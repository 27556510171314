import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';

export default async function setTermsAndConditionsResult({
  formData,
  Translate,
  setIsFetchingInfo,
  setFormData
}) {
  if (!formData?.terms_and_conditions_option_ref?.value) {
    showToast(Translate('Please select a valid Terms and Conditions'), 'error');
  }

  setIsFetchingInfo(true);

  try {
    const apiResult = await axios.get(
      `settings/terms-and-conditions/${formData.terms_and_conditions_option_ref.value}`
    );

    const termsData = apiResult?.data?.data;
    if (termsData?.description) {
      setFormData(prev => ({
        ...prev,
        terms_and_conditions: `${
          prev?.terms_and_conditions?.length > 0 &&
          prev.terms_and_conditions !== '<p><br></p>'
            ? prev.terms_and_conditions + '<br/>'
            : ''
        }${termsData.description}`,
        terms_and_conditions_option_ref: null
      }));
    }
  } catch (error) {
    showToast(
      error?.response?.data?.message ??
        error?.message ??
        'Something went wrong, please refresh the page and try again.',
      'error'
    );
  } finally {
    setIsFetchingInfo(false);
  }
}
