import { React } from 'react';
import { Button } from 'react-bootstrap';
import { IoMdSettings } from 'react-icons/io';
import SettingOffcanvas from './SettingOffcanvas';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PropTypes from 'prop-types';
const Settings = ({ type, module, className }) => {
  const navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const show = queryParams.get('settings') ? true : false;
  const settings_type = queryParams.get('settings_type');
  const allQueryParams = Object.fromEntries([...queryParams]);
  const onHide = () => {
    navigate(-1);
  };
  const ButtonClick = () => {
    navigate(
      `?${createSearchParams({
        ...allQueryParams,
        settings: true,
        settings_type: type
      })}`
    );
  };
  return (
    <>
      <Button
        size="sm"
        variant="transparent"
        className={`px-2 ${className}`}
        style={{
          backgroundColor: 'whitesmoke',
          border: '1px solid rgb(212, 210, 210)',
          boxShadow: 'none'
        }}
        onClick={ButtonClick}
        title={Translate('settings')}
      >
        <IoMdSettings size={15} />
      </Button>
      <SettingOffcanvas
        show={show}
        onHide={onHide}
        settings_type={settings_type}
        module={module}
      />
    </>
  );
};
Settings.propTypes = {
  type: PropTypes.string,
  module: PropTypes.string,
  className: PropTypes.string
};
export default Settings;
