import { React, useContext, useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AiOutlinePercentage } from 'react-icons/ai';

import { AuthWizardContext } from 'context/Context';
import KitDetailModal from 'module/Sales/common/kitDetails/KitDetailModal';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';

const SalesQuotationItem = ({
  entry: {
    stock_id,
    quantity,
    discount_amount,
    discount_percent,
    unit_price,
    initial_unit_price,
    unit_margin,
    item_display_name,
    item_description,
    line_total,
    unit_name,
    unit_tax,
    kit_id,
    is_kit,
    kit_items,
    estimation_detail_id,
    margin_type
  },
  index,
  handleRemoveTableItem,
  handleEditTableData,
  discountMethod,
  editDisable,
  kitFullDetails,
  onTableItemChange,
  formData,
  kitItems,
  setKitItems,
  setUpdateEnable,
  convertType
}) => {
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const { user } = useContext(AuthWizardContext);
  const [modifiedKitFullDetails, setModifiedKitFullDetails] = useState([]);
  const [itemIsAdding, setItemIsAdding] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  //Currently there is no use of this useEffect. But If kit modal is enabled for editing then this is needed!
  useEffect(() => {
    if (
      kitFullDetails &&
      kitFullDetails.kit_items &&
      kitFullDetails.kit_items.length > 0
    ) {
      setItemIsAdding(true);
      const details = {
        ...kitFullDetails,
        kit_items: kitFullDetails.kit_items.map(kitItem => {
          return {
            quantity: kitItem.quantity,
            item_display_name: kitItem.item_display_name,
            unit_name: kitItem.unit_name
              ? kitItem.unit_name
              : kitItem.unit_ref
              ? kitItem.unit_ref
              : '',
            unit_price_formatted: kitItem.unit_price_formatted,
            unit_price: kitItem.unit_price,
            unit_tax: kitItem.unit_tax,
            unit_tax_formatted: kitItem.unit_tax_formatted,
            base_quantity_ref: kitItem.base_quantity_ref,
            stock_id: kitItem.stock_id,
            discount_amount: kitItem.discount_amount,
            discount_percent: kitItem.discount_percent,
            discount_amount_formatted: kitItem.discount_amount_formatted,
            line_total_formatted: kitItem.line_total_formatted,
            line_total: kitItem.line_total,
            kit_item_id: kitItem.id,
            edit_ref: false,
            trans_date: formData.trans_date
          };
        })
      };
      setModifiedKitFullDetails(details);
      setItemIsAdding(false);
    }
  }, [kitFullDetails]);

  return (
    <tr>
      <td className="fs--1 text-dark p-1 text-center">{index + 1}</td>
      <td className="fs--1 text-dark p-1 text-center">
        {kit_id && kit_id !== 'null' ? kit_id : stock_id ? stock_id : '-'}
      </td>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={
          <Tooltip>
            <span className="fs--2">{item_display_name}</span>
          </Tooltip>
        }
      >
        <td className="text-dark p-1 d-flex flex-column gap-2">
          <div className="fw-bold fs--1">
            {item_display_name?.substring(0, 50)}
          </div>
          {
            <div className="fs--1 text-mute ps-3">
              {' - '}
              {item_description &&
                item_description?.match(/.{1,70}/g).map((chunk, index) => (
                  <span key={index}>
                    {chunk}
                    <br />
                  </span>
                ))}
            </div>
          }
        </td>
      </OverlayTrigger>
      <td className="fs--1 text-dark p-1 text-center">{quantity}</td>
      <td className="fs--1 text-dark p-1 text-uppercase text-center">
        {unit_name}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {acodaxNumberFormat(parseFloat(initial_unit_price ?? unit_price ?? 0))}
      </td>

      {convertType === 'estimation' ? (
        <td className="fs--1 text-dark p-1 text-center">
          {acodaxNumberFormat(parseFloat(unit_margin ?? 0))}
          <span className="mx-1">
            {margin_type === 'AMOUNT' ? (
              `${baseCurrency}`
            ) : (
              <AiOutlinePercentage />
            )}
          </span>
        </td>
      ) : null}

      <td className="fs--1 text-dark p-1 text-uppercase text-center">
        {acodaxNumberFormat(parseFloat(unit_tax ?? 0))}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {!discountMethod
          ? acodaxNumberFormat(parseFloat(discount_amount ?? 0))
          : acodaxNumberFormat(parseFloat(discount_percent ?? 0))}
        <span className="mx-1">
          {!discountMethod ? `${baseCurrency}` : <AiOutlinePercentage />}
        </span>
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {acodaxNumberFormat(parseFloat(line_total ?? 0))}
      </td>
      <td className="p-1 text-center">
        <div className="d-flex gap-1 aling-items-center justify-content-center">
          {kit_items && kit_items.length > 0 && parseInt(is_kit) == 1 && (
            <KitDetailModal
              kit={kit_items}
              kitName={item_display_name}
              modifiedKitFullDetails={modifiedKitFullDetails}
              setModifiedKitFullDetails={setModifiedKitFullDetails}
              readOnly={false}
              onTableItemChange={onTableItemChange}
              itemIsAdding={itemIsAdding}
              formData={formData}
              kitItems={kitItems}
              setKitItems={setKitItems}
              setUpdateEnable={setUpdateEnable}
              batch
            />
          )}
          {handleRemoveTableItem && (
            <Button
              size="sm"
              variant="transparent"
              className="p-0 mx-1 text-info"
              onClick={() => handleEditTableData(index)}
              style={{ boxShadow: 'none' }}
              disabled={editDisable}
            >
              <FontAwesomeIcon icon="edit" />
            </Button>
          )}
          {!estimation_detail_id && handleRemoveTableItem && (
            <Button
              size="sm"
              variant="transparent"
              className="p-0 me-1 text-danger"
              onClick={() => handleRemoveTableItem(index)}
              style={{ boxShadow: 'none' }}
              disabled={editDisable}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

SalesQuotationItem.propTypes = {
  index: PropTypes.number,
  entry: PropTypes.object,
  handleEditTableData: PropTypes.func,
  handleRemoveTableItem: PropTypes.func,
  discountMethod: PropTypes.bool,
  editDisable: PropTypes.bool,
  kitFullDetails: PropTypes.any,
  onTableItemChange: PropTypes.any,
  formData: PropTypes.any,
  kitItems: PropTypes.any,
  setKitItems: PropTypes.any,
  setUpdateEnable: PropTypes.any,
  convertType: PropTypes.string
};

export default SalesQuotationItem;
