import { React, useContext } from 'react';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

function SalesTableHead({
  SO,
  SD,
  SI,
  //  validation,
  taxType,
  convertType
}) {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const baseCurrency =
    user?.branch?.base_currency && user?.branch?.base_currency !== ''
      ? user?.branch?.base_currency
      : 'AED';

  return (
    <thead className="text-white" style={{ backgroundColor: 'black' }}>
      <tr>
        <th className="table-header-size text-center" style={{ width: '30px' }}>
          #
        </th>
        <th className="table-header-size text-center" style={{ width: '80px' }}>
          {Translate('Item Code')}
        </th>
        <th style={{ width: '350px' }} className="table-header-size">
          {Translate('Item Name')}
        </th>
        {SD || SI ? (
          <th
            style={{ width: '150px' }}
            className="table-header-size text-center"
          >
            {Translate('Batch')}
          </th>
        ) : null}
        <th
          className="table-header-size text-center"
          style={{ width: '150px' }}
        >
          {Translate(
            SO
              ? 'Ordering Qty'
              : SD
              ? 'Delivering Qty'
              : SI
              ? 'Invoicing Qty'
              : 'Quantity'
          )}
        </th>
        <th className="table-header-size text-center" style={{ width: '80px' }}>
          {Translate('Unit')}
        </th>
        <th
          className="table-header-size text-center"
          style={{ width: '120px' }}
        >
          {Translate('Price')}
        </th>

        {convertType === 'estimation' ? (
          <th
            className="table-header-size text-center"
            style={{ width: '120px' }}
          >
            {Translate('Margin')}
          </th>
        ) : null}
        <th
          className="table-header-size text-center"
          style={{ width: '120px' }}
        >
          {taxType ? Translate('Tax (INC)') : Translate('Tax')}
        </th>
        {/* {validation ? null : ( */}
        <th
          className="table-header-size text-center"
          style={{ width: '100px' }}
        >
          {Translate('Discount')}
        </th>
        {/* )} */}
        <th
          className="table-header-size text-center"
          style={{ width: '150px' }}
        >
          {Translate(`Total (${baseCurrency ?? 'AED'})`)}
        </th>
        <th className="table-header-size text-center" style={{ width: '70px' }}>
          {Translate('Action')}
        </th>
      </tr>
    </thead>
  );
}

SalesTableHead.propTypes = {
  SO: PropTypes.bool,
  SD: PropTypes.bool,
  SI: PropTypes.bool,
  validation: PropTypes.bool,
  taxType: PropTypes.bool,
  convertType: PropTypes.string
};

export default SalesTableHead;
