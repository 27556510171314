import { React, useContext, useEffect, useState } from 'react';
import {
  createSearchParams,
  Outlet,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { Card } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { FaUserGroup } from 'react-icons/fa6';
import { DeleteContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { apiCall } from 'helpers/apiCalls';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import {
  DeleteButton,
  EditButton,
  ViewButton
} from 'module/Common/Buttons/CommonButton';
import TableHeader from 'helpers/tableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import AppPagination from 'components/common/app-pagination/AppPagination';
import AddButton from 'components/add-button/AddButton';
import FormattedData from '../../Common/TableFilter/FormatedData';
// import Filter from 'module/Common/TableFilter/Filter';

const CustomerGroupTable = () => {
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [taxes, setTaxes] = useState({});
  const pageNumber = queryParams.get('page');
  const filterItems = queryParams.get('filters');
  const allQueryParams = Object.fromEntries([...queryParams]);
  const permission = {
    create: getAcodaxPermissionSlug('CS', 'create'),
    update: getAcodaxPermissionSlug('CS', 'update'),
    delete: getAcodaxPermissionSlug('CS', 'delete'),
    show: getAcodaxPermissionSlug('CS', 'showss')
  };

  const handleDelete = data => {
    dispatch({
      type: 'CONFIG',
      payload: {
        target: data.id,
        url: '/sales/customer-groups/' + data.id,
        title: 'Delete Customer Group',
        message: 'Are you sure you want to delete this customer group?',
        onSuccess: () => {
          const dataWithoutDeletedItem = taxes.data.filter(
            account => account.id !== data.id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              '/sales/customer-groups?' +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else {
            setTaxes(previous => ({
              ...previous,
              data: dataWithoutDeletedItem
            }));
            // reset deleteContext
            dispatch({
              type: 'RESET'
            });
          }
        }
      }
    });
  };

  const columns = [
    {
      accessor: 'group_code',
      Header: Translate('GROUP CODE'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2 text-uppercase'
      },
      Cell: rowData => {
        const { group_code } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{group_code}</h5>;
      }
    },
    {
      accessor: 'name',
      Header: Translate('GROUP NAME'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2 text-uppercase'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{name}</h5>;
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'py-0 pe-3 text-end button-styles-in-table'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <div className="hide-content">
            {permission?.update ? (
              <EditButton
                to={`edit/${id}?${createSearchParams({
                  ...allQueryParams
                })}`}
              />
            ) : (
              ''
            )}
            {permission?.delete ? (
              <DeleteButton
                handleDelete={handleDelete}
                id={rowData.row.original}
              />
            ) : (
              ''
            )}
            {permission.show ? (
              <ViewButton
                to={`/sales/customer-groups/${id}?${createSearchParams({
                  ...allQueryParams
                })}`}
              />
            ) : (
              ''
            )}
          </div>
        );
      }
    }
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: 'sales/customer-groups',
      params: {
        page: page,
        ...FormattedData(JSON.parse(filterItems))
      }
    });
    setTaxes(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, filterItems]);

  return (
    <>
      <Outlet context={[getData]} />
      <Card className="flex-fill mb-3">
        <Card.Header className="d-flex flex-row justify-content-between border-bottom">
          <FaUserGroup size={20} className="me-1 text-dark" />
          <TableHeader title={Translate('Customer Group')} table />
          {permission?.create ? (
            <AddButton
              to={`/sales/customer-groups/add?${createSearchParams({
                ...allQueryParams
              })}`}
            />
          ) : (
            ''
          )}
        </Card.Header>
        {/* <Filter moduleName="customer" /> */}
        <SimpleBar className="simplebar-style-with-pagination">
          <Card.Body className="p-0">
            {!isLoading && taxes && taxes.data ? (
              <AdvanceTableWrapper columns={columns} data={taxes.data} sortable>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </AdvanceTableWrapper>
            ) : (
              <div
                className=" d-flex align-item-center justify-content-center"
                style={{ height: '100vh' }}
              >
                <LoadingScreen message="Looking for data" />
              </div>
            )}
          </Card.Body>
        </SimpleBar>
        <Card.Footer className="border-top p-1 pt-3">
          {taxes && taxes.links && (
            <AppPagination
              data={taxes}
              url="/sales/customer-groups"
              search={allQueryParams}
            />
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

export default CustomerGroupTable;
