import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaHourglassStart
} from 'react-icons/fa';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

export default function WorkOrderStatusBadge({ quantity, quantityIssued }) {
  const Translate = useAxisproTranslate();
  const _quantity = !isNaN(parseFloat(quantity)) ? parseFloat(quantity) : 0;
  const _quantityIssued = !isNaN(parseFloat(quantityIssued))
    ? parseFloat(quantityIssued)
    : 0;

  const status =
    _quantity === _quantityIssued
      ? 'done'
      : _quantity > _quantityIssued && _quantityIssued > 0
      ? 'started'
      : 'pending';

  return (
    <Badge
      bg={
        status === 'done'
          ? 'success'
          : status === 'started'
          ? 'warning'
          : 'danger'
      }
      className="rounded"
    >
      <div className="d-flex flex-row align-items-center gap-1">
        {status === 'done' ? (
          <>
            <FaCheckCircle />
            {Translate('Fully Issued')}
          </>
        ) : status === 'started' ? (
          <>
            <FaHourglassStart />
            {Translate('Partially Issued')}
          </>
        ) : (
          <>
            <FaExclamationCircle />
            {Translate('Pending')}
          </>
        )}
      </div>
    </Badge>
  );
}

WorkOrderStatusBadge.propTypes = {
  quantity: PropTypes.string,
  quantityIssued: PropTypes.string
};
