import { React, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CommonSearch() {
  const navigate = useNavigate();
  const apiCallDelay = useRef(null);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const filterItems = queryParams.get('filters');
  const [formData, setFormData] = useState({
    search: ''
  });

  const handleFieldChange = e => {
    setFormData({
      search: e?.target?.value
    });
    let search = {
      search: e?.target?.value
    };
    if (apiCallDelay?.current) clearTimeout(apiCallDelay.current);
    apiCallDelay.current = setTimeout(
      () => {
        const queryParams = {
          page: 1,
          filters: {
            ...(allQueryParams?.filters
              ? JSON.parse(allQueryParams.filters)
              : {}),
            ...search
          }
        };
        const queryString = Object.keys(queryParams)
          .map(
            key =>
              `${key}=${encodeURIComponent(
                typeof queryParams[key] === 'object'
                  ? JSON.stringify(queryParams[key])
                  : queryParams[key]
              )}`
          )
          .join('&');
        navigate({
          pathname: '',
          search: queryString ? `?${queryString}` : ''
        });
      },
      search?.search?.length > 0 ? 1000 : 0
    );
  };

  useEffect(() => {
    if (filterItems && Object.keys(filterItems).length > 0) {
      const obj = JSON.parse(filterItems);
      if (Object.prototype.hasOwnProperty.call(obj, 'search')) {
        setFormData(prev => ({
          ...prev,
          search: obj['search']
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        search: ''
      }));
    }
  }, [filterItems]);

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <Form className="common-serach" onSubmit={e => handleSubmit(e)}>
      <Form.Control
        type="search"
        placeholder="Search..."
        aria-label="Search"
        className="rounded-pill search-input"
        value={formData?.search}
        onChange={handleFieldChange}
      />
      <FontAwesomeIcon
        icon="search"
        className="position-absolute text-400 search-box-icon"
      />
    </Form>
  );
}

export default CommonSearch;
