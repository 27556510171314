import { React } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

const SerachSelectSalesFields = ({
  handleFieldChange,
  url,
  label,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  isClearable,
  ...rest
}) => {
  const setItems = async value => {
    let response = await axios.get(url, {
      params: { [label]: value }
    });
    let data = [];
    if (name === 'credit_status') {
      data = response && response.data ? response.data.data.data : [];
    } else {
      data = response && response.data ? response.data.data : [];
    }
    return data.map(item => ({
      label: item[label],
      value: keyName ? item[keyName] : item.id
    }));
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={setItems}
        defaultOptions
        isClearable={isClearable}
        name={name ? name : 'payment_terms'}
        value={value}
        placeholder={placeholder ?? ''}
        onChange={handleFieldChange}
        className={error && 'form-control ps-0 py-0 is-invalid'}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
        {...rest}
      />
    </>
  );
};

SerachSelectSalesFields.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  url: PropTypes.string,
  label: PropTypes.string,
  isClearable: PropTypes.bool
};

export default SerachSelectSalesFields;
