import { React, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { BsCoin, BsReceipt } from 'react-icons/bs';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { CloseButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import CreditNoteView from './CreditNoteView';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import ReFundPopup from '../Common/ReFundPopup';
import SendEmail from '../Common/SendEmail';
import JournalTable from '../Common/Tables/JournalTable';
import AllocationsTable from '../Common/Tables/AllocationsTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import Badges from '../Common/ViewPageDetails/Badges';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import ActivityLog from 'components/activity-log/ActivityLog';

function CreditNoteTabView({
  creditNoteVoucherData,
  loading,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  let navigateTo = useNavigate();
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [reFund, setReFund] = useState(false);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    journal: false,
    allocations: false
  });
  const permission = {
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    create_cs: getAcodaxPermissionSlug('CS', 'create'),
    print_cn: getAcodaxPermissionSlug('CN', 'print'),
    customer_alloc: getAcodaxPermissionSlug('CRA', 'create'),
    send_mail_cr: getAcodaxPermissionSlug('CN', 'send-mail'),
    void_cn: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };
  const sendEmail = () => {
    setShowEmailModal(true);
  };
  const handleCancel = () => {
    setShowEmailModal(false);
  };
  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Credit Note')}
              </h5>
            </div>
            {voidTransactionReport && (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  setReFund={setReFund}
                  disableRefundButton={
                    Math.round(creditNoteVoucherData.balance) === 0
                  }
                  refundValiadtions={true}
                  emailPermission={permission.send_mail_cr}
                  clonePath={
                    permission?.create_cs
                      ? '/sales/credit-note/add?clone_item=' +
                        creditNoteVoucherData?.id
                      : undefined
                  }
                  voidTransactionData={
                    permission?.void_cn
                      ? {
                          id: creditNoteVoucherData?.id,
                          trans_type: 'CN',
                          callBack: () => {
                            navigateTo('/sales/credit-note/info');
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                />
              )}
              {!voidTransactionReport && permission?.print_cn && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`sales/credit-note/print/${creditNoteVoucherData.id}`}
                  pageTitle="Credit note print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div>
              {creditNoteVoucherData?.customer_name ? (
                <PersonName
                  label={'Customer Name'}
                  value={creditNoteVoucherData?.customer_name}
                  view
                  permission={permission?.show_cs}
                  setCustomerDetailsArea={setCustomerDetailsArea}
                />
              ) : null}
              {creditNoteVoucherData?.trans_date_formatted ? (
                <MainDetails
                  label={'Transaction Date'}
                  value={creditNoteVoucherData?.trans_date_formatted}
                />
              ) : null}
            </div>
            <div>
              {creditNoteVoucherData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/sales/credit-note/info/${creditNoteVoucherData?.id}`}
                  value={creditNoteVoucherData?.reference}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            {Math.round(parseFloat(creditNoteVoucherData.balance)) === 0 ||
            voidScreen ||
            offCanvas ? (
              ''
            ) : (
              <div className="view-page-payment-message-wrapper">
                <Card
                  className={`p-3 ${
                    Math.round(creditNoteVoucherData.balance) != 0
                      ? 'd-none'
                      : 'd-block'
                  }`}
                >
                  <>
                    <div className="d-flex align-items-center gap-2">
                      <BsCoin />
                      <p className="m-0 text-center">
                        {Translate('Un Allocated Amount')}: &nbsp;
                        <span className="fw-bold text-dark">
                          {creditNoteVoucherData.balance_formatted
                            ? creditNoteVoucherData.balance_formatted
                            : '0.00'}
                        </span>
                      </p>
                    </div>
                  </>
                  {permission?.customer_alloc ? (
                    <div>
                      <hr className="mt-2 mb-2" />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex gap-2 align-items-center">
                          <BsCoin size={22} className="text-dark" />
                          <h4 className="m-0 tab-view-heading">
                            {Translate('Make payment for this transaction!')}
                          </h4>
                        </div>
                        <div>
                          <Button
                            variant="warning"
                            style={{ boxShadow: 'none' }}
                            className="fs--2 text-uppercase"
                            as={Link}
                            to={`/sales/customer-allocation/allocate/${creditNoteVoucherData.voucher_id}`}
                          >
                            {Translate('Make allocation')}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <p className="m-0">
                          {Translate(
                            'Make allocations for this transaction if outstanding transaction available!'
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </Card>
              </div>
            )}
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={creditNoteVoucherData.trans_type}
                      transId={creditNoteVoucherData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar
                  className={
                    Math.round(creditNoteVoucherData.balance) === 0 ||
                    voidScreen ||
                    offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <CreditNoteView
                    creditNoteVoucherData={creditNoteVoucherData}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="journal" title={Translate('Journal')}>
                <SimpleBar
                  className={
                    Math.round(creditNoteVoucherData.balance) === 0 ||
                    voidScreen ||
                    offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <JournalTable
                    id={creditNoteVoucherData?.id}
                    transType={'CN'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.journal}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="allocations" title={Translate('Allocations')}>
                <SimpleBar
                  className={
                    Math.round(creditNoteVoucherData.balance) === 0 ||
                    voidScreen ||
                    offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <AllocationsTable
                    id={creditNoteVoucherData?.id}
                    t_type={'CN'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.allocations}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              {creditNoteVoucherData.media &&
              creditNoteVoucherData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar
                    className={
                      Math.round(creditNoteVoucherData.balance) === 0 ||
                      voidScreen ||
                      offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AttachementsView
                      data={creditNoteVoucherData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {creditNoteVoucherData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`sales/credit-note/print/${creditNoteVoucherData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
              <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                  {key === 'activityLog' && (
                    <ActivityLog subjectID={creditNoteVoucherData.id} />
                  )}
                </SimpleBar>
              </Tab>
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={creditNoteVoucherData?.created_at}
          created_by={creditNoteVoucherData?.created_user_full_name}
        />
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={{ value: creditNoteVoucherData?.customer_id }}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      {creditNoteVoucherData?.id && (
        <FileUpload
          show={upload}
          onHide={() => setUpload(false)}
          fetchData={fetchData}
          type={'CN'}
          id={creditNoteVoucherData?.id}
          setKey={setKey}
        />
      )}
      <ReFundPopup
        show={reFund}
        popupData={{
          personId: creditNoteVoucherData.customer_id,
          personType: 'CUSTOMER',
          personName: creditNoteVoucherData.customer_name,
          refundAmount: creditNoteVoucherData.balance,
          reference: creditNoteVoucherData.reference,
          refundAmountFormatted: creditNoteVoucherData.balance_formatted,
          transType: 'CN',
          transNo: creditNoteVoucherData.voucher_id,
          value: creditNoteVoucherData.customer_id
        }}
        fetchData={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
        onHide={() => setReFund(false)}
      />
      <SendEmail
        url={'sales/credit-note-email/' + creditNoteVoucherData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={creditNoteVoucherData.customer_email}
      />
    </>
  );
}

CreditNoteTabView.propTypes = {
  creditNoteVoucherData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default CreditNoteTabView;
