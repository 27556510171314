import { Fragment, React } from 'react';
import { Alert, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import handleTableItem from 'module/Purchase/common/functions/handleTableItem';
import PurchaseTableForm from './PurchaseTableForm';
import PurchaseTableFormLineItem from './PurchaseTableFormLineItem';
import { useSaveToCart } from '../hooks/purchaseHooks';
import DisplayLineItemErrors from './DisplayLineItemErrors';
import { useState } from 'react';
import TableLoading from 'module/Report/Common/TableLoading';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SubTotalLineItem from './SubTotalLineItem';

function PurchaseEntryTable({
  entries,
  setFormData,
  conversion,
  readOnly = false,
  batchIsEditable,
  handleSaveDraftData,
  taxIncluded,
  transaction_date,
  defaultKeys,
  totalAmount,
  disableEntriesRequiredMessage,
  enableBatchSection,
  transType,
  transNumber,
  enableTaxGroup,
  supplierDetails,
  showLoading,
  showPurchaseHistory,
  errors
}) {
  const colSpan =
    enableBatchSection && enableTaxGroup
      ? 12
      : enableBatchSection || enableTaxGroup
      ? 11
      : 10;

  if (enableTaxGroup) {
    defaultKeys = {
      ...defaultKeys,
      tax_group_id: '',
      tax_group_id_ref: null
    };
  }

  const Translate = useAxisproTranslate();
  const [itemIsAdding, setItemIsAdding] = useState(false);
  const onTableItemChange = async (data, method, saveToDraft) => {
    if (!transaction_date) {
      return showToast(
        Translate('Transaction date is required, please select a valid date')
      );
    }
    setItemIsAdding(true);
    handleTableItem({
      data: data,
      method: method,
      setFormData: setFormData,
      handleSaveDraftData: handleSaveDraftData,
      saveToDraft: saveToDraft ?? false
    });
    setItemIsAdding(false);
  };

  useSaveToCart({
    onTableItemChange: onTableItemChange,
    cartItems: entries,
    transType: transType,
    transNumber: transNumber
  });

  return (
    <Table
      striped
      bordered
      responsive
      hover={!readOnly ? true : false}
      className="simple-table-animation"
    >
      <thead
        className="text-dark text-uppercase"
        style={{ backgroundColor: 'rgb(237,243,248)' }}
      >
        <tr>
          <th
            className="table-header-size text-center"
            style={{ width: '40px' }}
          >
            #
          </th>
          <th className="table-header-size" style={{ width: '90px' }}>
            {Translate('Item Code')}
          </th>
          <th style={{ minWidth: '250px' }} className="table-header-size">
            {Translate('Item Name')}
          </th>
          {enableBatchSection ? (
            <th className="table-header-size" style={{ width: '200px' }}>
              {Translate('Batch')}
            </th>
          ) : null}

          <th className="table-header-size" style={{ width: '120px' }}>
            {Translate('Unit')}
          </th>
          {enableTaxGroup ? (
            <th className="table-header-size" style={{ minWidth: '150px' }}>
              {Translate('Tax Group')}
            </th>
          ) : null}

          <th className="table-header-size" style={{ width: '200px' }}>
            {Translate('Quantity')}
          </th>
          <th className="table-header-size" style={{ width: '200px' }}>
            {Translate('Unit Price')}
            {taxIncluded ? <small> ({Translate('Inc Tax')})</small> : null}
          </th>
          <th className="table-header-size">{Translate('Unit Tax')}</th>
          <th className="table-header-size" style={{ width: '200px' }}>
            {Translate('Discount / Unit')}
          </th>
          <th className="table-header-size">{Translate('Total')}</th>
          {!readOnly && <th style={{ width: '70px' }}></th>}
        </tr>
      </thead>
      <tbody>
        {entries.length > 0 ? (
          <>
            {!transaction_date && (
              <tr>
                <td colSpan={colSpan} className="p-0">
                  <Alert
                    className="text-center my-2 rounded-0 p-2 fs--1"
                    variant="danger"
                  >
                    {Translate(
                      'The transaction date is invalid, please select a valid date!'
                    )}
                  </Alert>
                </td>
              </tr>
            )}
            {entries.map((entry, index) => (
              <Fragment key={index}>
                {showLoading ? (
                  <TableLoading row={1} column={colSpan} />
                ) : entry.edit ? (
                  <PurchaseTableForm
                    editItemData={entry}
                    onTableItemChange={onTableItemChange}
                    transaction_date={transaction_date}
                    taxIncluded={taxIncluded}
                    defaultKeys={defaultKeys}
                    isLoading={entry?.add_to_cart_ref ? true : false}
                    enableBatchSection={enableBatchSection}
                    conversion={conversion}
                    batchIsEditable={batchIsEditable}
                    transType={transType}
                    enableTaxGroup={enableTaxGroup}
                    supplierDetails={supplierDetails}
                    showPurchaseHistory={showPurchaseHistory}
                  />
                ) : (
                  <PurchaseTableFormLineItem
                    entry={entry}
                    index={index}
                    onTableItemChange={onTableItemChange}
                    readOnly={readOnly}
                    conversion={conversion}
                    enableBatchSection={enableBatchSection}
                    errors={errors}
                    enableTaxGroup={enableTaxGroup}
                  />
                )}
                {!entry.add_to_cart_ref && entry.errors ? (
                  <DisplayLineItemErrors
                    errors={entry.errors[0]}
                    colSpan={colSpan}
                  />
                ) : null}
              </Fragment>
            ))}
          </>
        ) : !disableEntriesRequiredMessage ? (
          <tr>
            <td colSpan={colSpan} className="p-0">
              <Alert
                className="text-center my-2 rounded-0 p-2 fs--1"
                variant="warning"
              >
                {Translate('Entries are required!')}
              </Alert>
            </td>
          </tr>
        ) : null}

        {!readOnly && !conversion ? (
          <PurchaseTableForm
            onTableItemChange={onTableItemChange}
            transaction_date={transaction_date}
            taxIncluded={taxIncluded}
            defaultKeys={defaultKeys}
            enableBatchSection={enableBatchSection}
            hide={
              showLoading ||
              itemIsAdding ||
              !!(entries.filter(item => item.edit === true).length > 0)
            }
            enableTaxGroup={enableTaxGroup}
            supplierDetails={supplierDetails}
            showPurchaseHistory={showPurchaseHistory}
            transType={transType}
          />
        ) : null}

        {showLoading || itemIsAdding ? (
          <TableLoading row={1} column={colSpan} />
        ) : null}

        {typeof totalAmount === 'number' ? (
          <SubTotalLineItem
            textAreaColSpan={
              enableBatchSection && enableTaxGroup
                ? 10
                : enableBatchSection || enableTaxGroup
                ? 9
                : 8
            }
            amountAreaColSpan={2}
            totalAmount={totalAmount}
          />
        ) : null}
      </tbody>
    </Table>
  );
}

PurchaseEntryTable.propTypes = {
  entries: PropTypes.array,
  setFormData: PropTypes.func,
  conversion: PropTypes.bool,
  readOnly: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  handleSaveDraftData: PropTypes.func,
  taxIncluded: PropTypes.bool,
  transaction_date: PropTypes.string,
  defaultKeys: PropTypes.object.isRequired,
  totalAmount: PropTypes.number,
  disableEntriesRequiredMessage: PropTypes.bool,
  enableBatchSection: PropTypes.bool,
  transType: PropTypes.string,
  transNumber: PropTypes.string,
  enableTaxGroup: PropTypes.bool,
  supplierDetails: PropTypes.object,
  showLoading: PropTypes.bool,
  showPurchaseHistory: PropTypes.bool,
  errors: PropTypes.object
};

export default PurchaseEntryTable;
