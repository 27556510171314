import { React, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import SupplierPopupForm from './popup-forms/SupplierPopupForm';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectSupplier = ({
  handleFieldChange,
  name,
  value,
  keyName,
  style,
  showSupplierDetails,
  placeholder,
  includeDetailsKey,
  withAddButton,
  onFocus,
  error,
  enableBranchTransaction,
  ...rest
}) => {
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);
  const [showFormWindow, setShowFormWindow] = useState(false);
  const {
    fetchData,
    setDefaultResult,
    loading,
    defaultOptions,
    setDefaultOptions
  } = useAutoCompleteApiCallHandler({
    url: enableBranchTransaction
      ? 'purchase/branch-suppliers-autocomplete'
      : 'purchase/suppliers-autocomplete',
    setParams: searchKey => ({ name: searchKey }),
    setOptions: option => setOptionData(option),
    onFocus: onFocus
  });

  const setOptionData = option => ({
    label: option.supplier_code + ' ' + option.name,
    value: keyName ? option[keyName] : option.id,
    ...(includeDetailsKey
      ? {
          supplier_details: option
        }
      : null)
  });

  const handleSupplierDetailsArea = () => {
    setSupplierDetailsArea(!showSupplierDetailsArea);
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={fetchData}
        isLoading={loading}
        onFocus={setDefaultResult}
        defaultOptions={defaultOptions}
        isClearable
        name={name ?? 'supplier'}
        value={value}
        placeholder={placeholder ?? ''}
        onChange={handleFieldChange}
        classNamePrefix="app-react-select"
        className={error && 'is-invalid'}
        components={
          withAddButton
            ? {
                Menu: props => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Supplier"
                    />
                  </>
                )
              }
            : null
        }
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 }),
          control: provided => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
        {...rest}
      />
      {showSupplierDetails && value && (
        <>
          <Button
            className="d-flex flex-row mt-2 align-items-center p-0"
            variant="white"
            onClick={handleSupplierDetailsArea}
          >
            <FontAwesomeIcon icon="user-circle" size="xs" className="me-1" />
            <span className="small text-primary">View Supplier Details</span>
          </Button>
          <SupplierDetails
            show={showSupplierDetailsArea}
            onHide={handleSupplierDetailsArea}
            value={value}
            showSupplierDetailsArea={showSupplierDetailsArea}
          />
        </>
      )}

      {withAddButton ? (
        <SupplierPopupForm
          includeDetailsKey={includeDetailsKey}
          name={name ?? 'supplier_id'}
          labelName="name"
          keyName="id"
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
          callBack={newItem => {
            if (newItem?.id) {
              setDefaultOptions(prev => [setOptionData(newItem), ...prev]);
            }
          }}
        />
      ) : null}
    </>
  );
};

SelectSupplier.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  showSupplierDetails: PropTypes.bool,
  includeDetailsKey: PropTypes.bool,
  withAddButton: PropTypes.bool,
  enableBranchTransaction: PropTypes.bool,
  error: PropTypes.any,
  onFocus: PropTypes.func
};

export default SelectSupplier;
