import { React, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { ButtonGroup, Card, Container, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { BsReceipt } from 'react-icons/bs';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import ContractView from './ContractView';
import TransactionTable from '../Common/Tables/TransactionTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import Badges from '../Common/ViewPageDetails/Badges';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import ContractStatusDropDownButton from '../Common/ContractStatusDropDownButton';
import ActivityLog from 'components/activity-log/ActivityLog';

function ContractTabView({
  contractData,
  loading,
  voidScreen,
  infoPage,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  onHide,
  getData
}) {
  let navigateTo = useNavigate();
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [person] = useState({
    value: contractData.customer_id
  });
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const permission = {
    show_ct: getAcodaxPermissionSlug('CT', 'show'),
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    print_ct: getAcodaxPermissionSlug('CT', 'print'),
    create_ct: getAcodaxPermissionSlug('CT', 'create'),
    update_ct: getAcodaxPermissionSlug('CT', 'update'),
    send_mail_ct: getAcodaxPermissionSlug('CT', 'send_mail'),
    void_ct: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Contract')}
              </h5>
            </div>
            <ContractStatusDropDownButton
              data={contractData}
              id={contractData.id}
            />
            {voidTransactionReport && (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  // renewedPath={`/sales/contract/edit/${
                  //   contractData?.id
                  // }?${createSearchParams({
                  //   ...allQueryParams,
                  //   renewed: true
                  // })}`}
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  emailPermission={permission?.send_mail_ct}
                  firstConversionValidation={true}
                  disableFirstConversion={
                    (contractData?.balanced_invoiced_amount &&
                      parseFloat(contractData.balanced_invoiced_amount) <= 0) ||
                    contractData?.status === 'expired'
                  }
                  firstConversionTo={
                    '/sales/sales-invoice/add/ct/' + contractData.id
                  }
                  afterFirstConversion={
                    contractData?.status === 'expired'
                      ? Translate('Contract Expired!')
                      : Translate('Fully Invoiced!')
                  }
                  beforeFirstConversion={
                    contractData?.status === 'expired'
                      ? Translate('Contract Expired!')
                      : Translate('Convert to Sales Invoice')
                  }
                  voidTransactionData={
                    permission?.void_ct
                      ? {
                          id: contractData?.id,
                          trans_type: 'CT',
                          callBack: () => {
                            navigateTo('/sales/contract/info');
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                />
              )}
              {voidScreen || infoPage || offCanvas ? (
                ''
              ) : permission?.update_ct ? (
                <EditButton
                  to={`/sales/contract/edit/${
                    contractData.id
                  }?${createSearchParams({
                    ...allQueryParams
                  })}`}
                />
              ) : (
                ''
              )}
              {!voidTransactionReport && permission?.print_ct && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`sales/contract/print/${contractData.id}`}
                  pageTitle="Credit note print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea || onHide}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap justify-content-between">
            <div>
              {contractData?.customer_name ? (
                <PersonName
                  label={'Customer Name'}
                  value={contractData?.customer_name}
                  view
                  permission={permission?.show_cs}
                  setCustomerDetailsArea={setCustomerDetailsArea}
                />
              ) : null}
              {contractData?.contract_date_formatted ? (
                <MainDetails
                  label={'Contract Date'}
                  value={contractData?.contract_date_formatted}
                />
              ) : null}
            </div>
            <div>
              {contractData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/sales/contract/info/${contractData?.id}`}
                  value={contractData?.reference}
                />
              ) : null}
              {contractData?.amount_formatted ? (
                <MainDetails
                  label={'Total Contract Amount'}
                  value={contractData?.amount_formatted}
                />
              ) : null}
              {contractData?.total_invoiced_amount_formatted ? (
                <MainDetails
                  label={'Total Invoiced Amount'}
                  value={contractData?.total_invoiced_amount_formatted}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={contractData.trans_type}
                      transId={contractData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar
                  className={
                    voidScreen || offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <ContractView contractData={contractData} />
                </SimpleBar>
              </Tab>
              {contractData?.sales_invoices &&
                contractData.sales_invoices.length > 0 && (
                  <Tab
                    eventKey="transactions"
                    title={Translate('Transactions')}
                  >
                    <SimpleBar
                      className={
                        voidScreen || offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <TransactionTable data={contractData} />
                    </SimpleBar>
                  </Tab>
                )}
              {contractData.media && contractData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar
                    className={
                      Math.round(contractData.balance) === 0 ||
                      voidScreen ||
                      offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AttachementsView
                      data={contractData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {contractData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`sales/contract/print/${contractData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
              <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                  {key === 'activityLog' && (
                    <ActivityLog subjectID={contractData.id} />
                  )}
                </SimpleBar>
              </Tab>
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={contractData?.created_at}
          created_by={contractData?.created_user_full_name}
        />
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={person}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'CT'}
        id={contractData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'sales/contracts-email/' + contractData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
      />
    </>
  );
}

ContractTabView.propTypes = {
  contractData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  infoPage: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  onHide: PropTypes.bool,
  getData: PropTypes.func
};

export default ContractTabView;
