import { React, useState } from 'react';
import axios from 'axios';
import { Modal, Form, Spinner, Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { GrGroup } from 'react-icons/gr';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import SelectMultiUser from 'components/form/SelectMultiUser';
import { ChatGroupAddModalFormData } from 'helpers/formKeys';

const GroupFormModal = ({ showGroupForm, setShowGroupForm, fetchGroups }) => {
  const [formData, setFormData] = useState(ChatGroupAddModalFormData);
  const [formError, setFormError] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const Translate = useAxisproTranslate();

  const handleFieldsChange = (e, action) => {
    if (action.name === 'user') {
      const newFormData = { ...formData };
      newFormData.user_ref = e;
      newFormData.user = e.map(items => items.value);
      setFormData(() => ({ ...newFormData }));
    } else {
      const newFormData = generateFormDataHelper(e, action, formData);
      setFormData(() => ({ ...newFormData }));
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError(ChatGroupAddModalFormData);
    setIsSaving(true);
    axios({
      method: 'post',
      url: 'user/chat-groups',
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          fetchGroups();
          setShowGroupForm(false);
          setIsSaving(false);
          setFormData({});
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setIsSaving(false);
      });
  };

  return (
    <>
      <Modal
        show={showGroupForm ? true : false}
        onHide={() => setShowGroupForm(false)}
      >
        <Modal.Header>
          <h6 className="form-heading text-start gap-1">
            <GrGroup size={26} />
            <span>{Translate('Add Group')}</span>
          </h6>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(formError).length > 0 && (
            <ErrorAlert formError={formError} setFormError={setFormError} />
          )}
          <Form>
            <Form.Group className="mb-3" controlId="paymentTermsIndicator">
              <Form.Label>{Translate('Name')}</Form.Label>
              <Row>
                <Col
                  md={12}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleFieldsChange}
                    value={formData.name}
                  />
                  <FormErrorPopover
                    id="name"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.name}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{Translate('Description')}</Form.Label>
              <Row>
                <Col
                  md={12}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={formData.description}
                    onChange={handleFieldsChange}
                  />
                  <FormErrorPopover
                    id="description"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.description}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label>{Translate('Users')}</Form.Label>
              <SelectMultiUser
                value={formData?.user_ref}
                handleFieldChange={handleFieldsChange}
                error={formError.users}
              />
              <FormErrorPopover
                id="users"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.users}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-start">
          <Button
            variant="success"
            size="sm"
            className="d-flex align-items-center justify-content-center"
            onClick={handleSubmit}
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="success"
                  className="me-1"
                />
                <span className="fs--1">{Translate('Saving')}...</span>
              </>
            ) : (
              <span>{Translate('Save')}</span>
            )}
          </Button>
          <Button
            variant="danger"
            onClick={() => setShowGroupForm(false)}
            size="sm"
            disabled={isSaving}
          >
            {Translate('Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

GroupFormModal.propTypes = {
  showGroupForm: PropTypes.bool,
  setShowGroupForm: PropTypes.func,
  fetchGroups: PropTypes.func
};

export default GroupFormModal;
