import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { salesEndPoints, systemEndPoints } from 'module/Common/Api/endpoints';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SalesOrderTabView from 'module/Common/ViewPages/SalesOrder/SalesOrderTabView';

function SalesOrderInfo({
  salesOrderId,
  rendering,
  firstId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  onHide,
  getData,
  interBranch
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async isLoading => {
    if (!rendering) {
      if (firstId !== undefined) {
        let url = voidTransactionReport
          ? systemEndPoints.void
          : `${salesEndPoints.sales_order}/${firstId}`;
        const fetchData = async () => {
          try {
            const response = await axios.get(url, {
              params: {
                trans_type: voidTransactionReport ? 'SO' : '',
                trans_no: voidTransactionReport ? firstId : ''
              }
            });

            if (response.data.success) {
              setData(response.data.data);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        };
        fetchData();
      }
    } else {
      if (salesOrderId !== undefined) {
        if (isLoading) {
          setLoading(true);
        }
        let url = voidTransactionReport
          ? systemEndPoints.void
          : `${salesEndPoints.sales_order}/${salesOrderId}`;
        await axios
          .get(url, {
            params: {
              trans_type: voidTransactionReport ? 'SO' : '',
              trans_no: voidTransactionReport ? salesOrderId : ''
            }
          })
          .then(res => {
            if (res.data.success) {
              setData(res.data.data);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchData(loading);
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [salesOrderId, firstId]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for sales order info" />
        </LoadingScreenCover>
      ) : (
        <SalesOrderTabView
          offCanvas={offCanvas}
          salesOrderData={data}
          loading={loading}
          fetchData={fetchData}
          onHide={onHide}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
          interBranch={interBranch}
        />
      )}
    </>
  );
}

SalesOrderInfo.propTypes = {
  salesOrderId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  interBranch: PropTypes.bool,
  onHide: PropTypes.func,
  getData: PropTypes.func
};

export default SalesOrderInfo;
