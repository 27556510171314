import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import WorkOrderMaterialRequestsModal from 'module/Common/ViewPages/WorkOrder/modals/WorkOrderMaterialRequestsModal/WorkOrderMaterialRequestsModal';
import WorkOrderAdditionalCostsModal from 'module/Common/ViewPages/WorkOrder/modals/WorkOrderAdditionalCostsModal/WorkOrderAdditionalCostsModal';
import WorkOrderReleaseModal from 'module/Common/ViewPages/WorkOrder/modals/WorkOrderReleaseModal/WorkOrderReleaseModal';
import WorkOrderDeliveryModal from 'module/Common/ViewPages/WorkOrder/modals/WorkOrderDeliveryModal/WorkOrderDeliveryModal';

export default function WorkOrderEntryActionButton({
  children,
  className,
  keyName,
  workOrderId,
  workOrderDetailId,
  setTabApiCallCompleted,
  setWorkOrderVoucherData,
  releaselimit,
  deliverylimit,
  WorkOrderDetailsArray,
  ...rest
}) {
  const [modals, setModals] = useState({
    materialRequest: false,
    additionalCosts: false,
    release: false,
    delivery: false
  });

  function handleButton(event) {
    if (event) event.preventDefault();
    if (Object.keys(modals).includes(keyName)) {
      setModals(prev => ({
        ...prev,
        [keyName]: !prev[keyName]
      }));
    }
  }

  return (
    <>
      <Button
        size="sm"
        className={`text-uppercase d-flex gap-1 align-items-center justify-content-center rounded px-2 py-1 flex-fill ${
          className ?? ''
        }`}
        type="button"
        {...rest}
        onClick={handleButton}
      >
        {children}
      </Button>

      {modals?.materialRequest ? (
        <WorkOrderMaterialRequestsModal
          onHide={handleButton}
          workOrderId={workOrderId}
          workOrderDetailId={workOrderDetailId}
          setTabApiCallCompleted={setTabApiCallCompleted}
        />
      ) : modals?.additionalCosts ? (
        <WorkOrderAdditionalCostsModal
          onHide={handleButton}
          workOrderDetailId={workOrderDetailId}
          setTabApiCallCompleted={setTabApiCallCompleted}
          WorkOrderDetailsArray={WorkOrderDetailsArray}
        />
      ) : modals?.release ? (
        <WorkOrderReleaseModal
          onHide={handleButton}
          workOrderDetailId={workOrderDetailId}
          setTabApiCallCompleted={setTabApiCallCompleted}
          setWorkOrderVoucherData={setWorkOrderVoucherData}
          releaselimit={releaselimit}
        />
      ) : modals?.delivery ? (
        <WorkOrderDeliveryModal
          onHide={handleButton}
          workOrderDetailId={workOrderDetailId}
          setTabApiCallCompleted={setTabApiCallCompleted}
          setWorkOrderVoucherData={setWorkOrderVoucherData}
          deliverylimit={deliverylimit}
        />
      ) : null}
    </>
  );
}

WorkOrderEntryActionButton.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  keyName: 'materialRequest' | 'additionalCosts' | 'release' | 'delivery',
  workOrderId: PropTypes.string,
  workOrderDetailId: PropTypes.string,
  setTabApiCallCompleted: PropTypes.func,
  setWorkOrderVoucherData: PropTypes.func,
  releaselimit: PropTypes.number,
  deliverylimit: PropTypes.number,
  WorkOrderDetailsArray: PropTypes.array
};
