import { React } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

function SelectCustomerGroup({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  onFocus
}) {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: 'sales/customer-groups-autocomplete',
      setParams: searchKey => ({ name: searchKey }),
      setOptions: option => ({
        label: option?.name,
        value: option.id
      }),
      onFocus: onFocus
    });

  return (
    <AsyncSelect
      loadOptions={fetchData}
      cacheOptions
      placeholder={placeholder ?? ''}
      isLoading={loading}
      isClearable
      defaultOptions={defaultOptions}
      onFocus={setDefaultResult}
      value={value}
      onChange={handleFieldChange}
      name={name ?? 'customer_group'}
      className={error && 'form-control ps-0 py-0 is-invalid'}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          height: 32,
          minHeight: 32,
          ...style
        })
      }}
    />
  );
}

SelectCustomerGroup.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.number,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func
};

export default SelectCustomerGroup;
