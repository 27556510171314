import { React, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BsReceipt } from 'react-icons/bs';

import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import useAxisProPermission from 'hooks/useAxisproPermission';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import SendEmail from '../Common/SendEmail';
import FileUpload from '../Common/FileUpload';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import ReferenceCopyToClipBoard from '../Common/ReferenceCopyToClipBoard';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import WorkOrderView from './WorkOrderView';
import AttachementsView from '../Common/AttachementsView';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';
import PDFView from 'components/PDFViewerButton/PDFView';
import WorkOrderMaterialRequestesTabView from './WorkOrderMaterialRequestesTabView';
import WorkOrderAdditonalCostsTabView from './WorkOrderAdditonalCostsTabView';
import WorkOrderReleasesTabView from './WorkOrderReleasesTabView';
import WorkOrderDeliveriesTabView from './WorkOrderDeliveriesTabView';

function WorkOrderTabView({
  workOrderVoucherData,
  setWorkOrderVoucherData,
  loading,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  onHide
}) {
  const axisProPermission = useAxisProPermission();
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [showVoucherDetailsPreview, setShowVoucherDetailsPreview] =
    useState(undefined);
  const [person] = useState({
    value: workOrderVoucherData.customer_id
  });
  const [upload, setUpload] = useState(false);
  const [status, setStatus] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);

  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    materialRequestes: false,
    additionalCosts: false,
    releases: false,
    delivery: false
  });

  function handleVoucherPreview(data) {
    setShowVoucherDetailsPreview({
      ...data,
      show: true
    });
  }

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  const handleSubmit = () => {
    setOnSave(true);
    axios
      .post(`production/work-orders/${workOrderVoucherData.id}/update-status`, {
        status: 'completed'
      })
      .then(res => {
        if (res.data.success) {
          showToast(res.data.message, 'success');
          fetchData();
          setStatus(false);
          setOnSave(false);
        }
      })
      .catch(error => {
        showToast(
          Translate(
            error?.response?.data?.data?.errors?.[0]?.[0] ??
              'Something went wrong, please refresh the page and try again'
          ),
          'error'
        );
        setOnSave(false);
      });
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Work Order')}
              </h5>
            </div>
            {workOrderVoucherData &&
            workOrderVoucherData.status === 'initiated' ? (
              <Badge bg="warning" className="ms-2 fs--1">
                {Translate('Initiated')}
              </Badge>
            ) : (
              <Badge bg="success" className="ms-2 fs--1">
                {Translate('Completed')}
              </Badge>
            )}
            {voidTransactionReport && (
              <Badge bg="danger" className="ms-2 fs--1">
                {Translate('Voided')}
              </Badge>
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  setStatus={setStatus}
                  emailPermission={axisProPermission('send-email-wo')}
                  changeStatus
                  disableStatus={workOrderVoucherData.status === 'completed'}
                />
              )}
              {voidScreen || offCanvas ? (
                ''
              ) : axisProPermission('update-wo') ? (
                <EditButton
                  to={`/production/work-orders/edit/${
                    workOrderVoucherData.id
                  }?${createSearchParams({
                    ...allQueryParams
                  })}`}
                />
              ) : (
                ''
              )}
              {!voidTransactionReport && axisProPermission('print-wo') && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`production/work-orders-print/${workOrderVoucherData.id}`}
                  pageTitle="Work Order print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea || onHide}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div
            className="d-flex flex-wrap gap-3 border p-3 mb-2"
            style={{
              backgroundColor: '#edf2f5'
            }}
          >
            <div className="me-auto">
              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">
                  {Translate('Transaction Date')}:
                </h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {workOrderVoucherData?.trans_date_formatted ?? '-'}
                  </h6>
                </div>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">{Translate('Customer Name')}:</h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {workOrderVoucherData?.customer?.name}
                  </h6>
                  <Button
                    className="p-0 m-0 text-primary"
                    variant="transparent"
                    onClick={() => setCustomerDetailsArea(true)}
                    size="sm"
                  >
                    <FontAwesomeIcon
                      className="mb-2 p-0"
                      icon="external-link-alt"
                      size="xs"
                      cursor={'pointer'}
                    />
                  </Button>
                </div>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">{Translate('Assigned To')}:</h6>
                <h6 className="mb-2 text-uppercase">
                  {workOrderVoucherData?.assigned_to_user?.name?.length > 0
                    ? workOrderVoucherData.assigned_to_user.name
                    : '-'}
                </h6>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">
                  {Translate('Delivery ETA days')}:
                </h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {workOrderVoucherData?.delivery_eta_days ?? '-'}
                  </h6>
                </div>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">{Translate('Warehouse Name')}:</h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {workOrderVoucherData?.warehouse_name ?? '-'}
                  </h6>
                </div>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">{Translate('Project')}:</h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {workOrderVoucherData?.project_title ?? '-'}
                  </h6>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold me-auto">
                  {Translate('Reference')}:
                </h6>
                <div className="d-flex flex-row">
                  <h6 className={offCanvas ? 'mb-1 reference-style' : 'mb-2'}>
                    {workOrderVoucherData?.reference}
                  </h6>
                  <ReferenceCopyToClipBoard data={workOrderVoucherData} />
                </div>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold me-auto">
                  {Translate('Enquiry')}:
                </h6>
                <div className="d-flex flex-row">
                  <p
                    className={`m-0 text-dark ${
                      offCanvas ? 'mb-1 reference-style' : 'mb-2'
                    } `}
                  >
                    {workOrderVoucherData?.enquiry_id ? (
                      <a
                        href="#"
                        className="text-dark"
                        onClick={() =>
                          handleVoucherPreview({
                            type: 'ENQUIRY',
                            targetId: workOrderVoucherData?.enquiry_id
                          })
                        }
                      >
                        {workOrderVoucherData?.enquiry_reference ?? '-'}
                      </a>
                    ) : (
                      workOrderVoucherData?.enquiry_reference ?? ''
                    )}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold me-auto">
                  {Translate('Estimation')}:
                </h6>
                <div className="d-flex flex-row">
                  <a
                    href="#"
                    className={`text-dark ${
                      offCanvas ? 'mb-1 reference-style' : 'mb-2'
                    }`}
                    onClick={() =>
                      handleVoucherPreview({
                        type: 'ESTIMATION',
                        targetId: workOrderVoucherData?.estimation_id,
                        modalView: true
                      })
                    }
                  >
                    {workOrderVoucherData?.estimation_reference ?? '-'}
                  </a>
                </div>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold me-auto">
                  {Translate('Sales Quotation')}:
                </h6>
                <div className="d-flex flex-row">
                  <a
                    href="#"
                    className={`text-dark ${
                      offCanvas ? 'mb-1 reference-style' : 'mb-2'
                    }`}
                    onClick={() =>
                      handleVoucherPreview({
                        type: 'SQ',
                        targetId: workOrderVoucherData?.sales_quotation?.id
                      })
                    }
                  >
                    {workOrderVoucherData?.sales_quotation?.reference ?? '-'}
                  </a>
                </div>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold me-auto">
                  {Translate('Sales order')}:
                </h6>
                <div className="d-flex flex-row">
                  <a
                    href="#"
                    className={`text-dark ${
                      offCanvas ? 'mb-1 reference-style' : 'mb-2'
                    }`}
                    onClick={() =>
                      handleVoucherPreview({
                        type: 'SO',
                        targetId: workOrderVoucherData?.sales_order_id
                      })
                    }
                  >
                    {workOrderVoucherData?.sales_order?.reference ?? '-'}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title="Voiding Form">
                  <SimpleBar className="simplebar-custom-height-work-order">
                    <VoidTransactionTab
                      transType={workOrderVoucherData.trans_type}
                      transId={workOrderVoucherData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title="Overview">
                <SimpleBar className="simplebar-custom-height-work-order">
                  <WorkOrderView
                    workOrderVoucherData={workOrderVoucherData}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                    setWorkOrderVoucherData={setWorkOrderVoucherData}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="material_requests" title="Material Requests">
                <SimpleBar className="simplebar-custom-height-work-order simplebar-d-flex">
                  <WorkOrderMaterialRequestesTabView
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.materialRequestes}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                    workOrderId={workOrderVoucherData?.id}
                    WorkOrderDetailsArray={workOrderVoucherData?.details}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="additional_costs" title="Additional Costs">
                <SimpleBar className="simplebar-custom-height-work-order">
                  <WorkOrderAdditonalCostsTabView
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.additionalCosts}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                    workOrderId={workOrderVoucherData?.id}
                    WorkOrderDetailsArray={workOrderVoucherData?.details}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="releases" title="Releases">
                <SimpleBar className="simplebar-custom-height-work-order">
                  <WorkOrderReleasesTabView
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.releases}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                    workOrderId={workOrderVoucherData?.id}
                    WorkOrderDetailsArray={workOrderVoucherData?.details}
                    setWorkOrderVoucherData={setWorkOrderVoucherData}
                  />
                </SimpleBar>
              </Tab>
              <Tab eventKey="deliveries" title="Deliveries">
                <SimpleBar className="simplebar-custom-height-work-order">
                  <WorkOrderDeliveriesTabView
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.delivery}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                    workOrderId={workOrderVoucherData?.id}
                    WorkOrderDetailsArray={workOrderVoucherData?.details}
                    setWorkOrderVoucherData={setWorkOrderVoucherData}
                  />
                </SimpleBar>
              </Tab>
              {workOrderVoucherData.media &&
              workOrderVoucherData.media.length > 0 ? (
                <Tab eventKey="attachments" title="Attachments">
                  <SimpleBar className="simplebar-custom-height-work-order">
                    <AttachementsView
                      data={workOrderVoucherData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {workOrderVoucherData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-custom-height-work-order pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`production/work-orders-print/${workOrderVoucherData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {workOrderVoucherData &&
              workOrderVoucherData.created_user_full_name
                ? workOrderVoucherData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {workOrderVoucherData && workOrderVoucherData.created_at
                ? workOrderVoucherData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={person}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'WO'}
        id={workOrderVoucherData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'production/work-order-mail/' + workOrderVoucherData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={workOrderVoucherData.customer_email}
      />

      <VoucherDetail
        show={!!showVoucherDetailsPreview?.show}
        onHide={() => setShowVoucherDetailsPreview(undefined)}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={showVoucherDetailsPreview?.type}
        voucherId={showVoucherDetailsPreview?.targetId}
        infoPage={true}
        offCanvas={true}
        modalView={showVoucherDetailsPreview?.modalView}
      />

      <ConfirmWarning
        show={status}
        onHide={() => setStatus(false)}
        content={Translate('Are you sure to complete this work order?')}
        handleSubmit={handleSubmit}
        onSave={onSave}
      />
    </>
  );
}

WorkOrderTabView.propTypes = {
  workOrderVoucherData: PropTypes.any,
  setWorkOrderVoucherData: PropTypes.func,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  onHide: PropTypes.func
};

export default WorkOrderTabView;
