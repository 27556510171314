import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const PhoneNumberInput = ({ onChange, value, isInvalid, name, ...rest }) => {
  const handleChange = e => {
    e.target.value = e.target.value.replace(/\s+/g, '');
    let inputValue = e.target.value;
    let pattern = /^[+{1}]?\d*$/;
    if (pattern.test(inputValue)) {
      onChange(e);
    }
  };

  return (
    <Form.Control
      {...rest}
      type="tel"
      name={name}
      onChange={handleChange}
      value={value}
      isInvalid={isInvalid}
    />
  );
};

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  isInvalid: PropTypes.any,
  value: PropTypes.string
};

export default PhoneNumberInput;
