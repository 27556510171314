import React from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActivityLoading from 'components/customer-details/ActivityLoading';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import ActivityFetching from 'module/Common/Loading/ActivityFetching';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const SupplierDetailsTab = ({
  data,
  activity,
  activityLoading,
  setActivity,
  links,
  setLinks
}) => {
  const Translate = useAxisproTranslate();
  const loadNextPage = async next_page_url => {
    await axios
      .get(next_page_url)
      .then(res => {
        setActivity([...activity, ...res.data.data.data]);
        setLinks(res.data.data);
      })
      .catch(error => {
        console.log(error);
        showToast(
          Translate('Something went wrong please contact admin'),
          'error'
        );
      });
  };
  return (
    <Tabs
      defaultActiveKey={1}
      className="border-top mt-3"
      style={{ margin: '0 -1rem' }}
    >
      {data.address ? (
        <Tab eventKey={1} title={Translate('ADDRESS')}>
          <ul className="pt-4 px-0 m-0 customer-info-tab-content">
            <li className="d-flex flex-row">
              <FontAwesomeIcon
                icon="address-card"
                size="sm"
                className="me-2 mt-1"
                color="#00d27a"
              />
              <div>
                <h5>{Translate('BILLING ADDRESS')}</h5>
                <p className="text-muted mt-2">{data.address}</p>
              </div>
            </li>
          </ul>
        </Tab>
      ) : (
        ''
      )}
      {data && data.supplier_contacts && data.supplier_contacts.length > 0 ? (
        <Tab eventKey={2} title={Translate('CONTACT PERSONS')}>
          <ul className="pt-4 px-0 m-0 customer-info-tab-content">
            {data &&
              data.supplier_contacts &&
              data.supplier_contacts.map((item, key) => (
                <li className="d-flex flex-row" key={key}>
                  <FontAwesomeIcon
                    icon="user-circle"
                    size="sm"
                    className="me-2 mt-1"
                    color="#00d27a"
                  />
                  <div>
                    <h5>{item.name}</h5>
                    <p className="text-muted mt-2">
                      <a href="mailto:mr.bipinks@gmail.com">
                        {item.email_address}
                      </a>
                      <br />
                      <a href="tel:+0544706704">{item.contact_number}</a>
                      <br />
                      <small>{item.address}</small>
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </Tab>
      ) : (
        ''
      )}
      {activity && activity.length > 0 ? (
        <Tab eventKey={3} title={Translate('ACTIVITY')}>
          {!activityLoading ? (
            <>
              <ul className="pt-4 px-0 m-0 customer-info-tab-content activity-tree">
                {activity &&
                  activity.map((item, key) => (
                    <li className="d-flex flex-row" key={key}>
                      <div className="icon-cover d-flex justify-content-center align-items-center shadow border">
                        <FontAwesomeIcon
                          icon="comment-dots"
                          size="sm"
                          color="#344050"
                        />
                      </div>
                      <Card className="flex-fill">
                        <Card.Body>
                          <p className="text-muted small mb-2">
                            {item.created_at}
                          </p>
                          <div className="d-flex gap-2">
                            <h6 className="m-0">{item.description}</h6>
                            <h6 className="m-0">-</h6>
                            <h6 className="m-0">{item.event}</h6>
                          </div>
                          <p className="text-muted mt-3 mb-0 d-flex gap-2">
                            {item.reference ? item.reference : ''} {item.event}
                            <b>
                              {' '}
                              - {Translate('By')} {item.causer_name}
                            </b>
                          </p>
                        </Card.Body>
                      </Card>
                    </li>
                  ))}
              </ul>
              {links.next_page_url ? (
                <ActivityFetching links={links} loadNextPage={loadNextPage} />
              ) : (
                ''
              )}
            </>
          ) : (
            <ActivityLoading />
          )}
          {/* <ul className="pt-4 px-0 m-0 customer-info-tab-content activity-tree">
          <li className="d-flex flex-row">
            <div className="icon-cover d-flex justify-content-center align-items-center shadow border">
              <FontAwesomeIcon icon="comment-dots" size="sm" color="#344050" />
            </div>
            <Card className="flex-fill">
              <Card.Body>
                <p className="text-muted small mb-2">28 Jun 2022 10:36 AM</p>
                <h6>Invoice deleted</h6>
                <p className="text-muted mt-3 mb-0">
                  Invoice INV-000001 deleted <b>by Bipin Kareparambil</b>
                </p>
              </Card.Body>
            </Card>
          </li>
        </ul> */}
        </Tab>
      ) : (
        ''
      )}
    </Tabs>
  );
};

SupplierDetailsTab.propTypes = {
  data: PropTypes.object,
  activity: PropTypes.any,
  activityLoading: PropTypes.bool,
  setActivity: PropTypes.func,
  links: PropTypes.any,
  setLinks: PropTypes.func
};

export default SupplierDetailsTab;
