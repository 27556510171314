import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';

export default function ItemCodeAutoComplete({
  keyName,
  filter_keys,
  onChange,
  SelectItemKeyName,
  ...rest
}) {
  const [apiCallDelay, setApiCallDelay] = useState(null);
  function handleChange(event) {
    const onChangeType = typeof onChange;
    if (onChangeType === 'function') onChange(event);
    let searchKey = event.target.value;
    clearTimeout(apiCallDelay);
    const apiCall = setTimeout(() => {
      axios
        .get('inventory/items-auto', {
          params: {
            ...{ item_name: searchKey },
            ...filter_keys
          }
        })
        .then(response => {
          if (searchKey?.length > 0 && response?.data?.data?.length > 0) {
            const firstResult = response.data.data?.[0];
            const result = {
              label: firstResult.item_name,
              value: keyName ? firstResult[keyName] : firstResult.id,
              item: firstResult,
              type: firstResult.type,
              stock_id: firstResult.stock_id
            };

            if (
              typeof SelectItemKeyName === 'string' &&
              onChangeType === 'function'
            ) {
              onChange(result, {
                name: SelectItemKeyName
              });
            }
          } else {
            onChange(null, {
              name: SelectItemKeyName
            });
          }
        })
        .catch(error => {
          console.error(error);
          onChange(null, {
            name: SelectItemKeyName
          });
        });
    }, 1200);

    setApiCallDelay(apiCall);
  }

  return <Form.Control type="text" {...rest} onChange={handleChange} />;
}

ItemCodeAutoComplete.propTypes = {
  filter_keys: PropTypes.object,
  keyName: PropTypes.string,
  onChange: PropTypes.func,
  SelectItemKeyName: PropTypes.string
};
