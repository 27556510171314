import { React } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectMultiUser = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  onFocus,
  ...rest
}) => {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: 'user/users-autocomplete',
      setParams: searchKey => ({ name: searchKey }),
      setOptions: option => ({
        label: option.full_name,
        value: option.id
      }),
      onFocus: onFocus
    });

  return (
    <AsyncSelect
      isMulti
      loadOptions={fetchData}
      placeholder={placeholder ?? ''}
      isLoading={loading}
      isClearable
      defaultOptions={defaultOptions}
      onFocus={setDefaultResult}
      value={value}
      onChange={handleFieldChange}
      name={name ?? 'user'}
      className={`${error && 'is-invalid'}`}
      classNamePrefix="app-react-select react-multi-select"
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          ...(!rest?.isMulti ? { height: 32 } : null),
          minHeight: 32,
          ...style
        })
      }}
      {...rest}
    />
  );
};

SelectMultiUser.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  style: PropTypes.object,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func
};

export default SelectMultiUser;
