import { React } from 'react';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IoMdSettings } from 'react-icons/io';
import SalesSettings from 'module/SystemSettings/Components/SalesSettings/SalesSettings';
import PurchaseSettings from 'module/SystemSettings/Components/PurchaseSettings/PurchaseSettings';
const SettingOffcanvas = ({ show, onHide, settings_type, module }) => {
  const Translate = useAxisproTranslate();
  return (
    <Offcanvas
      show={show}
      placement="end"
      style={{ width: '28%' }}
      onHide={onHide}
    >
      <Offcanvas.Header
        closeButton
        style={{ boxShadow: 'none' }}
        className="offcanvas-heading-style border-bottom"
      >
        <div>
          <IoMdSettings size={25} className="me-2" />
          {Translate(settings_type ?? '')}
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        {module === 'sales' && (
          <SalesSettings Offcanvas={true} settings_type={settings_type} />
        )}
        {module === 'purchase' && (
          <PurchaseSettings Offcanvas={true} settings_type={settings_type} />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};
SettingOffcanvas.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  settings_type: PropTypes.string,
  module: PropTypes.string
};
export default SettingOffcanvas;
