import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import axios from 'axios';

export default function EstimationEntryHandleStaus({
  setEstimationEntryData,
  setEstimationEntries,
  status,
  targetId
}) {
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const variant =
    status === 'closed' ? 'success' : status === 'open' ? 'warning' : 'danger';

  const showErrorMessage = (message = undefined) =>
    showToast(
      Translate(
        message ??
          'Something went wrong, please refresh the page and try again.'
      ),
      'error'
    );

  function handleStatus(event) {
    event.preventDefault();
    let newStatus = event.target?.hash?.replace('#', '');
    let availableStatus = ['pending', 'closed', 'open'];

    if (!availableStatus.includes(newStatus)) {
      showToast(
        Translate(
          `Invalid request, status must be one of the given items (${availableStatus.join(
            ', '
          )})`,
          'error'
        )
      );
      return;
    }

    setIsLoading(true);

    axios
      .put(`crm/estimation-status-update/${targetId}?status=${newStatus}`)
      .then(response => {
        if (response?.data?.success) {
          showToast(
            Translate(
              response?.data?.data?.message ?? 'Status updated successfully.'
            ),
            'success'
          );

          if (typeof setEstimationEntryData === 'function') {
            setEstimationEntryData(prev => ({ ...prev, status: newStatus }));
          }

          if (typeof setEstimationEntries === 'function') {
            setEstimationEntries(prev => ({
              ...prev,
              data: Array.isArray(prev?.data)
                ? prev.data.reduce((newResult, item) => {
                    if (item?.id === targetId) {
                      item.status = newStatus;
                    }
                    newResult.push(item);
                    return newResult;
                  }, [])
                : []
            }));
          }
        } else {
          showErrorMessage();
        }
      })
      .catch(error => {
        showErrorMessage(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return isLoading ? (
    <Button variant={variant} disabled size="sm">
      <Spinner
        size="sm"
        style={{
          width: 13,
          height: 13
        }}
      />
    </Button>
  ) : (
    <Dropdown>
      <Dropdown.Toggle
        variant={variant}
        size="sm"
        id="estimation-status-dropdown"
        className="text-capitalize"
        disabled={isLoading}
      >
        <small>{Translate(status)}</small>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          href="#pending"
          disabled={!!(status === 'pending')}
          onClick={handleStatus}
        >
          {Translate('Pending')}
        </Dropdown.Item>
        <Dropdown.Item
          href="#open"
          disabled={!!(status === 'open')}
          onClick={handleStatus}
        >
          {Translate('Open')}
        </Dropdown.Item>
        <Dropdown.Item
          href="#closed"
          disabled={!!(status === 'closed')}
          onClick={handleStatus}
        >
          {Translate('Closed')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

EstimationEntryHandleStaus.propTypes = {
  setEstimationEntryData: PropTypes.func,
  setEstimationEntries: PropTypes.func,
  status: PropTypes.string,
  targetId: PropTypes.string
};
