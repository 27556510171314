import React from 'react';
import UseAnimations from 'react-useanimations';
import infinity from 'react-useanimations/lib/infinity';

function FormLayer() {
  return (
    <div className="form-layer-wrapper d-flex align-items-center justify-content-center">
      <UseAnimations animation={infinity} size={40} />
    </div>
  );
}

export default FormLayer;
