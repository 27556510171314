export const salesEndPoints = {
  sales_quotation: 'sales/sales-quote',
  sales_order: 'sales/sales-order',
  customer: 'sales/customers'
};

export const settingsEndPoints = {
  sales_type: 'settings/sales-types',
  terms_and_conditions: 'settings/terms-and-conditions',
  custom_field: {
    find: 'settings/custom-fields/find-by-form-slug'
  }
};

export const systemEndPoints = {
  void: 'settings/void-transaction-details'
};

export const commonEndPoints = {
  draft: {
    find: 'draft/find',
    save: 'draft/save',
    delete: 'draft/discard'
  },
  addToCartBulk: 'sales/add-to-cart-bulk',
  addToCart: 'sales/add-to-cart',
  salesTypePrice: 'inventory/sales-type-price',
  print: {
    sq: 'sales/quotation/print',
    so: 'sales/order/print'
  },
  email: {
    sq: 'sales/sales-quotation-email/',
    so: 'sales/sales-order-email/'
  }
};
