import { React, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

function SelectBatch({
  handleFieldChange,
  value,
  itemId,
  trans_date,
  error,
  onKeyDown
}) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const setBatch = async () => {
    setLoading(true);
    try {
      let response = await axios.get('inventory/batches-autocomplete', {
        params: { stock_id: itemId, trans_date: trans_date }
      });
      let data = response && response.data ? response.data.data : [];
      return data.map(item => ({
        label: item.batch_number_display,
        value: item.batch_number
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleFocus = async () => {
    if (defaultOptions.length === 0) {
      const defaultOptionsData = await setBatch('');
      setDefaultOptions(defaultOptionsData);
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={setBatch}
      isLoading={loading}
      defaultOptions={defaultOptions}
      onFocus={handleFocus}
      isSelected={true}
      name={value ? value : 'batch_id'}
      value={value}
      placeholder=""
      onChange={handleFieldChange}
      onKeyDown={onKeyDown}
      className={`w-100  ${error ? ' form-control ps-0 py-0 is-invalid' : ''}`}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({
          ...provided,
          zIndex: 9999,
          fontFamily: 'sans-serif',
          fontSize: '12px',
          borderRadius: '0px',
          textTransform: 'capitalize',
          overflow: 'hidden'
        }),
        control: (base, state) => ({
          ...base,
          boxShadow: state.isFocused ? 0 : 0,
          borderRadius: '0px',
          border: '1px solid #b5b5b5',
          height: '10px'
        })
      }}
      menuPortalTarget={document.body}
    ></AsyncSelect>
  );
}

SelectBatch.propTypes = {
  handleFieldChange: PropTypes.func,
  itemId: PropTypes.any,
  trans_date: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  onKeyDown: PropTypes.func
};

export default SelectBatch;
