import { React, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { ButtonGroup, Card, Container, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { FaUserCog } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { EditButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import ActivityLog from '../Common/ActivityLog';
import SalesmanView from './SalesmanView';

function SalesmanTabView({ salesmanData, loading, fetchData, offcanvas }) {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState('overview');
  const [upload, setUpload] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    activity_log: false
  });
  const allQueryParams = Object.fromEntries([...queryParams]);
  const permission = {
    update: getAcodaxPermissionSlug('SM', 'update'),
    history_lg: getAcodaxPermissionSlug('LG', 'show-all')
  };
  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        {!offcanvas ? (
          <Card.Header
            className={
              breakpoints.up('sm')
                ? 'd-flex m-0 align-items-center border-bottom'
                : 'border-bottom'
            }
          >
            <>
              <div className="d-flex m-0 align-items-center">
                <FaUserCog size={28} className="me-2 text-dark" />
                <h5
                  className="mb-0 d-flex text-uppercase view-page-header"
                  style={{ fontSize: '25px' }}
                >
                  {salesmanData && salesmanData.salesman_name
                    ? salesmanData.salesman_name
                    : ''}
                </h5>
              </div>
              <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
                <MoreButton setUpload={setUpload} type="SALESMAN" />
                {permission?.update ? (
                  <EditButton
                    style={{ boxShadow: 'none' }}
                    to={`/sales/salesman/info/edit/${
                      salesmanData.id
                    }?${createSearchParams({
                      ...allQueryParams
                    })}`}
                  />
                ) : (
                  ''
                )}
              </ButtonGroup>
            </>
          </Card.Header>
        ) : null}

        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap">
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">{Translate('Name')}:&nbsp;</h6>
                <h6 className="mb-2 text-uppercase">
                  {salesmanData && salesmanData.salesman_name
                    ? salesmanData.salesman_name
                    : 'null'}
                </h6>
              </div>
            </div>
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Salesman ID')}:&nbsp;
                </h6>
                <h6 className="mb-2">
                  {salesmanData && salesmanData?.salesman_code
                    ? salesmanData.salesman_code
                    : ''}
                </h6>
              </div>
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-overview-page-style">
                  <SalesmanView salesmanData={salesmanData} />
                </SimpleBar>
              </Tab>
              {permission?.history_lg ? (
                <Tab eventKey="activity" title={Translate('Activity Log')}>
                  <SimpleBar className="simplebar-overview-page-style">
                    <ActivityLog
                      id={salesmanData.id}
                      type={'SALESMAN'}
                      activeTabKey={key}
                      tabApiCallCompleted={tabApiCallCompleted?.activity_log}
                      setTabApiCallCompleted={setTabApiCallCompleted}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              {salesmanData.media && salesmanData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={salesmanData}
                      fetchData={fetchData}
                      setKey={setKey}
                      deletePermsission={permission?.update}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {salesmanData && salesmanData.created_user_full_name
                ? salesmanData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {salesmanData && salesmanData.created_at
                ? salesmanData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'SALESMAN'}
        id={salesmanData.id}
        setKey={setKey}
      />
    </>
  );
}

SalesmanTabView.propTypes = {
  salesmanData: PropTypes.any,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  offcanvas: PropTypes.bool
};

export default SalesmanTabView;
