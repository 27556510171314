import { React, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import RentalContractTabView from 'module/Common/ViewPages/RentalContract/RentalContractTabView';
import commonFunction from '../commonFunction';

function RentalContractInfo({
  rentalId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState();
  const { fetchData } = commonFunction;
  const [loading, setLoading] = useState(true);
  const apiRequestAbortControllerRef = useRef(null);
  const getRentalContract = async () => {
    try {
      apiRequestAbortControllerRef?.current?.abort();
      apiRequestAbortControllerRef.current = new AbortController();
      setLoading(true);
      await fetchData(
        voidTransactionReport,
        rentalId,
        setLoading,
        setData,
        apiRequestAbortControllerRef.current?.signal
      );
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching rental contract:', error);
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    if (rentalId) {
      getRentalContract();
    }
    return () => {
      apiRequestAbortControllerRef?.current?.abort();
      setData(undefined);
    };
  }, [rentalId]);
  return (
    <>
      {loading ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <RentalContractTabView
          rentalContractData={data}
          loading={loading}
          fetchData={getRentalContract}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      )}
    </>
  );
}

RentalContractInfo.propTypes = {
  rentalId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func,
  contentLoading: PropTypes.bool
};

export default RentalContractInfo;
