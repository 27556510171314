import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import classNames from 'classnames';

export default function EntriesPopUpLineItemAccordionViewTitleSection({
  type,
  taxIncluded,
  enableTaxGroup,
  enableBatchSection,
  ...rest
}) {
  const Translate = useAxisproTranslate();
  return (
    <tr {...rest}>
      <th className="fw-bold py-2">{Translate('SL')}</th>
      <th className="fw-bold py-2">{Translate('Item Code')}</th>
      <th className="fw-bold py-2">{Translate('Item Name')}</th>
      {type === 'ESTIMATION' ? (
        <>
          <th className="fw-bold py-2">{Translate('Length')}</th>
          <th className="fw-bold py-2">{Translate('Width')}</th>
          <th className="fw-bold py-2">{Translate('Height')}</th>
          <th className="fw-bold py-2">{Translate('Weight')}</th>
        </>
      ) : (
        <>
          <th className="fw-bold py-2">
            <div className="d-flex flex-row align-items-center gap-1">
              <span>{Translate('Unit Price')}</span>
              {taxIncluded ? (
                <Badge bg="warning" className="rounded">
                  <small>{Translate('Inc Tax')}</small>
                </Badge>
              ) : null}
            </div>
          </th>
          <th className="fw-bold py-2">{Translate('Unit Tax')}</th>
          <th className="fw-bold py-2">{Translate('Discount / Unit')}</th>

          {enableTaxGroup ? (
            <th className="fw-bold py-2">{Translate('Tax Group')}</th>
          ) : null}

          {enableBatchSection ? (
            <th className="fw-bold py-2">{Translate('Batch')}</th>
          ) : null}
        </>
      )}

      <th
        className={classNames('fw-bold py-2', {
          'border-end-0': type === 'ESTIMATION'
        })}
      >
        {Translate('Quantity')}
      </th>
      {type !== 'ESTIMATION' ? (
        <th className="fw-bold py-2 border-end-0">{Translate('Total')}</th>
      ) : null}

      <th className="border-start-0"></th>
    </tr>
  );
}

EntriesPopUpLineItemAccordionViewTitleSection.propTypes = {
  type: PropTypes.oneOf(['FIXED-ASSET']).isRequired,
  taxIncluded: PropTypes.bool,
  enableTaxGroup: PropTypes.bool,
  enableBatchSection: PropTypes.bool
};
