import { React, useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import { Badge, Offcanvas } from 'react-bootstrap';

import { BiLinkExternal } from 'react-icons/bi';

import { showToast } from 'module/Common/Toast/toast';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import removeRefData from 'helpers/removeRefData';
import ReportBasicTable from 'components/report-components/ReportBasicTable';
import ReportBasicTableFooter from 'components/report-components/ReportBasicTableFooter';
import ReportinLineItemLoading from 'components/report-components/ReportinLineItemLoading';
import ReportLineItem from 'components/report-components/ReportLineItem';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import DataFetching from './DataFetching';

const LedgerReportOffcanvas = () => {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const [dataArray, setDataArray] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);

  const allQueryParams = Object.fromEntries([...queryParams]);
  const [person, setPerson] = useState({
    value: ''
  });
  let navigate = useNavigate();
  const account_code_ref = queryParams.get('account_code_ref');
  const show = queryParams.get('ledger_offcanvas') ? true : false;
  const [balance, setBalance] = useState();
  const [nextLink, setNextLink] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const headingsArray = [
    {
      title: Translate('DATE'),
      className: 'ledger-report-table-heading text-dark border-0'
    },
    {
      title: Translate('TRANSACTION TYPE'),
      className: 'ledger-report-table-heading text-dark border-0'
    },
    {
      title: Translate('REFERENCE'),
      className: 'ledger-report-table-heading text-dark border-0'
    },
    {
      title: Translate('PERSON'),
      className: 'ledger-report-table-heading text-dark border-0'
    },
    {
      title: Translate('COST CENTER'),
      className: 'ledger-report-table-heading text-dark border-0'
    },
    {
      title: Translate('MEMO'),
      className: 'ledger-report-table-heading text-dark border-0'
    },
    {
      title: Translate('DEBIT'),
      className: 'ledger-report-table-heading text-dark border-0'
    },
    {
      title: Translate('CREDIT'),
      className: 'ledger-report-table-heading text-dark border-0'
    },
    {
      title: Translate('BALANCE'),
      className:
        'd-flex justify-content-end ledger-report-table-heading text-dark border-0'
    }
  ];

  // const handleVoucherDetailsArea = () => {
  //   setShowVoucherDetailArea(!showVoucherDetailArea);
  // };

  // const clickOnVoucher = (voucher_id, trans_type) => {
  //   setVoucherId(voucher_id);
  //   let slug = getPermissionSlug(trans_type);
  //   if (SystemPermission(slug, user)) {
  //     setShowVoucherDetailArea(true);
  //   }
  // };

  const getData = () => {
    setLoading(true);
    setNextLink(null);
    axios
      .get('finance/reports/ledger-report', {
        params: removeRefData(allQueryParams)
      })
      .then(res => {
        if (res.data.success) {
          setBalance(res.data.opening_balance);
          let cumulativeSum = 0;
          const updatedArray = res.data.data.data.map(obj => {
            cumulativeSum += parseFloat(obj.amount);
            return {
              ...obj,
              balance_formatted:
                cumulativeSum + parseFloat(res.data.opening_balance)
            };
          });
          let calculatingAmount = res.data.data.data.reduce(
            (sum, item) => sum + parseFloat(item.amount),
            0
          );
          setTotalAmount(calculatingAmount);
          setDataArray(updatedArray);
          if (res?.data?.data?.links?.next !== null) {
            setNextLink(res.data.data.links.next);
          }
          setLoading(false);
        }
      })
      .catch(error => {
        showToast('Something went wrong please contact admin', 'error');
        console.log(error);
      });
  };

  const loadNextPage = async () => {
    if (nextLink && nextLink !== null) {
      await axios
        .get(nextLink)
        .then(res => {
          if (res.data.success) {
            let cumulativeSum = 0;
            const updatedArray = res.data.data.data.map(obj => {
              cumulativeSum += parseFloat(obj.amount);
              return {
                ...obj,
                balance_formatted:
                  cumulativeSum +
                  parseFloat(res.data.opening_balance) +
                  parseFloat(totalAmount)
              };
            });
            let calculatingAmount = res.data.data.data.reduce(
              (sum, item) => sum + parseFloat(item.amount),
              0
            );
            setTotalAmount(calculatingAmount + parseFloat(totalAmount));
            setDataArray([...dataArray, ...updatedArray]);
            if (res?.data?.data?.links?.next !== null) {
              setNextLink(res.data.data.links.next);
            } else {
              setNextLink(null);
            }
          }
        })
        .catch(error => {
          console.log(error);
          showToast('Something went wrong please contact admin', 'error');
        });
    }
  };

  useEffect(() => {
    const { account_code, date_from, date_to } = allQueryParams;
    account_code && date_from && date_to && getData();
  }, [
    allQueryParams.account_code,
    allQueryParams.date_from,
    allQueryParams.date_to,
    allQueryParams.page,
    allQueryParams.cost_center_id,
    allQueryParams.transaction_type
  ]);

  // useEffect(() => {
  //   navigate({
  //     pathname: '/reports/ledger-report',
  //     search: `?${createSearchParams({
  //       ...formData,
  //       account_code_ref: formData.account_code_ref
  //         ? JSON.stringify(formData.account_code_ref)
  //         : '',
  //       cost_center_id_ref: formData.cost_center_id_ref
  //         ? JSON.stringify(formData.cost_center_id_ref)
  //         : ''
  //     })}`
  //   });
  // }, [filter]);

  const clickOnCustomer = id => {
    setPerson({
      value: id
    });
    setCustomerDetailsArea(true);
  };

  const clickOnSupplier = id => {
    setPerson({
      value: id
    });
    setSupplierDetailsArea(true);
  };

  const renderPersonDetails = reportLineItem => {
    switch (reportLineItem.person_type_id) {
      case 'CUSTOMER':
        return getAcodaxPermissionSlug('CS', 'show') ? (
          <p
            className="m-0 p-0 text-primary"
            style={{ cursor: 'pointer' }}
            onClick={() => clickOnCustomer(reportLineItem.person_id)}
          >
            {reportLineItem.person_name}
          </p>
        ) : (
          <p className="m-0 p-0">{reportLineItem.person_name}</p>
        );
      case 'SUPPLIER':
        return getAcodaxPermissionSlug('supplier', 'show') ? (
          <p
            className="m-0 p-0 text-primary"
            style={{ cursor: 'pointer' }}
            onClick={() => clickOnSupplier(reportLineItem.person_id)}
          >
            {reportLineItem.person_name}
          </p>
        ) : (
          <p className="m-0 p-0">{reportLineItem.person_name}</p>
        );
      default:
        return '';
    }
  };

  return (
    <Offcanvas
      show={show}
      placement="end"
      style={{ width: '80%', overflowY: 'scroll' }}
      onHide={() => {
        navigate(-1);
      }}
    >
      <Offcanvas.Header closeButton className="border-bottom ">
        <div className="text-dark fw-bold text-primary ms-4">
          <span className="pe-2">
            <BiLinkExternal
              className="text-primary cursor-pointer"
              size={18}
              onClick={() => {
                navigate(
                  `/reports/ledger-report?${createSearchParams({
                    ...allQueryParams,
                    show_report: true
                  })}`
                );
              }}
            />
          </span>
          {JSON.parse(account_code_ref)?.label}
        </div>
      </Offcanvas.Header>
      {!allQueryParams.account_code ||
      !allQueryParams.date_from ||
      !allQueryParams.date_to ? (
        ''
      ) : (
        <>
          <div className="w-100 d-flex align-items-center justify-content-center">
            <ReportBasicTable
              off_canvas={true}
              headingsArray={headingsArray}
              title={Translate('Ledger Report')}
            >
              {loading ? (
                [1, 2, 3, 4, 5].map(index => (
                  <ReportinLineItemLoading key={index} colSpan={10} />
                ))
              ) : dataArray && Array.isArray(dataArray) ? (
                dataArray.length > 0 || balance !== 0 ? (
                  <>
                    <ReportLineItem
                      className={'border-top'}
                      itemsArray={[
                        {
                          value: Translate('Opening Balance'),
                          className: 'h6 fw-bold text-dark'
                        },
                        {
                          value: null,
                          className: 'fs--1 fw-bold'
                        },
                        {
                          value: null,
                          className: 'fs--1 fw-bold'
                        },
                        {
                          value: null,
                          className: 'fs--1 fw-bold'
                        },
                        {
                          value: null,
                          className: 'fs--1 fw-bold'
                        },
                        {
                          value: null,
                          className: 'fs--1 fw-bold'
                        },
                        {
                          value:
                            balance > 0
                              ? balance.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
                              : '-',
                          className: 'ledger-report-contents'
                        },
                        {
                          value:
                            balance < 0
                              ? balance.toLocaleString(navigator.language, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
                              : '-',
                          className: 'ledger-report-contents'
                        },
                        {
                          value: (
                            <div className="d-flex justify-content-end text-dark">
                              {balance
                                ? balance.toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })
                                : '-'}
                            </div>
                          ),
                          className: 'ledger-report-contents'
                        }
                      ]}
                    />
                    {dataArray &&
                      dataArray.length > 0 &&
                      dataArray.map((reportLineItem, index) => (
                        <ReportLineItem
                          key={index}
                          className={'border-top'}
                          itemsArray={[
                            {
                              value: reportLineItem.trans_date_formatted,
                              className: 'ledger-report-contents'
                            },
                            {
                              value: reportLineItem.trans_type_name,
                              className: 'ledger-report-contents'
                            },
                            {
                              value:
                                // <a
                                //   href="#"
                                // onClick={() =>
                                //   clickOnVoucher(
                                //     reportLineItem.voucher_id,
                                //     reportLineItem.trans_type
                                //   )
                                // }
                                // >
                                reportLineItem.reference,
                              // </a>
                              className: 'ledger-report-contents'
                            },
                            {
                              value: renderPersonDetails(reportLineItem),
                              className: 'ledger-report-contents'
                            },
                            {
                              value: reportLineItem.cost_center_name ?? '-',
                              className: 'ledger-report-contents'
                            },
                            {
                              value: reportLineItem.description,
                              className: 'ledger-report-contents'
                            },
                            {
                              value:
                                reportLineItem.amount >= 0
                                  ? Math.abs(
                                      reportLineItem.amount
                                    ).toLocaleString(navigator.language, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  : null,
                              className: 'ledger-report-contents'
                            },
                            {
                              value:
                                reportLineItem.amount < 0
                                  ? Math.abs(
                                      reportLineItem.amount
                                    ).toLocaleString(navigator.language, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  : null,
                              className: 'ledger-report-contents'
                            },
                            {
                              value: (
                                <div className="d-flex justify-content-end text-dark">
                                  {reportLineItem.balance_formatted.toLocaleString(
                                    navigator.language,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )}
                                </div>
                              ),
                              className: 'ledger-report-contents'
                            }
                          ]}
                        />
                      ))}
                  </>
                ) : (
                  <ReportLineItem
                    className="border-top"
                    itemsArray={[
                      {
                        value: (
                          <Badge bg="danger">
                            {Translate('No Data Available')}!
                          </Badge>
                        ),
                        colSpan: 10
                      }
                    ]}
                  />
                )
              ) : null}
              {nextLink && nextLink !== null && dataArray?.length > 0 && (
                <tr>
                  <td colSpan={10}>
                    <DataFetching
                      nextLink={nextLink}
                      loadNextPage={loadNextPage}
                    />
                  </td>
                </tr>
              )}
              <ReportBasicTableFooter colSpan={4} />
            </ReportBasicTable>
          </div>
        </>
      )}
      <div className="mt-5 small w-100"></div>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={() => setCustomerDetailsArea(!showCustomerDetailsArea)}
        value={person}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <SupplierDetails
        show={showSupplierDetailsArea}
        onHide={() => setSupplierDetailsArea(!showSupplierDetailsArea)}
        value={person}
        showSupplierDetailsArea={showSupplierDetailsArea}
      />
      {/* <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={''}
        voucherId={voucherId}
        offCanvas={true}
      /> */}
    </Offcanvas>
  );
};
export default LedgerReportOffcanvas;
