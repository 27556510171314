import { React, useContext, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './timelinestyle.css';

import { FaEye } from 'react-icons/fa';

import { AuthWizardContext } from 'context/Context';
import { getAcodaxPermissionSlug } from '../Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PrintButton from 'components/print-button/PrintButton';
import VoucherDetail from 'components/voucher-details/VoucherDetail';

function TransactionsData({ transaction, type }) {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [voucherId, setVoucherId] = useState();
  const [transType, setTransType] = useState();
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);

  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  const clickOnVoucher = (voucher_id, trans_type) => {
    setVoucherId(voucher_id);
    setTransType(trans_type);
    setShowVoucherDetailArea(true);
  };

  const renderPrint = (item, type) => {
    if (getAcodaxPermissionSlug(item?.trans_type ?? type, 'print')) {
      return (
        <PrintButton
          variant="transparent"
          className="p-1 me-1 mx-1 text-black"
          style={{ boxShadow: 'none' }}
          url={
            item.trans_type === 'SI' || type === 'SI'
              ? `sales/invoice/print/${item.id}`
              : item.trans_type === 'SD' || type === 'SD'
              ? `sales/delivery/print/${item.id}`
              : item.trans_type === 'SO' || type === 'SO'
              ? `sales/order/print/${item.id}`
              : item.trans_type === 'PO' || type === 'PO'
              ? `purchase/purchase-orders/print/${item.id}`
              : item.trans_type === 'PI' || type === 'PI'
              ? `purchase/purchase-invoices/print/${item.id}`
              : item.trans_type === 'JV' || type === 'JV'
              ? `/finance/journal-entry/print/${item.id}`
              : item.trans_type === 'PV' || type === 'PV'
              ? `/finance/payment-voucher/print/${item.id}`
              : item.trans_type === 'RV' || type === 'RV'
              ? `/finance/receipt-voucher/print/${item.id}`
              : item.trans_type === 'IA' || type === 'IA'
              ? `/inventory/inventory-adjustment/print/${item.id}`
              : item.trans_type === 'BT' || type === 'BT'
              ? `/finance/bank-transfer/print/${item.id}`
              : `purchase/purchase-deliveries/print/${item.id}`
          }
          pageTitle={`${item.trans_type} Print View`}
        />
      );
    }
  };

  return (
    <>
      <Container className="mt-2">
        <Row>
          <div className="widget-wrap">
            {transaction.map((item, key) => (
              <div className={user.language == 'AR' ? 'rtl' : 'vtl'} key={key}>
                <div className="event d-flex justify-content-between align-item-center">
                  <div>
                    <h4 className="fs--1 m-0 mb-1 text-uppercase text-danger fw-bold">
                      {item.trans_type === 'SI' || type === 'SI'
                        ? Translate('Sales invoice')
                        : item.trans_type === 'SD' || type === 'SD'
                        ? Translate('Sales Delivery')
                        : item.trans_type === 'SO' || type === 'SO'
                        ? Translate('sales order')
                        : item.trans_type === 'PO' || type === 'PO'
                        ? Translate('purchase order')
                        : item.trans_type === 'PI' || type === 'PI'
                        ? Translate('purchase invoice')
                        : item.trans_type === 'JV' || type === 'JV'
                        ? Translate('Journal Voucher')
                        : item.trans_type === 'PV' || type === 'PV'
                        ? Translate('Payment Voucher')
                        : item.trans_type === 'RV' || type === 'RV'
                        ? Translate('Receipt Voucher')
                        : item.trans_type === 'IA' || type === 'IA'
                        ? Translate('Inventory Adjustment')
                        : item.trans_type === 'CR' || type === 'CR'
                        ? Translate('Customer Receipt')
                        : item.trans_type === 'BT' || type === 'BT'
                        ? Translate('Bank Transfer')
                        : Translate('purchase delivery')}
                    </h4>
                    <p className="date fs--2 m-0">{item.created_at}</p>
                    <p className="txt m-0 fs--2">{item.reference}</p>
                    <p className="txt fs--2 m-0">
                      {Translate('Created by')} :{item.created_user_full_name}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    {getAcodaxPermissionSlug(
                      item.trans_type ?? type,
                      'show'
                    ) ? (
                      <a
                        onClick={() =>
                          clickOnVoucher(item.id, item.trans_type ?? type)
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <FaEye className="text-info" />
                      </a>
                    ) : (
                      ''
                    )}
                    {renderPrint(item, item.trans_type ?? type)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Row>
      </Container>
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={transType}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
    </>
  );
}

TransactionsData.propTypes = {
  transaction: PropTypes.any,
  type: PropTypes.string
};

export default TransactionsData;
