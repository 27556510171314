import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PhoneNumberInput from 'components/form/PhoneNumberInput';

function ContactNumberInputField({
  formData,
  formError,
  handleFieldChange,
  handleInputBoxOutofFocus,
  handleInputBoxFocus,
  enableDraft,
  required,
  readOnly,
  SI
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group
      as={Col}
      xxl={SI ? 6 : 4}
      xl={6}
      md={6}
      sm={12}
      className={SI ? 'mb-1' : 'mb-2'}
    >
      <Row>
        <Col xl={SI ? 12 : 4} md={SI ? 12 : 4} sm={12} className="pe-0">
          <Form.Label
            className={
              required
                ? 'require-data m-0 d-flex align-items-center justify-content-start h-100'
                : 'm-0 d-flex align-items-center justify-content-start h-100'
            }
          >
            {Translate('Contact Number')}
          </Form.Label>
        </Col>
        <Col
          xl={SI ? 12 : 8}
          md={SI ? 12 : 8}
          sm={12}
          className={SI ? '' : 'ps-0'}
        >
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              {readOnly ? (
                <Form.Control value={formData.mobile} disabled />
              ) : (
                <PhoneNumberInput
                  name="mobile"
                  onChange={handleFieldChange}
                  value={formData.mobile}
                  onBlur={() => {
                    enableDraft ? handleInputBoxOutofFocus() : '';
                  }}
                  onFocus={() => {
                    enableDraft ? handleInputBoxFocus() : '';
                  }}
                />
              )}
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              <FormErrorPopover
                id="mobile"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.mobile}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

ContactNumberInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleInputBoxFocus: PropTypes.func,
  handleInputBoxOutofFocus: PropTypes.func,
  enableDraft: PropTypes.bool,
  handleFieldChange: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  SI: PropTypes.bool
};

export default ContactNumberInputField;
