import { React, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Container, Table } from 'react-bootstrap';
import { EmployeeAllocationReportHead } from '../Common/data';
import { showToast } from 'module/Common/Toast/toast';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ReportsContainer from 'components/report-components/reports-container/ReportsContainer';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import TableHeader from '../Common/TableHeader';
import MinimalReportDataFetching from 'module/Common/Loading/MinimalReportDataFetching';
import ClickPerson from 'module/Common/ClickableData/ClickPerson';
import useReportApiCalls from '../Common/useReportApiCalls';
import ItemName from 'components/item-details/ItemName';

function EmployeeAllocationReport() {
  const [queryParams] = useSearchParams();
  const reportApiCall = useReportApiCalls();
  const filterItems = queryParams.get('filters');
  const Translate = useAxisproTranslate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [value, setValue] = useState({ value: 0 });
  const [formData, setFormData] = useState({});
  const [nextLink, setNextLink] = useState(null);
  const pageNumber = queryParams.get('page');
  const permissions = {
    show_user: getAcodaxPermissionSlug('USER', 'show')
  };
  const fetchData = async (page = 1) => {
    setLoading(true);
    const data = await reportApiCall('/fixedasset/employee-allocation-report', {
      page: page
    });
    if (data?.links?.next !== null) {
      setNextLink(data.links.next);
    } else {
      setNextLink(null);
    }
    setData(data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(pageNumber);
  }, [pageNumber, filterItems]);

  const clickOnCustomer = customer_id => {
    setValue({ value: customer_id });
    setCustomerDetailsArea(true);
  };

  const loadNextPage = async () => {
    if (nextLink && nextLink !== null) {
      await axios
        .get(nextLink)
        .then(res => {
          if (res.data.success) {
            setData([...data, ...res.data.data.data]);
            if (res?.data?.data?.links?.next !== null) {
              setNextLink(res.data.data.links.next);
            } else {
              setNextLink(null);
            }
          }
        })
        .catch(error => {
          if (error) {
            showToast('Something went wrong please contact admin', 'error');
          }
        });
    }
  };

  return (
    <>
      <ReportsContainer
        title={'Employee Allocation Report'}
        url="/fixedasset/employee-allocation-report/export"
        initialMessage={'Employee Allocation export started'}
        successMessage={'Employee Allocation export completed'}
        data={data}
        loading={loading}
        setFormData={setFormData}
        formData={formData}
        moduleName="employee_allocation_report"
      >
        <Container fluid className="p-0 reports-container-wrapper">
          <Table
            bordered={data?.length > 0 ? true : false}
            striped
            hover
            size="sm"
          >
            <TableHeader headers={EmployeeAllocationReportHead} />
            {data && data.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <tbody key={index}>
                    <tr className="report-column-custom-style">
                      <td>
                        <span className="report-column-content-custom-style">
                          {index + 1}
                        </span>
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          {item.allocation_id}
                        </span>
                      </td>
                      <td className="name-column text-primary fs--1">
                        <ItemName name={item.stock_id} id={item.item_id} />
                      </td>
                      <td className="name-column fs--2">{item.asset_name}</td>
                      <td>
                        <span className="name-column fs--1">
                          {item.issued_at_formatted}
                        </span>
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          {item.expected_return_at_formatted}
                        </span>
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          {item.returned_at_formatted}
                        </span>
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          <ClickPerson
                            name={item.receiver_name}
                            person_type="USER"
                            className="fs--1"
                            permission={permissions?.show_user}
                            clickOnFunction={() =>
                              clickOnCustomer(item.receiver_id)
                            }
                          />
                        </span>
                      </td>
                      <td>
                        <span
                          className={`report-column-content-custom-style fs--2 ${
                            item?.status === 'returned'
                              ? 'text-success'
                              : item?.payment_status === 'Partially Paid'
                              ? 'text-warning'
                              : 'text-danger'
                          }`}
                        >
                          {item.status}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
                {nextLink && nextLink !== null && nextLink !== '' && (
                  <MinimalReportDataFetching
                    row={3}
                    column={12}
                    nextLink={nextLink}
                    loadNextPage={loadNextPage}
                  />
                )}
              </>
            ) : (
              <tr className="alert-styles">
                <td colSpan={12}>
                  <Alert variant="warning">
                    {Translate('Currently there is no report generated.')}
                  </Alert>
                </td>
              </tr>
            )}
          </Table>
        </Container>
      </ReportsContainer>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={() => {
          setCustomerDetailsArea(!showCustomerDetailsArea);
          setValue({ value: 0 });
        }}
        value={value}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
    </>
  );
}

export default EmployeeAllocationReport;
