import React, { useContext } from 'react';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaChevronDown } from 'react-icons/fa';
import {
  Accordion,
  AccordionContext,
  Button,
  Table,
  useAccordionButton
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import EstimationEntryCostTitleSection from '../EstimationEntryCostTitleSection';
import EstimateEntryCostLineItem from '../EstimateEntryCostLineItem';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';

export default function EstimationLineItemsAccordionViewCostItem({
  title,
  eventKey,
  entries,
  subTotalFormatted
}) {
  const Translate = useAxisproTranslate();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const { activeEventKey } = useContext(AccordionContext);
  const handleClick = useAccordionButton(eventKey);
  const entriesLength = entries?.length;
  const costSubTotalAmount = subTotalFormatted
    ? subTotalFormatted
    : Array.isArray(entries)
    ? entries.reduce((total, item) => {
        const lineTotal = parseFloat(item?.line_total_ref?.replaceAll(',', ''));
        if (!isNaN(lineTotal)) {
          total = total + lineTotal;
        }
        return total;
      }, 0)
    : 0;

  return entriesLength > 0 ? (
    <>
      <tr>
        <th className="fw-bold" colSpan={9}>
          <div className="d-flex flex-row justify-content-between gap-2 align-items-center text-dark">
            <div>
              <span>{Translate(title)}</span>
              <p
                className="m-0"
                style={{
                  fontSize: '.65rem',
                  color: '#838282'
                }}
              >
                <b className="text-dark">{entriesLength}</b>
                {Translate(` item${entriesLength > 1 ? 's' : ''} found, and
                total cost is `)}
                <b className="text-dark">
                  {subTotalFormatted || acodaxNumberFormat(costSubTotalAmount)}
                </b>
              </p>
            </div>
            <Button
              variant="transparent"
              size="sm"
              className="outline-none shadow-none"
              onClick={handleClick}
            >
              <FaChevronDown
                style={{
                  transition: 'all .4s linear 0s',
                  transform: `rotate(${
                    activeEventKey === eventKey ? '0' : '-90'
                  }deg)`
                }}
              />
            </Button>
          </div>
        </th>
      </tr>
      <tr>
        <th colSpan={9} className="bg-white p-0 m-0 border-0">
          <Accordion.Collapse eventKey={eventKey}>
            <Table bordered striped responsive size="sm" className="mb-0">
              <thead>
                <EstimationEntryCostTitleSection isPreview />
              </thead>
              <tbody>
                {entries?.map((cost, index) => (
                  <EstimateEntryCostLineItem
                    key={index}
                    data={cost}
                    ItemIndex={index}
                    isPreview
                  />
                ))}
              </tbody>
            </Table>
          </Accordion.Collapse>
        </th>
      </tr>
    </>
  ) : null;
}

EstimationLineItemsAccordionViewCostItem.propTypes = {
  title: PropTypes.string,
  eventKey: PropTypes.string,
  entries: PropTypes.array,
  subTotalFormatted: PropTypes.string
};
