import { React, useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import qs from 'qs';
import PropTypes from 'prop-types';

import { FaHandHoldingUsd } from 'react-icons/fa';
import { FcNoIdea } from 'react-icons/fc';

import { apiCall } from 'helpers/apiCalls';
import { StoreContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useAxisProPermission from 'hooks/useAxisproPermission';
import { getPermissionSlug } from 'module/Common/Validators/permissions/Constants';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PaymentScreenPopup from 'module/Common/ViewPages/Common/PaymentScreenPopup';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import Loader from '../Common/Loader';
import { unpaidInvoice } from '../Common/LoaderData';

const UnPaidInvoices = ({ draggable }) => {
  const Translate = useAxisproTranslate();
  const axisProPermission = useAxisProPermission();
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const { pageNumber } = useParams();
  const { store } = useContext(StoreContext);

  const [array] = useState([
    {
      column: 'trans_date',
      type: 'date',
      value: store.currentDate,
      operator: '='
    }
  ]);

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: 'sales/sales-invoice',
      params: {
        page: page,
        is_unpaid: true,
        filters: array
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
    if (data.data.length >= 1) {
      data.data.length = 5;
    }

    setDatas(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (axisProPermission('show-si')) {
      fetchData(pageNumber);
    }
  }, [pageNumber]);

  return (
    <Card className={`h-100 ${draggable && 'dashboard_shake'}`}>
      {axisProPermission('report-sales-invoice') && (
        <FalconCardHeader
          title={Translate('Unpaid Invoices')}
          titleTag="h6"
          className="py-2"
          light
          endEl={
            <Link
              className="py-1 fs--1 font-sans-serif"
              to={'/reports/unpaid-sales-invoice-reports'}
            >
              {Translate('View All')}
            </Link>
          }
        />
      )}
      {!isLoading ? (
        axisProPermission('show-si') ? (
          <>
            {datas && datas.length > 0 ? (
              <Card.Body className="pb-0">
                {datas.map((data, index) => (
                  <UnPaidInvoice
                    fetchData={fetchData}
                    key={data.id}
                    data={data}
                    isLast={index === 4}
                  />
                ))}
              </Card.Body>
            ) : (
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <FcNoIdea size={30} />
                {Translate('No Data Available')}
              </Card.Body>
            )}
          </>
        ) : (
          <Card.Body className="d-flex flex-column justify-content-center align-items-center">
            <FcNoIdea size={30} />
            {Translate('Permission Denied!')}
          </Card.Body>
        )
      ) : (
        <Loader loader={unpaidInvoice} className={'ps-4'} />
      )}
    </Card>
  );
};

const UnPaidInvoice = ({ data, isLast, fetchData }) => {
  const Translate = useAxisproTranslate();
  const axisProPermission = useAxisProPermission();
  const {
    id,
    customer,
    customer_id,
    customer_name,
    reference,
    trans_date,
    border
  } = data;

  const [payment, setPayment] = useState(false);
  const [person] = useState({
    personType: 'CUSTOMER',
    personId: customer_id,
    name: customer_name,
    value: customer_id
  });

  const [voucherId, setVoucherId] = useState();
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);

  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  const clickOnVoucher = voucher_id => {
    setVoucherId(voucher_id);
    let slug = getPermissionSlug('SI');
    if (axisProPermission(slug)) {
      setShowVoucherDetailArea(true);
    }
  };

  return (
    <>
      <Flex alignItems="center" className="mb-1 hover-actions-trigger">
        <div className="file-thumbnail">
          {axisProPermission('show-customer') ? (
            <Link to={'/sales/customer/info/' + customer_id}>
              <img
                className={classNames('h-100 w-100 fit-cover rounded-2', {
                  border: border
                })}
                src={customer?.image}
                alt=""
              />
            </Link>
          ) : (
            <img
              className={classNames('h-100 w-100 fit-cover rounded-2', {
                border: border
              })}
              src={customer?.image}
              alt=""
            />
          )}
        </div>
        <div className="ms-3 flex-shrink-1 flex-grow-1 row">
          <Row className="pe-0">
            <Col sm={6}>
              <h6 className="mb-1">
                <a
                  style={{ pointer: 'cursor' }}
                  className="text-900 fw-bold"
                  onClick={() => clickOnVoucher(id)}
                >
                  {reference}
                </a>
              </h6>
              <div className="fs--1">
                <span>{customer_name}</span>
                <span className="ms-2">{trans_date}</span>
              </div>
            </Col>
            <Col sm={6} className={'d-flex justify-content-end p-0'}>
              <p className="m-2 fs--1 fw-bold text-dark">
                {data.balance_formatted}
              </p>
              {data.balance > 0 && axisProPermission('create-cr') ? (
                <Button
                  variant="success"
                  size="sm"
                  className="p-1 text-white rounded"
                  style={{ height: '2rem' }}
                  title={Translate('Receive Payment')}
                  onClick={() => setPayment(true)}
                >
                  <FaHandHoldingUsd size={20} className="text-dark" />
                </Button>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
      </Flex>
      <PaymentScreenPopup
        show={payment}
        person={person}
        invoiceData={data}
        onHide={() => setPayment(false)}
        dueAmount={data.amount - data.alloc}
        fetchData={fetchData}
      />
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={'SI'}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
      {!isLast && <hr className="bg-200" />}
    </>
  );
};

UnPaidInvoice.propTypes = {
  data: PropTypes.shape({
    customer: PropTypes.object,
    customer_name: PropTypes.string,
    customer_id: PropTypes.string,
    reference: PropTypes.string,
    trans_date: PropTypes.string,
    border: PropTypes.bool,
    id: PropTypes.string,
    balance_formatted: PropTypes.string,
    balance: PropTypes.string,
    amount: PropTypes.string,
    alloc: PropTypes.string
  }),
  isLast: PropTypes.bool,
  fetchData: PropTypes.func
};

UnPaidInvoices.propTypes = {
  draggable: PropTypes.bool
};

export default UnPaidInvoices;
