import { React } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import SearchAndSelectCustomer from '../Search/SearchAndSelectCustomer';

function CustomerInputField({
  formData,
  uiDesign,
  validations,
  handleCustomer,
  formError,
  editDisable,
  setFormData,
  id,
  enableBranchTransaction,
  disableAddButton
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group
      as={Col}
      xxl={4}
      xl={6}
      md={6}
      sm={12}
      className={formData?.customer_id ? 'mb-1' : 'mb-2'}
    >
      <Row>
        <Col xl={4} md={4} sm={12} className="pe-0">
          <Form.Label
            className={
              'require-data m-0 d-flex align-items-center justify-content-start h-100'
            }
          >
            {Translate('Customer')}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12} className="ps-0">
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              {(uiDesign && uiDesign.convertingScreen) ||
              (uiDesign && uiDesign.convertingScreenForSO) ||
              (validations && validations.fromSd) ||
              (validations && validations.fromSi) ||
              (validations && validations.fromSo) ||
              (uiDesign && uiDesign.editScreen) ||
              editDisable ||
              id ? (
                <Form.Control
                  value={
                    formData?.customer_id_ref?.label ??
                    formData?.customer_id_ref
                  }
                  disabled
                  className="mb-2"
                />
              ) : (
                <SearchAndSelectCustomer
                  value={formData.customer_id_ref}
                  name={formData.customer_id_ref}
                  handleFieldChange={handleCustomer}
                  setFormData={setFormData}
                  showCustomerDetails
                  advancedSearch
                  enableBranchTransaction={enableBranchTransaction}
                  hideAddForm={disableAddButton}
                />
              )}
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              {formError.customer_id ? (
                <FormErrorPopover
                  id="customer_id"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.customer_id}
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

CustomerInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  uiDesign: PropTypes.any,
  editDisable: PropTypes.bool,
  validations: PropTypes.any,
  handleCustomer: PropTypes.func,
  setFormData: PropTypes.func,
  id: PropTypes.any,
  enableBranchTransaction: PropTypes.bool,
  disableAddButton: PropTypes.bool
};

export default CustomerInputField;
