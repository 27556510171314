import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KitDetailsModalPreview from './KitDetails/KitDetailsModalPreview/KitDetailsModalPreview';
import { BsHandbagFill } from 'react-icons/bs';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function PurchaseTableFormLineItem({
  entry,
  index,
  onTableItemChange,
  readOnly = false,
  conversion = false,
  enableBatchSection,
  errors,
  enableTaxGroup
}) {
  let is_kit = parseInt(entry.is_kit) === 1 ? true : false;
  // let disableItemEditButton = false;
  // let deleteOverlayMessage = undefined;

  // if (entry?.quantity_issued > 0) {
  //   disableItemEditButton = true;
  //   deleteOverlayMessage =
  //     'The item has already been issued, so this item cannot be deleted';
  // }

  // if (entry?.qty_sent > 0) {
  //   disableItemEditButton = true;
  //   deleteOverlayMessage =
  //     'The item has already been sent, so this item cannot be deleted';
  // }

  // if (entry?.qty_invoiced > 0) {
  //   disableItemEditButton = true;
  //   deleteOverlayMessage =
  //     'The item has already been invoiced, so this item cannot be deleted';
  // }

  return (
    <tr>
      <td className="fs--1 text-dark p-1 text-center">{index + 1}</td>
      <td className="fs--1 text-dark p-1">
        <span>{entry.stock_id}</span>
        {errors?.[`details.${index}.stock_id`] ? (
          <FormErrorPopover
            id="entry.stock_id"
            totalErrorCount={Object.keys(errors).length}
            errorMessage={errors[`details.${index}.stock_id`]}
          />
        ) : null}
      </td>
      <td className="fs--1 text-dark p-1">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div>
            {entry.item_display_name ? (
              <span>{entry.item_display_name}</span>
            ) : null}
            {entry.item_description ? (
              <p className="small text-muted m-0">{entry.item_description}</p>
            ) : null}
          </div>
          {is_kit && !entry.kit_id ? (
            <BsHandbagFill style={{ fill: 'red' }} size={11} className="mx-2" />
          ) : null}
          {errors?.[`details.${index}.item_id`] ? (
            <FormErrorPopover
              id="entry.item_id"
              totalErrorCount={Object.keys(errors).length}
              errorMessage={errors[`details.${index}.item_id`]}
            />
          ) : null}
        </div>
      </td>

      {enableBatchSection ? (
        <td className="fs--1 text-dark p-1">
          <span>
            {entry?.batch_number_ref?.label
              ? entry.batch_number_ref.label
              : entry?.batch_number_display
              ? entry.batch_number_display
              : '-'}
          </span>
          {errors?.[`details.${index}.batch_number`] ? (
            <FormErrorPopover
              id="entry.batch_number"
              totalErrorCount={Object.keys(errors).length}
              errorMessage={errors[`details.${index}.batch_number`]}
            />
          ) : null}
        </td>
      ) : null}

      <td className="fs--1 text-dark p-1">
        <span>{entry.unit_ref ?? entry.unit_name}</span>
        {errors?.[`details.${index}.unit_name`] ? (
          <FormErrorPopover
            id="entry.unit_name"
            totalErrorCount={Object.keys(errors).length}
            errorMessage={errors[`details.${index}.unit_name`]}
          />
        ) : null}
      </td>

      {enableTaxGroup ? (
        <td className="fs--1 text-dark p-1">
          <span>{entry?.tax_group_id_ref?.label}</span>
          {errors?.[`details.${index}.tax_group_id`] ? (
            <FormErrorPopover
              id="entry.tax_group_id"
              totalErrorCount={Object.keys(errors).length}
              errorMessage={errors[`details.${index}.tax_group_id`]}
            />
          ) : null}
        </td>
      ) : null}

      <td className="fs--1 text-dark p-1">
        <span>{entry.quantity}</span>
        {errors?.[`details.${index}.quantity`] ? (
          <FormErrorPopover
            id="entry.quantity"
            totalErrorCount={Object.keys(errors).length}
            errorMessage={errors[`details.${index}.quantity`]}
          />
        ) : null}
      </td>
      <td className="fs--1 text-dark p-1">
        <span>{entry.unit_price_formatted ?? '-'}</span>
        {errors?.[`details.${index}.unit_price`] ? (
          <FormErrorPopover
            id="entry.unit_price"
            totalErrorCount={Object.keys(errors).length}
            errorMessage={errors[`details.${index}.unit_price`]}
          />
        ) : null}
      </td>
      <td className="fs--1 text-dark p-1">
        <span>{entry.unit_tax_formatted ?? '-'}</span>
        {errors?.[`details.${index}.unit_tax`] ? (
          <FormErrorPopover
            id="entry.unit_tax"
            totalErrorCount={Object.keys(errors).length}
            errorMessage={errors[`details.${index}.unit_tax`]}
          />
        ) : null}
      </td>
      <td className="fs--1 text-dark p-1">
        <span>
          {entry.discount_amount <= 0 ? (
            '-'
          ) : entry.discount_unit_ref === '%' ? (
            <>{entry.discount_percent}%</>
          ) : (
            <>{entry.discount_amount_formatted ?? '-'}</>
          )}
        </span>
        {errors?.[`details.${index}.discount_amount`] ||
        errors?.[`details.${index}.discount_percent`] ? (
          <FormErrorPopover
            id="entry.discount_amount"
            totalErrorCount={Object.keys(errors).length}
            errorMessage={
              errors[`details.${index}.discount_amount`] ??
              errors[`details.${index}.discount_percent`]
            }
          />
        ) : null}
      </td>
      <td className="fs--1 text-dark p-1 fw-bolder">
        <span>{entry.line_total_formatted ?? '-'}</span>
        {errors?.[`details.${index}.line_total`] ? (
          <FormErrorPopover
            id="entry.line_total"
            totalErrorCount={Object.keys(errors).length}
            errorMessage={errors[`details.${index}.line_total`]}
          />
        ) : null}
      </td>
      {(!readOnly || conversion) && (
        <td>
          <div className="d-flex flex-row align-items-center justify-content-center p-1">
            {is_kit && (
              <KitDetailsModalPreview
                kit={entry}
                onTableItemChange={onTableItemChange}
                readOnly={conversion ?? readOnly}
              />
            )}

            {(!is_kit || !conversion) &&
            onTableItemChange &&
            !entry.add_to_cart_ref ? (
              <>
                <Button
                  size="sm"
                  variant="transparent"
                  className="p-0 mx-1 text-info"
                  onClick={() =>
                    onTableItemChange({ ...entry, edit: true }, 'UPDATE')
                  }
                  style={{ boxShadow: 'none' }}
                >
                  <FontAwesomeIcon icon="edit" size="sm" />
                </Button>

                <Button
                  size="sm"
                  variant="transparent"
                  className="p-0 mx-1 text-danger"
                  onClick={() => onTableItemChange(entry, 'REMOVE', true)}
                  style={{ boxShadow: 'none' }}
                >
                  <FontAwesomeIcon icon="trash" size="sm" />
                </Button>
              </>
            ) : null}
          </div>
        </td>
      )}
    </tr>
  );
}

PurchaseTableFormLineItem.propTypes = {
  index: PropTypes.number,
  entry: PropTypes.object,
  setFormData: PropTypes.func,
  onTableItemChange: PropTypes.func,
  readOnly: PropTypes.bool,
  conversion: PropTypes.bool,
  enableBatchSection: PropTypes.bool,
  errors: PropTypes.object,
  enableTaxGroup: PropTypes.bool
};

export default PurchaseTableFormLineItem;
