import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';
import axios from 'axios';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaCalendarAlt, FaCopy, FaCube, FaEye, FaUser } from 'react-icons/fa';
import WorkOrderMaterialRequestLineItemDetailsModal from './modals/WorkOrderMaterialRequestsModal/WorkOrderMaterialRequestLineItemDetailsModal';
import WorkOrderStatusBadge from './components/WorkOrderStatusBadge';
import useAxisproPermission from 'hooks/useAxisproPermission';
import WorkOrderIssueItemsButton from './components/WorkOrderIssueItemsButton';
import WorkOrderTabDataInfiniteScrollHandler from './components/WorkOrderTabDataInfiniteScrollHandler';
import WorkOrderTabViewFilterAndActionSection from './components/WorkOrderTabViewFilterAndActionSection';
import { FaNoteSticky } from 'react-icons/fa6';
import EllipsisOverlayText from 'components/ellipsis-overlay-text/EllipsisOverlayText';

export default function WorkOrderMaterialRequestesTabView({
  activeTabKey,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  workOrderId,
  WorkOrderDetailsArray
}) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [activeItemData, setActiveItemData] = useState(undefined);
  const [activeWorkOrderDetailId, setActiveWorkOrderDetailId] =
    useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const itemIssuePermission = useAxisproPermission('issue-items');

  function fetchData(page, workOrderDetailId) {
    setIsLoading(true);
    axios
      .get('purchase/material-request', {
        params: {
          work_order_id: workOrderId,
          page: page ?? 1,
          ...(workOrderDetailId
            ? {
                work_order_detail_id: workOrderDetailId
              }
            : null)
        }
      })
      .then(response => {
        setData(prev =>
          page > 1 && prev?.data && Array.isArray(response?.data?.data?.data)
            ? {
                ...(response?.data?.data ?? null),
                data: [...prev.data, ...(response?.data?.data?.data ?? null)]
              }
            : response?.data?.data
        );
      })
      .catch(error => {
        setErrorMessage(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.'
        );
      })
      .finally(() => {
        setIsLoading(false);
        setTabApiCallCompleted(prev => ({
          ...prev,
          materialRequestes: true
        }));
      });
  }

  function handleSuccessCallBack(response) {
    if (
      !response?.targetId ||
      !response?.data?.data?.id ||
      !Array.isArray(response?.issuedItems) ||
      response.issuedItems.length < 1
    )
      return;

    const issuedQuantityCount = response.issuedItems.reduce((count, item) => {
      const itemQuantity = parseFloat(item?.quantity);
      if (!isNaN(itemQuantity) && itemQuantity > 0) {
        count += itemQuantity;
      }
      return count;
    }, 0);

    setData(prev => ({
      ...prev,
      data: Array.isArray(prev?.data)
        ? prev.data.reduce((newResult, item) => {
            if (item?.id === response.targetId) {
              const totalQuantityIssued = parseFloat(item?.total_qty_issued);
              item.total_qty_issued =
                (isNaN(totalQuantityIssued) ? 0 : totalQuantityIssued) +
                issuedQuantityCount;
            }
            newResult.push(item);
            return newResult;
          }, [])
        : []
    }));
  }

  useEffect(() => {
    if (activeTabKey !== 'material_requests' || tabApiCallCompleted) return;
    fetchData(1, activeWorkOrderDetailId);
  }, [activeTabKey, tabApiCallCompleted, activeWorkOrderDetailId]);

  return (
    <>
      <div
        className="pt-4 d-flex flex-column flex-fill align-items-between justify-content-between"
        id="wo-mt-rq-scroll-cover-div-target"
      >
        {errorMessage ? (
          <Alert variant="danger">{Translate(errorMessage)}</Alert>
        ) : isLoading && isNaN(data?.meta?.to) ? (
          <Alert className="d-flex flex-row align-items-center gap-2">
            <span>{Translate('Looking for material requestes')}</span>
            <Spinner
              style={{
                width: 15,
                height: 15
              }}
            />
          </Alert>
        ) : (
          <>
            <WorkOrderTabViewFilterAndActionSection
              keyName="materialRequest"
              TabkeyName="materialRequestes"
              workOrderId={workOrderId}
              WorkOrderDetailsArray={WorkOrderDetailsArray}
              fetchData={fetchData}
              setTabApiCallCompleted={setTabApiCallCompleted}
              setActiveWorkOrderDetailId={setActiveWorkOrderDetailId}
            />

            <Table
              responsive
              striped
              bordered
              hover
              className="simple-table-animation"
            >
              <thead>
                <tr className="bg-dark text-light fs--2 text-uppercase">
                  <td>{Translate('Date')}</td>
                  <td>{Translate('Reference')}</td>
                  <td>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          {Translate(
                            ' Work order line item stock id and item name'
                          )}
                        </Tooltip>
                      }
                    >
                      <span>{Translate('WO - Item Name')}</span>
                    </OverlayTrigger>
                  </td>
                  <td>{Translate('Requested by')}</td>
                  <td>{Translate('Note')}</td>
                  <td className="text-center">{Translate('Status')}</td>
                  <td className="text-center">{Translate('Actions')}</td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data?.data) && data.data.length > 0 ? (
                  data.data.map((item, index) => (
                    <tr key={index} className="fs--1">
                      <td role="button" onClick={() => setActiveItemData(item)}>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <FaCalendarAlt size={12} className="text-muted" />
                          <span>{item?.trans_date_formatted}</span>
                        </div>
                      </td>
                      <td role="button" onClick={() => setActiveItemData(item)}>
                        <FaCopy size={12} className="text-muted" />
                        <span>{item?.reference}</span>
                      </td>
                      <td role="button" onClick={() => setActiveItemData(item)}>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <FaCube size={12} className="text-muted" />
                          <span>
                            {item?.work_order_detail?.stock_id +
                              ' - ' +
                              item?.work_order_detail?.item_display_name}
                          </span>
                        </div>
                      </td>
                      <td role="button" onClick={() => setActiveItemData(item)}>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <FaUser size={12} className="text-muted" />
                          <span>{item?.created_user_full_name}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <FaNoteSticky size={12} className="text-muted" />
                          <EllipsisOverlayText text={item?.memo} limit={25} />
                        </div>
                      </td>
                      <td
                        className="text-center"
                        role="button"
                        onClick={() => setActiveItemData(item)}
                      >
                        <WorkOrderStatusBadge
                          quantity={item?.total_qty_requested}
                          quantityIssued={item?.total_qty_issued}
                        />
                      </td>

                      <td>
                        <div className="d-flex gap-2 justify-content-center">
                          {itemIssuePermission ? (
                            <WorkOrderIssueItemsButton
                              targetId={item?.id}
                              disabled={
                                parseFloat(item?.total_qty_requested) ===
                                parseFloat(item?.total_qty_issued)
                              }
                              successCallBack={handleSuccessCallBack}
                            />
                          ) : null}

                          <Button
                            size="sm"
                            className="rounded px-2"
                            variant="info"
                            onClick={() => setActiveItemData(item)}
                          >
                            <div className="d-flex flex-row align-items-center gap-1">
                              <FaEye size={10} />
                              <span className="small">
                                {Translate('Show Details')}
                              </span>
                            </div>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-0" colSpan={7}>
                      <Alert className="m-0 border-0 text-center rounded-0">
                        {Translate('No Results found!')}
                      </Alert>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <WorkOrderTabDataInfiniteScrollHandler
              isLoading={isLoading}
              current_page={data?.meta?.current_page}
              last_page={data.meta.last_page}
              nextLink={data?.meta?.current_page + 1}
              loadNextPage={() => fetchData(data?.meta?.current_page + 1)}
            />
          </>
        )}
      </div>

      {activeItemData?.id ? (
        <WorkOrderMaterialRequestLineItemDetailsModal
          targetId={activeItemData.id}
          onHide={() => setActiveItemData(undefined)}
        />
      ) : null}
    </>
  );
}

WorkOrderMaterialRequestesTabView.propTypes = {
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func,
  workOrderId: PropTypes.string,
  WorkOrderDetailsArray: PropTypes.array
};
