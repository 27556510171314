import { React, useContext, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import SettingsDropdown from './SettingsDropdown';
import DownloadCenter from 'components/download-center/DownloadCenter';
import QuickForms from './QuickForms';
import ChatNotification from './ChatNotification';
import LanguageDropdown from './LanguageDropdown';
import Launchpad from 'components/Launchpad/Launchpad';
import BackButton from './BackButton';
import ToggleSwitch from './ToggleSwitch';
import AIChatBot from 'components/ai-chatBot/AIChatBot';
import { GrAppsRounded } from 'react-icons/gr';
import AppContext, { StoreContext } from 'context/Context';
import { useLocation } from 'react-router';
import ChangeBranch from './ChangeBranch';
import ActiveTaskDetailsDropdown from 'components/active-task-details-dropdown/ActiveTaskDetailsDropdown';
const TopNavRightSideNavItem = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  const { store, addIitemToStore } = useContext(StoreContext);
  const NewIsDark = localStorage.getItem('isDarkTheme');
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/dashboard') {
      if (isDark) {
        setConfig('isDark', false);
      }
      addIitemToStore('dashboard_draggable', false);
    } else {
      if (NewIsDark === 'true') {
        setConfig('isDark', true);
      } else {
        setConfig('isDark', false);
      }
    }
  }, [location]);
  const DashboardSetup = () => {
    addIitemToStore('dashboard_draggable', !store?.dashboard_draggable);
  };

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {store?.globalConfiguration?.show_multi_branch_for_admins && (
        <ChangeBranch />
      )}
      {location.pathname === '/dashboard' && <ToggleSwitch />}
      <BackButton />
      <LanguageDropdown />
      <QuickForms />
      <ChatNotification />
      <AIChatBot />
      <ActiveTaskDetailsDropdown />
      <NotificationDropdown />
      <SettingsDropdown />
      <DownloadCenter />
      {location.pathname === '/dashboard' && (
        <GrAppsRounded
          onClick={DashboardSetup}
          size={18}
          color="secondary"
          className="mt-1 ms-1 me-1 cursor-pointer "
          title="Customize Your Dashboard "
        />
      )}
      <Launchpad />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
