import React, { useState } from 'react';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';
import setNewFormData from 'helpers/setNewFormData';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import useRemoveParams from 'module/Common/Hooks/useRemoveParams';
const RejectButton = ({ name, Id, getData, info }) => {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [onSave, setOnSave] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const handleSubmit = () => {
    setOnSave(true);
    const newData = {
      approval_stage_event_id: Id
    };
    let newFormData = setNewFormData(newData);
    axios({
      method: 'post',
      url: `settings/reject-transaction`,
      data: newFormData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.data.success === true) {
          getData();
          if (info)
            navigate(
              `?${createSearchParams(
                useRemoveParams(
                  {
                    ...allQueryParams
                  },
                  ['entry']
                )
              )}`
            );
          handleHide();
          showToast(response.data.message, 'success');
          setOnSave(false);
        }
      })
      .catch(error => {
        console.log(error);
        handleHide();
        showToast('Something went wrong', 'error');
        setOnSave(false);
      });
  };
  return (
    <div>
      <ConfirmWarning
        content="Are you sure you want to reject this request ?"
        onHide={handleHide}
        show={show}
        handleSubmit={handleSubmit}
        onSave={onSave}
      />
      <Button
        className={`${
          info
            ? 'reject-request-info-button rounded'
            : 'reject-request-table-button rounded-pill'
        }  text-capitalize `}
        onClick={handleShow}
      >
        <RxCross2 className="p-0 m-0 " size={15} /> {Translate(name)}
      </Button>
    </div>
  );
};

RejectButton.propTypes = {
  name: PropTypes.string,
  Id: PropTypes.string,
  getData: PropTypes.object,
  info: PropTypes.bool
};
export default RejectButton;
