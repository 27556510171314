import { fixedAssetPurchaseInvoiceFormKeys } from 'helpers/formKeys';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { AiFillFileAdd } from 'react-icons/ai';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import axios from 'axios';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import removeRefData from 'helpers/removeRefData';
import setNewFormData from 'helpers/setNewFormData';
import { useDraft } from 'module/Purchase/common/hooks/purchaseHooks';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AuthWizardContext } from 'context/Context';
import useAxisproPermission from 'hooks/useAxisproPermission';
import setCloneItemDetails from 'module/Purchase/common/functions/setCloneItemDetails';
import handleSetPurchaseFormDetailsData from 'module/Purchase/common/helpers/handleSetPurchaseFormDetailsData';
import setPurchaseDetailsItemsTaxGroupData from 'module/Purchase/common/helpers/setPurchaseDetailsItemsTaxGroupData';
import setCustomFields from 'helpers/setCustomFields';
import handlePurchaseModuleFormDynamicValues from 'module/Purchase/common/helpers/handlePurchaseModuleFormDynamicValues';
import FixedAssetPurchaseInvoiceForm from '../Form/FixedAssetPurchaseInvoiceForm';
import AfterSaveModal from 'components/after-save/AfterSaveModal';
import moment from 'moment';
import setTermsAndConditionsResult from 'module/Purchase/common/functions/handleAddTermsAndConditions';
import DraftData from 'module/Common/DraftSupportingFiles/DraftData';

const AddFixedAssetPurchaseInvoice = () => {
  const { user } = useContext(AuthWizardContext);
  const userBranchData = user?.branch;
  const enableBatchSection = !!+user?.branch?.inventory_settings?.enable_batch;
  const enableTaxGroup =
    userBranchData?.purchase_settings?.enable_line_item_tax_group === '1'
      ? true
      : false;

  if (userBranchData?.inventory_settings?.default_warehouse_id) {
    fixedAssetPurchaseInvoiceFormKeys.warehouse_id =
      userBranchData.inventory_settings.default_warehouse_id;
    fixedAssetPurchaseInvoiceFormKeys.warehouse_id_ref = {
      label: userBranchData.inventory_settings?.default_warehouse_name,
      value: userBranchData.inventory_settings.default_warehouse_id
    };
  }

  if (userBranchData?.purchase_settings?.default_terms_and_conditions_id) {
    fixedAssetPurchaseInvoiceFormKeys.terms_and_conditions =
      userBranchData.purchase_settings
        ?.default_terms_and_conditions_description || '';
  }

  const navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const axisProPermission = useAxisproPermission();
  const [queryParams] = useSearchParams();
  const [formError, setFormError] = useState({});
  const [customFieldItems, setCustomFieldItems] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [onSave, setOnSave] = useState(false);
  const [entryTableShowLoading, setEntryTableShowLoading] = useState(false);
  const [afterSaveModal, setAfterSaveModal] = useState({
    show: false,
    data: undefined
  });
  const [isFetchingInfo, setIsFetchingInfo] = useState(false);

  const cloneItemId = queryParams.get('clone_item');

  const {
    formData,
    setFormData,
    netAmounts,
    draftId,
    draftData,
    showDraftWindow,
    setShowDraftWindow,
    saveDraftData,
    disableSaveDraftData,
    getDraftData,
    handleResumeDraftData
  } = useDraft({
    trans_type: 'FIXED-ASSET-PI',
    defaultFormData: fixedAssetPurchaseInvoiceFormKeys,
    withoutItemsArray: ['trans_type', 'draft_id']
  });

  const handleFieldChange = async (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    let keyName = !action ? e.target.name : action?.name;

    if (keyName === 'terms_and_conditions_option_ref') disableSaveDraftData();

    await handlePurchaseModuleFormDynamicValues(
      e,
      action,
      newFormData,
      user,
      [
        'applyTransdateAndTaxIncludedChanges',
        'setSuppliersDefaultPaymentTermAsDefaultPaymentTermValue',
        'setDueDateRelatedToPaymentTermDays'
      ],
      'Fixed-Asset-PI'
    );

    if (
      keyName === 'supplier_id' &&
      !newFormData?.payment_term_id &&
      user?.payment_term_id
    ) {
      newFormData.payment_term_id = user.payment_term_id;
      newFormData.payment_term_id_ref = {
        days: user?.payment_term_days,
        label: user?.payment_term_name,
        value: user.payment_term_id
      };
    }

    setFormData(newFormData);

    if (enableTaxGroup && keyName === 'supplier_id') {
      setEntryTableShowLoading(true);
      setPurchaseDetailsItemsTaxGroupData({
        newFormData: newFormData,
        formData: formData,
        setFormData: setFormData,
        setEntryTableShowLoading: setEntryTableShowLoading,
        Translate: Translate
      });
    }
  };

  const handleAddTermsAndConditions = async event => {
    event.preventDefault();
    saveDraftData();
    setTermsAndConditionsResult({
      formData: formData,
      Translate: Translate,
      setIsFetchingInfo: setIsFetchingInfo,
      setFormData: setFormData
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setFormError({});
    setOnSave(true);

    try {
      const detailsData = await handleSetPurchaseFormDetailsData({
        detailsArray: formData?.details
      });

      let formDataWithoutRefkeys = {
        ...removeRefData(formData, 'object', {
          removeValue: [null, 'null']
        }),
        details: detailsData?.validItems,
        draft_id: draftId
      };

      let newFormData = setNewFormData(formDataWithoutRefkeys);

      const postResponse = await axios.postForm(
        'purchase/fixed-asset-purchase-invoices',
        newFormData
      );

      const purchaseInvoiceDetails = postResponse?.data?.data;

      if (postResponse?.data?.success !== true || !purchaseInvoiceDetails?.id) {
        showToast(
          'Something went wrong, please refresh the page and try again.',
          'error'
        );
        return;
      }

      showToast(postResponse?.data?.message, 'success');
      setAfterSaveModal({
        show: true,
        data: purchaseInvoiceDetails
      });
    } catch (error) {
      showToast(
        error?.response?.data?.message ??
          error?.message ??
          'Something went wrong, please refresh the page and try again.',
        'error'
      );
      setFormError({ ...(error?.response?.data?.data?.errors ?? null) });
    } finally {
      setOnSave(false);
    }
  };

  const handleAfterSaveModalOnHide = event => {
    event?.preventDefault();
    setLoading(true);
    const currentDate = moment().format('YYYY-MM-DD');
    fixedAssetPurchaseInvoiceFormKeys.trans_date = currentDate;
    customFieldItems?.forEach(element => {
      if (element?.column_type === 'date' && element?.is_required) {
        fixedAssetPurchaseInvoiceFormKeys[`custom_fields[${element.id}]`] =
          currentDate;
      }
    });
    setFormData(fixedAssetPurchaseInvoiceFormKeys);

    setAfterSaveModal({
      show: false,
      data: undefined
    });

    if (cloneItemId) {
      navigate('/purchase/purchase-invoice/add', {
        replace: true
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  useLayoutEffect(() => {
    const featchDetails = async () => {
      setLoading(true);
      try {
        //set custom fileds
        await setCustomFields({
          formSlug: 'rental-pi-form',
          setCustomFieldItems: setCustomFieldItems
        });

        // set clone data
        if (cloneItemId) {
          setLoadingText(Translate('Looking for cloning item details'));
          await setCloneItemDetails({
            type: 'PI',
            targetId: cloneItemId,
            setFormData: setFormData
          });
        }

        // get draft data
        setLoadingText(Translate('Looking for draft details'));
        await getDraftData();
        setLoadingText(null);
        setLoading(false);
      } catch (error) {
        console.error(error);
        showToast('Something went wrong, please refresh and try again');
      }
    };
    featchDetails();
  }, [cloneItemId]);

  return !axisProPermission('create-pi') ? (
    <Navigate to="/errors/403" />
  ) : (
    <>
      <Card style={{ height: '92vh' }}>
        <Card.Header className="d-flex flex-row justify-content-between align-items-center border-bottom">
          <h5
            className="fs-1 mb-0 d-flex align-items-center text-uppercase"
            style={{ fontSize: '18px' }}
          >
            <AiFillFileAdd size={25} className="text-success me-1" />
            {Translate('Add Fixed Asset Purchase')}
          </h5>
        </Card.Header>

        {loading ? (
          <LoadingScreen message={loadingText} />
        ) : (
          <FixedAssetPurchaseInvoiceForm
            handleSubmit={handleSubmit}
            formError={formError}
            setFormError={setFormError}
            formData={formData}
            setFormData={setFormData}
            handleFieldChange={handleFieldChange}
            handleAddTermsAndConditions={handleAddTermsAndConditions}
            saveDraftData={saveDraftData}
            customFieldItems={customFieldItems}
            netAmounts={netAmounts}
            enableBatchSection={enableBatchSection}
            enableTaxGroup={enableTaxGroup}
            entryTableShowLoading={entryTableShowLoading}
            onSave={onSave}
            isFetchingInfo={isFetchingInfo}
          />
        )}
      </Card>

      <DraftData
        show={showDraftWindow}
        onHide={() => setShowDraftWindow(false)}
        savedDraftData={draftData}
        setResumeDraftData={handleResumeDraftData}
        setDraftId={() => console.log('')}
        setDeleteDraft={() => console.log('')}
      />

      {!loading && afterSaveModal?.show ? (
        <AfterSaveModal
          module="PURCHASE"
          show
          onHide={handleAfterSaveModalOnHide}
          afterSave={{
            mainData: 'Fixed Asset Purchase Invoice Created Successfully!',
            printButton: {
              url: 'purchase/purchase-invoices/print',
              pageTitle: Translate('Fixed Asset Purchase invoice print view')
            },
            newButton: {
              title: Translate('Create New Fixed Asset Purchase Invoice'),
              onClick: handleAfterSaveModalOnHide
            },
            viewButton: {
              title: Translate('View Fixed Asset Purchase Invoice'),
              url: '/fixed-assets/purchase-invoice'
            },
            listPageUrl: '/fixed-assets/purchase-invoice'
          }}
          data={afterSaveModal?.data}
        />
      ) : null}
    </>
  );
};

export default AddFixedAssetPurchaseInvoice;
