import axios from 'axios';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { AuthWizardContext } from 'context/Context';
import { apiCall } from 'helpers/apiCalls';
import { fixedAssetPurchaseInvoiceFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import removeRefData from 'helpers/removeRefData';
import setNewFormData from 'helpers/setNewFormData';
import useAxisproPermission from 'hooks/useAxisproPermission';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import DraftData from 'module/Common/DraftSupportingFiles/DraftData';
import { showToast } from 'module/Common/Toast/toast';
import handleSetPurchaseFormDetailsData from 'module/Purchase/common/helpers/handleSetPurchaseFormDetailsData';
import { useDraft } from 'module/Purchase/common/hooks/purchaseHooks';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FaRegEdit } from 'react-icons/fa';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import setPurchaseDetailsItemsTaxGroupData from 'module/Purchase/common/helpers/setPurchaseDetailsItemsTaxGroupData';
import setCustomFields, {
  setCustomFieldsForApiResult
} from 'helpers/setCustomFields';
import handlePurchaseModuleFormDynamicValues from 'module/Purchase/common/helpers/handlePurchaseModuleFormDynamicValues';
import FixedAssetPurchaseInvoiceForm from '../Form/FixedAssetPurchaseInvoiceForm';
import setTermsAndConditionsResult from 'module/Purchase/common/functions/handleAddTermsAndConditions';

export default function EditFixedAssetPurchaseInvoice() {
  const { user } = useContext(AuthWizardContext);
  const userBranchData = user?.branch;
  const enableBatchSection = !!+user?.branch?.inventory_settings?.enable_batch;
  const enableTaxGroup =
    userBranchData?.purchase_settings?.enable_line_item_tax_group === '1'
      ? true
      : false;
  const [isFetchingInfo, setIsFetchingInfo] = useState(false);

  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const axisProPermission = useAxisproPermission();
  const { itemId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [formError, setFormError] = useState({});
  const [entryTableShowLoading, setEntryTableShowLoading] = useState(false);
  const [customFieldItems, setCustomFieldItems] = useState(undefined);

  const {
    formData,
    setFormData,
    netAmounts,
    draftId,
    draftData,
    showDraftWindow,
    setShowDraftWindow,
    saveDraftData,
    disableSaveDraftData,
    getDraftData,
    handleResumeDraftData
  } = useDraft({
    trans_type: 'FIXED-ASSET-PI',
    defaultFormData: fixedAssetPurchaseInvoiceFormKeys,
    withoutItemsArray: ['trans_type', 'draft_id'],
    conversionData: {
      trans_no: itemId ?? null
    }
  });

  const handleFieldChange = async (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    let keyName = !action ? e.target.name : action?.name;
    if (keyName === 'terms_and_conditions_option_ref') disableSaveDraftData();

    await handlePurchaseModuleFormDynamicValues(e, action, newFormData, user, [
      'applyTransdateAndTaxIncludedChanges',
      'setSuppliersDefaultPaymentTermAsDefaultPaymentTermValue',
      'setDueDateRelatedToPaymentTermDays'
    ]);

    setFormData(newFormData);

    if (enableTaxGroup && keyName === 'supplier_id') {
      setEntryTableShowLoading(true);
      setPurchaseDetailsItemsTaxGroupData({
        newFormData: newFormData,
        formData: formData,
        setFormData: setFormData,
        setEntryTableShowLoading: setEntryTableShowLoading,
        Translate: Translate
      });
    }
  };

  const handleAddTermsAndConditions = async event => {
    event.preventDefault();
    saveDraftData();
    setTermsAndConditionsResult({
      formData: formData,
      Translate: Translate,
      setIsFetchingInfo: setIsFetchingInfo,
      setFormData: setFormData
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    try {
      const detailsData = await handleSetPurchaseFormDetailsData({
        detailsArray: formData?.details
      });

      let formDataWithoutRefkeys = {
        ...removeRefData(formData, 'object', {
          removeValue: [null, 'null']
        }),
        details: detailsData?.validItems,
        _method: 'PUT',
        draft_id: draftId
      };

      let newFormData = setNewFormData(formDataWithoutRefkeys);

      const postResponse = await axios.postForm(
        'purchase/fixed-asset-purchase-invoices/' + itemId,
        newFormData
      );

      if (
        postResponse?.data?.success !== true ||
        !postResponse?.data?.data?.id
      ) {
        showToast(
          'Something went wrong, please refresh the page and try again.',
          'error'
        );
        return;
      }

      showToast(postResponse?.data?.message, 'success');
      navigate(
        `/fixed-assets/purchase-invoice?entry=${postResponse?.data?.data?.id}&layout=column`,
        {
          replace: true
        }
      );
    } catch (error) {
      showToast(
        error?.response?.data?.message ??
          error?.message ??
          'Something went wrong, please refresh the page and try again.',
        'error'
      );
      setFormError({ ...(error?.response?.data?.data?.errors ?? null) });
    } finally {
      setOnSave(false);
    }
  };

  useLayoutEffect(() => {
    const featchDetails = async () => {
      setIsLoading(true);

      try {
        //set custom fileds
        await setCustomFields({
          formSlug: 'rental-pi-form',
          setCustomFieldItems: setCustomFieldItems
        });

        // set conversion data
        if (itemId) {
          setLoadingText('Looking for item details');

          const itemDetails = await apiCall({
            url: `purchase/purchase-invoice/${itemId}`
          });
          if (itemDetails?.id) {
            let detailsIndex = 0;
            setFormData(defaultFormData => ({
              ...defaultFormData,
              terms_and_conditions: itemDetails?.terms_and_conditions ?? '',
              memo: itemDetails?.memo ?? '',
              trans_date: itemDetails?.trans_date ?? '',
              due_date: itemDetails?.due_date ?? '',
              supplier_reference: itemDetails?.supplier_reference ?? '',
              phone: itemDetails?.phone ?? '',
              email: itemDetails?.email ?? '',
              supplier_id: itemDetails?.supplier_id ?? '',
              supplier_id_ref: itemDetails?.supplier_id
                ? {
                    value: itemDetails.supplier_id,
                    label: itemDetails?.supplier_name
                  }
                : null,
              ...(enableTaxGroup && itemDetails?.supplier
                ? { supplierDetails_ref: itemDetails?.supplier }
                : null),
              payment_term_id: itemDetails?.payment_term_id ?? '',
              payment_term_id_ref: itemDetails?.payment_term_id
                ? {
                    value: itemDetails.payment_term_id,
                    label: itemDetails?.payment_term
                  }
                : null,
              cost_center_id: itemDetails?.cost_center_id ?? '',
              cost_center_id_ref: itemDetails?.cost_center_id
                ? {
                    value: itemDetails.cost_center_id,
                    label: itemDetails?.cost_center_name
                  }
                : null,
              warehouse_id: itemDetails?.warehouse_id ?? '',
              warehouse_id_ref: itemDetails?.warehouse_id
                ? {
                    value: itemDetails?.warehouse_id,
                    label: itemDetails?.warehouse_name
                  }
                : null,
              tax_included: itemDetails?.tax_included ?? 0,
              details: Array.isArray(itemDetails?.details)
                ? itemDetails.details.reduce((newResult, item) => {
                    detailsIndex += 1;

                    const itemNeededData = {
                      id_ref: detailsIndex,
                      stock_id: item?.stock_id || '',
                      item_display_name: item?.item_display_name,
                      item_display_name_ref: {
                        label: item?.item_basic_details?.item_name,
                        value: item?.item_basic_details?.item_name,
                        item: item?.item_basic_details,
                        type: item?.item_basic_details?.type,
                        stock_id: item?.item_basic_details?.stock_id
                      },
                      item_description: item?.item_description || '',
                      unit_price: item?.unit_price || '',
                      discount_amount: item?.discount_amount || 0,
                      discount_percent: item?.discount_percent || 0,
                      discount_unit_ref: '$',
                      unit_ref: item?.unit_name,
                      quantity: item?.quantity,
                      trans_date: itemDetails?.trans_date,
                      add_to_cart_ref: true,
                      add_to_draft_ref: false
                    };

                    if (item?.batch_number) {
                      itemNeededData.batch_number_ref = {
                        label: item?.batch_number_display,
                        value: item.batch_number
                      };
                    }

                    if (item?.tax_group_id) {
                      itemNeededData.tax_group_id = item?.tax_group_id || '';
                      itemNeededData.tax_group_id_ref = {
                        label: item?.tax_group_name,
                        value: item?.tax_group_id
                      };
                    }

                    newResult.push(itemNeededData);
                    return newResult;
                  }, [])
                : defaultFormData.details,
              ...setCustomFieldsForApiResult(itemDetails?.custom_field_values)
            }));
          }
        }

        // get draft data
        setLoadingText(Translate('Looking for draft details'));
        await getDraftData();
        setLoadingText(null);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        let errorMessage =
          'Something went wrong, please refresh the page and try again.';
        if (error?.response?.data?.message) {
          errorMessage = error.response.data.message;
        }

        showToast(Translate(errorMessage), 'error');
      }
    };
    featchDetails();
  }, [itemId]);

  return !axisProPermission('update-pi') ? (
    <Navigate to="/errors/403" />
  ) : (
    <>
      <Card style={{ height: '92vh' }}>
        <Card.Header className="border-bottom">
          <h5
            className="fs-1 mb-0 d-flex align-items-center text-uppercase"
            style={{ fontSize: '18px' }}
          >
            <FaRegEdit size={25} className="text-success me-1" />
            {Translate('Edit Fixed Asset Purchase Invoice')}
          </h5>
        </Card.Header>
        {isLoading ? (
          <LoadingScreen message={loadingText} />
        ) : (
          <FixedAssetPurchaseInvoiceForm
            editScreen
            itemId={itemId}
            handleSubmit={handleSubmit}
            formError={formError}
            setFormError={setFormError}
            formData={formData}
            setFormData={setFormData}
            handleFieldChange={handleFieldChange}
            handleAddTermsAndConditions={handleAddTermsAndConditions}
            saveDraftData={saveDraftData}
            customFieldItems={customFieldItems}
            netAmounts={netAmounts}
            enableBatchSection={enableBatchSection}
            enableTaxGroup={enableTaxGroup}
            entryTableShowLoading={entryTableShowLoading}
            onSave={onSave}
            isFetchingInfo={isFetchingInfo}
          />
        )}
      </Card>

      <DraftData
        show={showDraftWindow}
        onHide={() => setShowDraftWindow(false)}
        savedDraftData={draftData}
        setResumeDraftData={handleResumeDraftData}
        setDraftId={() => console.log('')}
        setDeleteDraft={() => console.log('')}
      />
    </>
  );
}
