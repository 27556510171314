import './Launchpad.scss';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useRef } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import LaunchpadSearchArea from './components/LaunchpadSearchArea';
import LaunchpadModulesSection from './components/LaunchpadModulesSection';
import { useEffect } from 'react';

import { useContext } from 'react';
import { AuthWizardContext } from 'context/Context';
import LaunchpadQuickLinksSection from './components/LaunchpadQuickLinksSection';
import LaunchpadSearchResultArea from './components/LaunchpadSearchResultArea';
import axios from 'axios';
import LaunchPadFooter from './components/LaunchPadFooter';

export default function Launchpad() {
  const launchpadCoverElement = useRef(null);
  const launchpadElement = useRef(null);
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const [searchKey, setSearchKey] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchApiCallTimer, setSearchApiCallTimer] = useState(null);

  async function toggleLaunchPad() {
    if (!launchpadCoverElement.current || !launchpadElement.current) return;
    function showLaunchpadCover(time) {
      return new Promise(resolve =>
        setTimeout(() => {
          launchpadCoverElement.current.classList.toggle('show-launchpad');
          return resolve();
        }, time)
      );
    }

    function showLaunchpad(time) {
      return new Promise(resolve =>
        setTimeout(() => {
          launchpadElement.current.classList.toggle('app-launchpad-scale');
          return resolve();
        }, time)
      );
    }

    if (!launchpadCoverElement.current.classList.contains('show-launchpad')) {
      await showLaunchpadCover(0);
      showLaunchpad(70);
    } else {
      await showLaunchpad(0);
      showLaunchpadCover(300);
    }
  }

  function handleShow(event) {
    event.preventDefault();
    toggleLaunchPad();
  }

  function handleSearchInput(event) {
    const _searchKey = event.target.value;
    setSearchKey(_searchKey);
    if (_searchKey.length > 2) {
      setIsSearching(true);

      clearTimeout(searchApiCallTimer);

      const apiRequest = setTimeout(() => {
        axios
          .post('/menu-search', {
            search: _searchKey,
            mode: 'LIKE'
          })
          .then(response => {
            if (response?.data?.data) {
              setSearchResult(response.data.data);
            }
            setIsSearching(false);
          })
          .catch(error => {
            setIsSearching(false);
            console.log(error);
          });
      }, 1200);

      setSearchApiCallTimer(apiRequest);
    }
  }

  function keyPress(event) {
    if (event.key === 'Escape') {
      toggleLaunchPad();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => {
      document.removeEventListener('keydown', keyPress);
    };
  }, []);

  return (
    <>
      <Button
        variant="transparent"
        onClick={handleShow}
        className={`px-1 py-0 nav-link download-center-button launch-pad-button-driver-element`}
        style={{ boxShadow: 'none' }}
      >
        <BsFillGrid3X3GapFill title={Translate('Launchpad')} />
      </Button>
      <div
        className="app-launchpad-cover flex-column align-items-center justify-content-center"
        ref={launchpadCoverElement}
      >
        <div
          className="app-launchpad shadow d-flex flex-column"
          ref={launchpadElement}
        >
          <LaunchpadSearchArea
            inputProps={{
              value: searchKey,
              onChange: handleSearchInput
            }}
          />

          <div className="d-flex flex-nowrap flex-row  app-launchpad-content-area">
            <div
              className={`app-launchpad-section-padding moving-animation-rea ${
                searchKey.length > 0 ? 'move-item-left' : 'move-item-center'
              }`}
            >
              <LaunchpadModulesSection
                modules={user.menu_links}
                onPageChange={toggleLaunchPad}
              />
              <div className="mt-4">
                <LaunchpadQuickLinksSection
                  quickLinks={user.quick_links}
                  onPageChange={toggleLaunchPad}
                />
              </div>
            </div>
            <div
              className={`app-launchpad-section-padding moving-animation-rea ${
                searchKey.length < 1
                  ? 'move-item-right'
                  : 'move-item-left-with-opacity'
              }`}
            >
              <LaunchpadSearchResultArea
                searchKey={searchKey}
                searchResult={searchResult}
                isSearching={isSearching}
                onPageChange={toggleLaunchPad}
              />
            </div>
          </div>

          <LaunchPadFooter />
        </div>
      </div>
    </>
  );
}
