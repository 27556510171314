import { React, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card } from 'react-bootstrap';

import { AuthWizardContext } from 'context/Context';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import ChatWindowBody from './ChatWindowBody';
import ChatWindowFooter from './ChatWindowFooter';
import ChatWindowHeader from './ChatWindowHeader';

const ChatWindow = () => {
  const location = useLocation();
  const { user } = useContext(AuthWizardContext);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const item = location.state ? location.state.item : 'undefined';
  const type = location.state ? location.state.type : 'undefined';

  const [loading, setLoading] = useState(false);
  const [loadingPreviousMessage, setLoadingPreviousMessage] = useState(false);
  const [data, setData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [formData, setFormData] = useState({
    message: '',
    receiver_id: '',
    group_id: '',
    files: []
  });
  const individualType = 'INDIVIDUAL';
  const groupType = 'GROUP';

  const [links, setLinks] = useState([]);

  const loadNextPage = async next_page_url => {
    setLoadingPreviousMessage(true);
    await axios
      .get(next_page_url)
      .then(response => {
        if (response.data.data) {
          setPageNumber(response.data.data.meta.current_page);
          setLinks(response.data.data);
          const newData = response.data.data.data
            .map(details => {
              return {
                ...details,
                sender_id: details.sender_id,
                message: details.message,
                media: details.media,
                success: true
              };
            })
            .reverse();
          setData([...newData, ...data]);
          setLoadingPreviousMessage(false);
        }
      })
      .catch(error => {
        console.log(error);
        setLoadingPreviousMessage(false);
      });
  };

  const fetchChat = (type, groupOrUserId) => {
    setData([]);
    setLoading(true);
    let params = '';
    type == individualType
      ? (params = {
          user_id: groupOrUserId,
          type: type
        })
      : type == groupType
      ? (params = {
          group_id: groupOrUserId,
          type: type
        })
      : '';

    axios
      .get('user/chats', {
        params: params
      })
      .then(response => {
        setLinks(response.data.data);
        const newData = response.data.data.data
          .map(details => {
            return {
              ...details,
              sender_id: details.sender_id,
              message: details.message,
              success: true
            };
          })
          .reverse();
        setData(newData);
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (type == 'undefined' || type == '' || !type) {
      navigate('/dashboard');
    }
    if (type && item) {
      fetchChat(type, item.id);
      axios
        .get('user/read-chat', {
          params: {
            user_id: type == individualType ? item.id : '',
            group_id: type == groupType ? item.id : '',
            type: type
          }
        })
        .then(() => {});
      type == individualType
        ? setFormData({
            ...formData,
            type: type,
            receiver_id: item.id,
            group_id: ''
          })
        : type == groupType
        ? setFormData({
            ...formData,
            type: type,
            receiver_id: '',
            group_id: item.id
          })
        : '';
    }
  }, [type, item]);

  useEffect(() => {
    if (item && type) {
      const currentURL = window.location.href;
      const urlObject = new URL(currentURL);
      const domain = urlObject.hostname;
      window.Echo.channel(`user_chat_${domain}_${user.id}`).listen(
        'ChatBroadcast',
        data => {
          let groupOrUserId =
            type == individualType ? data.from_id : data.group_id;
          if (item.id == groupOrUserId && type == data.type) {
            let newData = {
              sender_id: data.from_id,
              message: data.message,
              media: data.files,
              success: true
            };
            setData(prevState => [...prevState, newData]);
            if (pageNumber == 1) {
              messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            axios
              .get('user/read-chat', {
                params: {
                  user_id: type == individualType ? item.id : '',
                  group_id: type == groupType ? item.id : '',
                  type: type
                }
              })
              .then(() => {});
          }
        }
      );
      return () => {
        window.Echo.channel(`user_chat_${domain}_${user.id}`).stopListening(
          'ChatBroadcast'
        );
      };
    }
  }, [item, type, pageNumber]);

  return (
    <>
      <Card style={{ height: '54.5rem', width: '50%' }}>
        <ChatWindowHeader item={item} type={type} />
        {loading ? (
          <Card.Body className="h-100 d-flex align-items-center justify-content-center">
            <LoadingScreen message="Looking for messages" />
          </Card.Body>
        ) : (
          <ChatWindowBody
            data={data}
            loadNextPage={loadNextPage}
            links={links}
            loadingPreviousMessage={loadingPreviousMessage}
            attachments={attachments}
            setAttachments={setAttachments}
            setFormData={setFormData}
            formData={formData}
            messagesEndRef={messagesEndRef}
            setPageNumber={setPageNumber}
          />
        )}
        <Card.Footer className="border-top py-2">
          <ChatWindowFooter
            formData={formData}
            setData={setData}
            setFormData={setFormData}
            data={data}
            setAttachments={setAttachments}
            attachments={attachments}
            item={item}
            messagesEndRef={messagesEndRef}
            pageNumber={pageNumber}
          />
        </Card.Footer>
      </Card>
    </>
  );
};

export default ChatWindow;
