export const SalesInvoiceReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Date',
  // 'Customer LPO Number', {bipin bro told this column hide}
  // 'Customer LPO Date',
  'Allocations',
  'Amount',
  'Tax Amount',
  'Round Off Amount',
  'Paid Amount',
  'Balance Amount',
  'Payment Status'
];
export const SalesContractReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Sales Invoice',
  'Customer Receipt',
  'Sales Type',
  'Transaction Date',
  'From Date',
  'To Date',
  'Contract Amount',
  'Invoice Amount',
  'Receipt Amount'
];
export const rentalContractReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Sales Type',
  'Contract Date',
  'From Date',
  'To Date',
  'Return Date',
  'Rental Status',
  'Amount'
];
export const EmployeeAllocationReportHead = [
  'S/N',
  'Allocation ID',
  'Stock ID',
  'Asset Name',
  'Issued At',
  'Expected Return At',
  'Returned At',
  'Receiver Name',
  'Status'
];
export const DebitNoteReportHead = [
  'S/N',
  'Reference',
  'Supplier Name',
  'Date',
  'Amount',
  'Tax Amount'
];
export const SalesBasicReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Date',
  'Amount',
  'Tax Amount'
];

export const SalesReportByCustomerReportHead = [
  'S/N',
  'Customer Name',
  'Invoice Count',
  'Sales Amount',
  'Tax Amount'
];

export const CategoryWiseReportHead = [
  'S/N',
  'Category Name',
  'Count',
  'Invoice Amount',
  'Tax Amount'
];

export const TopCustomersSalesReportHead = [
  'S/N',
  'Customer Name',
  'Invoice Count',
  'Invoice Amount',
  'Tax Amount'
];

export const CustomerBalanceReportHead = [
  'S/N',
  'Customer Name',
  'Invoice Amount',
  'Received Amount',
  'Balance Amount'
];

export const BalanceStatementReportHead = [
  'S/N',
  'Trans Date',
  'Reference',
  'Transaction Type',
  'Debit',
  'Credit',
  'Balance Amount'
  // 'Memo'
];

export const AgedCustomerReportHead = [
  'S/N',
  'Customer Id',
  'Customer Name',
  '1 to 30 Days',
  '31 to 60 Days',
  'Before 60 Days',
  'Total Balance'
];

export const UnPaidSalesInvoiceReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Date',
  'Amount',
  'Tax Amount',
  'Paid Amount',
  'Balance Amount',
  'Payment Status'
];
export const ContractDetailsReportHead = [
  'S/N',
  'Reference',
  'Contract Type',
  'Transaction Date',
  'From Date',
  'To Date',
  'Customer Name',
  'Item Name',
  'Unit Price',
  'Quantity',
  'Discount',
  'Tax Amount',
  'Amount'
];

export const SalesInvoiceDetailReportHead = [
  'S/N',
  'Reference',
  'Date',
  'Customer Name',
  'Category Name',
  'Item Name',
  'Unit Price',
  'Quantity',
  'Unit Discount',
  'Unit Tax',
  'Amount'
];

export const SalesDetailReportHead = [
  'S/N',
  'Reference',
  'Date',
  'Customer Name',
  'Item Name',
  'Unit Price',
  'Quantity',
  'Unit Discount',
  'Unit Tax',
  'Amount'
];

export const UnpaidPurchaseInvoiceReportHead = [
  'S/N',
  'Reference',
  'Date',
  'Supplier Name',
  'Total Tax',
  'Amount',
  'Alloc Amount',
  'Balance Amount',
  'Payment Status'
];
export const CreditNoteDetailsReportsHead = [
  'S/N',
  'Reference',
  'Trans Date',
  'Due Date',
  'Customer Name',
  'Item Name',
  'Cost Center',
  'Unit Price',
  'Quantity',
  'Discount',
  'Tax Amount',
  'Amount'
];

export const PurchaseDetailReportHead = [
  'S/N',
  'Reference',
  'Date',
  'Supplier Name',
  'Item Name',
  'Unit Price',
  'Quantity',
  'Unit Discount',
  'Unit Tax',
  'Amount'
];

export const PurchaseDeliveryDetailReportHead = [
  'S/N',
  'Reference',
  'Date',
  'Supplier Name',
  'Category Name',
  'Item Name',
  'Unit Price',
  'Quantity',
  'Unit Discount',
  'Unit Tax',
  'Amount'
];

export const PurchaseRequestDetailReportHead = [
  'S/N',
  'Reference',
  'Date',
  'Item Name',
  'Unit Price',
  'Quantity',
  'Unit Discount',
  'Unit Tax',
  'Amount'
];

export const PurchaseInvoiceDetailReportHead = [
  'S/N',
  'Reference',
  'Date',
  'Supplier Name',
  'Category Name',
  'Item Name',
  'Unit Price',
  'Quantity',
  'Unit Discount',
  'Unit Tax',
  'Amount'
];

export const PurchaseInvoiceReportHead = [
  'S/N',
  'Reference',
  'Supplier Name',
  'Date',
  'Invoiced Amount',
  'Tax Amount',
  'Paid Amount',
  'Balance Amount',
  'Payment Status'
];

export const WorkInProgressReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Sales Order Reference',
  'Date',
  'Delivery ETA Days',
  'Delivery Expected Date',
  'Total Qty',
  'Total Producing Qty',
  'Total Released Qty',
  'Amount'
];

export const FininshedWorkOrdersReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Sales Order Reference',
  'Date',
  'Delivery ETA Days',
  'Delivery Expected Date',
  'Total Qty',
  'Total Released Qty',
  'Amount'
];

export const WorkOrdersIssuedItemsReportHead = [
  'S/N',
  'Reference',
  'Stock ID',
  'Item Name',
  'Issued Date',
  'Unit Price',
  'Issued Qty',
  'Amount'
];

export const WorkOrdersDetailsReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Date',
  'Category Name',
  'Item Name',
  'Unit Price',
  'Quantity',
  'Finished Qty',
  'Amount'
];

export const EnquiryReportHead = [
  'S/N',
  'Date',
  'Reference',
  'Name',
  'Email Address',
  'Phone Number'
  // 'Message'
];
export const MaterialRequestDetailsHead = [
  'S/N',
  'Date',
  'Reference',
  'Item Name',
  'Cost Center',
  'Quantity',
  'Quantity Issued',
  'Quantity Send',
  'Unit Discount',
  'Unit Tax',
  'Unit Price'
];
export const MaterialRequestHead = [
  'S/N',
  'Date',
  'Reference',
  'Tax',
  'Amount'
];

export const EstimationReportHead = [
  'S/N',
  'Reference',
  'Name',
  'Estimator',
  'From Date',
  'End Date',
  'Status'
];

export const WorkOrdersReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Sales Order Reference',
  'Date',
  'Amount'
];

export const OverDueWorkOrdersReportHead = [
  'S/N',
  'Reference',
  'Customer Name',
  'Sales Order Reference',
  'Date',
  'Delivery ETA Days',
  'Delivery Expected Date',
  'Total Qty',
  'Total Producing Qty',
  'Total Released Qty',
  'Amount'
];

export const VoucherInquiryReportHead = [
  'S/N',
  'Trans Date',
  'Reference',
  'Person Name',
  'Amount',
  'Pending Amount',
  'Alloc Amount'
];

export const PurchaseBasicReportHead = [
  'S/N',
  'Reference',
  'Supplier Name',
  'Date',
  'Amount',
  'Tax Amount'
];

export const PurchaseRequestReportHead = [
  'S/N',
  'Reference',
  'Date',
  'Amount',
  'Tax Amount'
];

export const SupplierBalanceReportHead = [
  'S/N',
  'Supplier Name',
  'Invoice Amount',
  'Paid Amount',
  'Balance Amount'
];

export const AgedSupplierReportHead = [
  'S/N',
  'Supplier Id',
  'Supplier Name',
  '1 to 30 Days',
  '31 to 60 Days',
  'Before 60 Days',
  'Total Balance'
];

export const TopSupplierPurchaseReportHead = [
  'S/N',
  'Supplier Name',
  'Invoice Count',
  'Invoice Amount',
  'Tax Amount'
];

export const InventoryValuationReportHead = [
  'S/N',
  'Stock ID',
  'Item Name',
  'Unit price',
  'Stock on hand',
  'Asset value'
];

export const InventoryStatusReportHead = [
  'S/N',
  'Category Name',
  'Item Name',
  'Description',
  'SKU',
  'Stock on hand',
  'On Order',
  'Demand',
  'Reorder Level'
];

export const InventoryMovementReportHead = [
  'S/N',
  'Transaction Type',
  'Reference',
  'Date',
  'Price',
  'Stock In',
  'Stock Out'
];

export const InventorySummaryReportHead = [
  'S/N',
  'Category Name',
  'Item Name',
  'Description',
  'UOM',
  'Opening',
  'Qty In',
  'Qty Out',
  'Balance'
];

export const BatchReportHead = [
  'S/N',
  'Item',
  'Batch Number',
  'Manufacturing Date',
  'Expiry Date',
  'Purchased Quantity',
  'Sold Quantity',
  'Balance Quantity'
];

export const InventoryPurchaseReportHead = [
  'S/N',
  'Reference',
  'Item Name',
  'Category Name',
  'Date',
  'Supplier Name',
  'Quantity',
  'Unit Price',
  'Total Amount'
];

export const InvoiceWiseItemProfitReportHead = [
  'S/N',
  'Date',
  'Reference',
  'Stock ID',
  'Item Name',
  'Category Name',
  'Sold Quantity',
  'Total Selling Price',
  'Total Cost',
  'Total Profit'
];

export const ItemReOrderReportHead = [
  'S/N',
  'Stock Id',
  'Item Name',
  'Category Name',
  'Quantity',
  'ReOrder Level'
];

export const ItemWiseProfitReportHead = [
  'S/N',
  'Stock Id',
  'Item Name',
  'Category Name',
  'Total Sales Amount',
  'Total Purchase Amount',
  'Profit'
];

export const CategoryWiseProfitReportHead = [
  'S/N',
  'Category Name',
  'Total Sales Amount',
  'Total Purchase Amount',
  'Profit'
];

export const WarehouseWiseAverageCostReportHead = [
  'S/N',
  'Warehouse Name',
  'Item Name',
  'Category Name',
  'Total Purchase Cost',
  'Total Sold Price',
  'Total Purchased QTY',
  'Total Sold QTY',
  'Current Average Cost',
  'QOH'
];

export const CustomerPaymentReportHead = [
  'S/N',
  'Date',
  'Customer Name',
  'Reference',
  'Transactions',
  'Payment Method',
  'Receipt Amount',
  'Allocated Amount',
  'Balance Amount'
];

export const SupplierPaymentReportHead = [
  'S/N',
  'Date',
  'Supplier Name',
  'Reference',
  'Transactions',
  'Payment Method',
  'Receipt Amount',
  'Allocated Amount',
  'Balance Amount'
];
export const PaymentVoucherReportHead = [
  'S/N',
  'Trans Date',
  'Reference',
  'Person Name',
  'From Bank Name',
  'Memo',
  'Amount'
];
export const ReceiptVoucherReportHead = [
  'S/N',
  'Trans Date',
  'Reference',
  'Person Name',

  'To Bank Name',
  'Memo',
  'Amount'
];
export const BankTransferReportHead = [
  'S/N',
  'Trans Date',
  'Reference',
  'From Bank Name',
  'To Bank Name',
  'Memo',
  'Amount'
];
export const RefundReportHead = [
  'S/N',
  'Reference',
  'Trans Date',
  'Person Type',
  'Person Name',
  'From Bank Name',
  'Memo',
  'Amount'
];

export const BankBalanceReportHead = [
  'S/N',
  'Account Code',
  'Account Name',
  'Account Number',
  'Opening Balance',
  'Total Debit',
  'Total Credit',
  'Balance'
];
export const ChequeReportHead = [
  'S/N',
  'Cheque Number',
  'Cheque Date',
  'Transaction Date',
  'Transaction Reference',
  'Bank',
  'Type',
  'Supplier',
  'Customer',
  'Amount',
  'Status'
];

export const BankTransactionReportHead = [
  'S/N',
  'Date',
  'Reference',
  'Transaction Type',
  'Person Name',
  'Memo',
  'Debit',
  'Credit'
];

export const VoidTransactionReportHead = [
  'S/N',
  'Reference',
  'Transaction Type',
  'Transaction Date',
  'Void Date',
  'Memo',
  'Created By',
  'Void By'
];

export const CashInOutStatementReportHead = [
  'S/N',
  'Account Name',
  'Opening Balance',
  'Cash In',
  'Cash Out',
  'Closing Balance'
];

export const RecurrentProfileReportHead = [
  'S/N',
  'Transaction Type',
  'Start Date',
  'End Date',
  'Next Date',
  'Repeat Every',
  'Repeat Type',
  'Created At',
  'Created By'
];

export const EmailLogsReportHead = [
  'S/N',
  'Reference',
  'Transaction Type',
  'Date',
  'User Name',
  'Notes'
];

export const ScheduleMaintenanceReportHead = [
  'S/N',
  'Item',
  'Due Date',
  'Completed Date',
  'Status'
];

export const AssetWiseProfitSummaryReportHead = [
  'S/N',
  'Asset Type',
  'Stock Id',
  'Asset Name',
  'Category Name',
  'Total Income',
  'Total Cost',
  'Total Profit'
];

export const AssetTransactionReportHead = [
  'S/N',
  'Trans Date',
  'Reference',
  'Transaction Type',
  'Stock Id',
  'Asset Name',
  'Total Amount'
];

export const AssetStockReportHead = [
  'S/N',
  'Stock Id',
  'Asset Name',
  'Person Type',
  'Person Name',
  'Rented Date',
  'Expected Return Date',
  'Status'
];
