import { React, useLayoutEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { AiOutlineFileText } from 'react-icons/ai';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { CloseButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PurchaseOrderView from './PurchaseOrderView';
import TransactionsData from 'module/Common/Timeline/TransactionsData';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import Settings from 'module/Common/SystemSettings/Settings';
import PDFView from 'components/PDFViewerButton/PDFView';
import Badges from '../Common/ViewPageDetails/Badges';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import TabViewGeneralDetailsSectionCover from 'components/tab-view-general-details-section-cover/TabViewGeneralDetailsSectionCover';
import { FaExternalLinkAlt } from 'react-icons/fa';
import ShowReference from 'components/show-reference/ShowReference';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ApproveButton from 'components/approval-component/button/ApproveButton';
import RejectButton from 'components/approval-component/button/RejectButton';
import ApprovalWorkFlow from '../Common/ApprovelWorkFlow/ApprovalWorkFlow';
import BranchToBranch from 'components/inter-branch-transaction/components/BranchToBranch';

function PurchaseOrderTabView({
  purchaseOrderData,
  loading,
  // infoPage,
  voidScreen,
  fetchData,
  getData,
  offCanvas,
  setShowVoucherDetailArea,
  onHide,
  voidTransactionReport,
  interBranch
}) {
  const Translate = useAxisproTranslate();
  const navigateTo = useNavigate();
  let permissions = {
    show_sp: getAcodaxPermissionSlug('supplier', 'show'),
    sendMail: getAcodaxPermissionSlug('PO', 'send-mail'),
    print: getAcodaxPermissionSlug('PO', 'print'),
    create: getAcodaxPermissionSlug('PO', 'create'),
    edit: getAcodaxPermissionSlug('PO', 'update'),
    create_pi: getAcodaxPermissionSlug('PI', 'create'),
    create_pd: getAcodaxPermissionSlug('PD', 'create'),
    voidTransaction: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };

  const { breakpoints } = useBreakpoints();
  const [queryParams] = useSearchParams();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [transactions, setTransactions] = useState([]);
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const status = queryParams.get('status');

  const value = {
    value: purchaseOrderData.supplier_id
  };

  const handleSupplierDetailsArea = () => {
    setSupplierDetailsArea(!showSupplierDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  useLayoutEffect(() => {
    if (
      purchaseOrderData &&
      purchaseOrderData.connected_invoices &&
      purchaseOrderData.connected_deliveries
    ) {
      let connected = [
        ...purchaseOrderData.connected_deliveries,
        ...purchaseOrderData.connected_invoices
      ];
      let sorted = connected.sort((a, b) =>
        a.timestamp.localeCompare(b.timestamp)
      );
      setTransactions(sorted);
    }
  }, [purchaseOrderData]);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <AiOutlineFileText size={28} className="me-2 text-dark" />
              <h5
                className="mb-0 d-flex text-uppercase view-page-header"
                style={{ fontSize: '25px' }}
              >
                {Translate(interBranch ? 'Inter Branch PO' : 'Purchase Order')}
              </h5>
            </div>
            {voidTransactionReport && (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            )}
            {!interBranch && purchaseOrderData?.is_inter_branch_transaction ? (
              <BranchToBranch className={'ms-2'} badgeColor="info" size={15} />
            ) : null}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
              voidScreen ||
              offCanvas ? (
                ''
              ) : (
                <>
                  <MoreButton
                    sendEmail={sendEmail}
                    setUpload={setUpload}
                    firstConversionValidation={
                      permissions?.create_pd ||
                      purchaseOrderData?.is_inter_branch_transaction
                        ? false
                        : true
                    }
                    disableFirstConversion={purchaseOrderData.is_fully_sent}
                    firstConversionTo={
                      `/purchase/${
                        interBranch ? 'inter-branch-transactions/' : ''
                      }purchase-delivery/add?order_id=` + purchaseOrderData.id
                    }
                    afterFirstConversion={'Fully Delivered!'}
                    beforeFirstConversion={'Convert to Purchase Delivery'}
                    secondConversionValidation={
                      permissions?.create_pi && !interBranch
                    }
                    disableSecondConversion={
                      purchaseOrderData.is_fully_invoiced
                    }
                    secondConversionTo={
                      '/purchase/purchase-invoice/add?order_id=' +
                      purchaseOrderData.id
                    }
                    afterSecondConversion={'Fully Invoiced!'}
                    beforeSecondConversion={'Convert to Purchase Invoice'}
                    emailPermission={permissions?.sendMail}
                    clonePath={
                      permissions?.create && !interBranch
                        ? '/purchase/purchase-order/add?clone_item=' +
                          purchaseOrderData?.id
                        : undefined
                    }
                    voidTransactionData={
                      permissions?.voidTransaction
                        ? {
                            id: purchaseOrderData?.id,
                            trans_type: 'PO',
                            callBack: () => {
                              navigateTo(
                                `   /purchase/${
                                  interBranch && 'inter-branch-transactions/'
                                }purchase-order?layout=column`
                              );
                              typeof getData === 'function' && getData(1);
                            }
                          }
                        : undefined
                    }
                  />

                  {/* {!infoPage && permissions?.edit ? (
                    purchaseOrderData?.alloc > 0 ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            <small>
                              {Translate(
                                'Supplier Payments are generated against this Purchase Order. So further modification cannot be done.'
                              )}
                            </small>
                          </Tooltip>
                        }
                      >
                        <div className="px-2 btn btn-sm btn-info opacity-50 readonly">
                          <FaEdit />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <EditButton
                        to={`/purchase/purchase-order/edit/${purchaseOrderData.id}`}
                      />
                    )
                  ) : null} */}
                </>
              )}
              {(status && status !== 'approved-request') ||
                (permissions?.print && !voidTransactionReport && (
                  <ViewPagePrintButton
                    variant="danger"
                    className="text-white"
                    style={{ boxShadow: 'none' }}
                    url={`purchase/purchase-orders/print/${purchaseOrderData.id}`}
                    pageTitle="Purchase order print view"
                  />
                ))}
              <Settings type="Purchase Order Settings" module="purchase" />
              {offCanvas || onHide ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : (
                ''
              )}
              {purchaseOrderData?.user_actions?.Approve && (
                <ApproveButton
                  name={purchaseOrderData?.user_actions?.Approve}
                  Id={purchaseOrderData?.approval_event_id}
                  data={purchaseOrderData}
                  getData={getData}
                  info={true}
                />
              )}
              {purchaseOrderData?.user_actions?.Reject && (
                <RejectButton
                  name={purchaseOrderData?.user_actions?.Reject}
                  Id={purchaseOrderData?.approval_event_id}
                  data={purchaseOrderData}
                  getData={getData}
                  info={true}
                />
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <TabViewGeneralDetailsSectionCover mainTitle="General Details">
            <TabViewGeneralDetailsSectionCover.Section position="start">
              <TabViewGeneralDetailsSectionCover.Item
                title="Supplier Name"
                value={purchaseOrderData?.supplier_name}
                EndElement={
                  <Button
                    variant="transparent"
                    className="p-0 mb-2 text-primary outline-none"
                    onClick={() => setSupplierDetailsArea(true)}
                    size="sm"
                  >
                    <FaExternalLinkAlt size={10} />
                  </Button>
                }
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Transaction Date"
                value={purchaseOrderData?.trans_date_formatted}
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Due Date"
                value={purchaseOrderData?.due_date_formatted}
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.Section position="end">
              <TabViewGeneralDetailsSectionCover.Item
                title="Reference"
                contentSectionClassName="mb-2"
              >
                <ShowReference
                  data={purchaseOrderData}
                  to={`/purchase/purchase-order?entry=${purchaseOrderData?.id}&layout=column`}
                  offCanvas={offCanvas}
                  readOnly={voidTransactionReport}
                />
              </TabViewGeneralDetailsSectionCover.Item>

              <TabViewGeneralDetailsSectionCover.Item
                title="Tax Included"
                value={
                  [true, 1, '1'].includes(purchaseOrderData?.tax_included)
                    ? 'Included'
                    : 'Excluded'
                }
              />

              <TabViewGeneralDetailsSectionCover.Item
                title="Payment Term"
                value={purchaseOrderData?.payment_term}
              />
            </TabViewGeneralDetailsSectionCover.Section>

            <TabViewGeneralDetailsSectionCover.CustomColumnResultSection
              customColumnItems={purchaseOrderData?.custom_field_values}
            />
          </TabViewGeneralDetailsSectionCover>

          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={purchaseOrderData.trans_type}
                      transId={purchaseOrderData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <PurchaseOrderView purchaseOrderData={purchaseOrderData} />
                </SimpleBar>
              </Tab>
              {transactions && transactions.length > 0 && (
                <Tab eventKey="transaction" title={Translate('Transactions')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <TransactionsData transaction={transactions} />
                  </SimpleBar>
                </Tab>
              )}
              {purchaseOrderData.media && purchaseOrderData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={purchaseOrderData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}

              {status === 'approved-request' ||
              (!status &&
                !purchaseOrderData?.user_actions?.Approve &&
                purchaseOrderData?.id) ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    <PDFView
                      viewType="tab"
                      url={`purchase/purchase-orders/print/${purchaseOrderData.id}`}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              {purchaseOrderData?.history_events?.length && (
                <Tab
                  eventKey="approval-flow"
                  title={Translate('Approval Flow')}
                >
                  <SimpleBar className="simplebar-view-page-style">
                    <ApprovalWorkFlow
                      data={purchaseOrderData?.history_events}
                      details={{
                        created_user: purchaseOrderData?.created_user_full_name,
                        create_time: purchaseOrderData?.created_at,
                        trans_type_name: purchaseOrderData?.trans_type_name
                      }}
                    />
                  </SimpleBar>
                </Tab>
              )}
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={purchaseOrderData?.created_at}
          created_by={purchaseOrderData?.created_user_full_name}
        />
      </Card>
      <SupplierDetails
        show={showSupplierDetailsArea}
        onHide={handleSupplierDetailsArea}
        value={value}
        showSupplierDetailsArea={showSupplierDetailsArea}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'PO'}
        id={purchaseOrderData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'purchase/purchase-order/send-mail/' + purchaseOrderData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={purchaseOrderData.supplier_email}
      />
    </>
  );
}

PurchaseOrderTabView.propTypes = {
  purchaseOrderData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  onHide: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  infoPage: PropTypes.bool,
  getData: PropTypes.func,
  interBranch: PropTypes.bool
};

export default PurchaseOrderTabView;
