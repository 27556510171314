import React, { useContext, useState } from 'react';
import { Button, Placeholder } from 'react-bootstrap';
import {
  FaClock,
  FaTimes,
  FaUser,
  FaUserEdit,
  FaUserPlus
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import SelectUser from 'components/form/SelectUser';
import {
  ActiveTaskContext,
  AuthWizardContext,
  DeleteContext
} from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectTaskStatus from 'components/form/SelectTaskStatus';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproPermission from 'hooks/useAxisproPermission';
import TaskTimerHandler from './TaskTimerHandler';
import WorkOrderLineItemTaskItemDescriptionArea from './WorkOrderLineItemTaskItemDescriptionArea';

export default function WorkOrderLineItemTaskItem({
  task,
  itemTargetId,
  setWorkOrderVoucherData,
  withBottomSpace,
  colSpan,
  isDropDownPreview,
  isCompleted
}) {
  const { user } = useContext(AuthWizardContext);
  const { dispatch: confirmDispatch } = useContext(DeleteContext);
  const { activeTask } = useContext(ActiveTaskContext);
  const axisProPermission = useAxisproPermission();
  const Translate = useAxisproTranslate();
  const [showSelectAssignee, setShowSelectAssignee] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const permissions = {
    changeTaskStatus: axisProPermission('update-task-status'),
    updateTaskAssignee: axisProPermission('update-task-assignee'),
    updateWorkOrder: axisProPermission('update-wo')
  };
  const isMyTask = task?.assignee?.id && user?.id === task.assignee.id;
  const showLoadingUI =
    activeTask?.id && activeTask?.showLoadingUI && task?.id === activeTask?.id;

  const handleShowSelectAssignee = event => {
    event.preventDefault();
    setShowSelectAssignee(!showSelectAssignee);
  };

  const handleRemoveAssignee = event => {
    event.preventDefault();
    handleChangeAssignee(null, 'REMOVE');
  };

  const handleChangeAssignee = (event, method) => {
    if (![undefined, 'REMOVE'].includes(method)) {
      throw new Error('Invalid method, please provide a valid method');
    }
    const removeAssignee = !!(method === 'REMOVE');
    const config = {
      target: event?.value,
      buttonText: 'Remove',
      submitButtonVariant: 'danger',
      cancelButtonVariant: 'warning',
      reuestMethod: 'post',
      url: '/crm/task-assignees/update?task_id=' + task?.id,
      onDelete: () => setShowLoading(true),
      onSuccess: taskData => {
        if (taskData?.id && itemTargetId) {
          setWorkOrderVoucherData(prev => ({
            ...prev,
            details: prev?.details?.map(item =>
              item?.id === itemTargetId ? { ...item, task: taskData } : item
            )
          }));

          showSelectAssignee && setShowSelectAssignee(false);
          setShowLoading(false);
        }
      },
      onError: setShowLoading(false)
    };

    if (!removeAssignee) {
      if (!config?.target) return;
      config.buttonText = 'Update';
      config.submitButtonVariant = 'success';
      config.cancelButtonVariant = 'danger';
      config.url = config.url + '&' + 'new_assignee=' + config.target;
    }

    config.title = Translate(
      `${
        removeAssignee ? 'Remove' : task?.assignee ? 'Change' : 'Add'
      } Assignee`
    );

    config.message = Translate(
      `Are you sure you want to ${
        !task?.assignee
          ? 'add'
          : `${removeAssignee ? 'remove' : 'change'} current`
      } assignee?`
    );

    confirmDispatch({
      type: 'CONFIG',
      payload: config
    });
  };

  const handleTaskStatus = event => {
    if (!task?.assignee) {
      showToast(
        Translate('The task has not been assigned to anyone yet'),
        'error'
      );
      return;
    }
    const status = event?.value;
    if (!status || status === task?.status) return;
    const config = {
      target: status,
      title: Translate('Change Task Status'),
      message: Translate(
        'Are you sure you want to change the status of the selected task?'
      ),
      buttonText: 'Update',
      submitButtonVariant: 'success',
      cancelButtonVariant: 'danger',
      reuestMethod: 'post',
      url: '/crm/task-status/update?task_id=' + task?.id + '&status=' + status,
      onDelete: () => setShowLoading(true),
      onSuccess: taskData => {
        if (taskData?.id && itemTargetId) {
          setWorkOrderVoucherData(prev => ({
            ...prev,
            details: prev?.details?.map(item =>
              item?.id === itemTargetId ? { ...item, task: taskData } : item
            )
          }));
        }
        setShowLoading(false);
      },
      onError: setShowLoading(false)
    };

    confirmDispatch({
      type: 'CONFIG',
      payload: config
    });
  };

  return task?.id ? (
    <>
      <tr>
        <td colSpan={colSpan ?? 10} className="p-2">
          {showLoading || showLoadingUI ? (
            <>
              <Placeholder animation="glow">
                <Placeholder
                  style={{
                    width: '15%'
                  }}
                />
                <br />
                <Placeholder className="w-100" />
                <br />
                <Placeholder className="w-50" />
              </Placeholder>

              <div className="mt-3 d-flex flex-wrap">
                <div className="flex-fill">
                  <Placeholder animation="glow">
                    <Placeholder
                      style={{
                        width: '15%'
                      }}
                    />
                    <br />
                    <Placeholder className="w-25" />
                  </Placeholder>
                </div>
                <Placeholder
                  animation="glow"
                  className="gap-2 d-flex mt-auto flex-fill justify-content-end"
                >
                  <Placeholder.Button
                    variant="light"
                    className="rounded border"
                    style={{
                      height: 25,
                      width: '100%',
                      maxWidth: 100
                    }}
                  />
                  <Placeholder.Button
                    className="rounded"
                    style={{
                      height: 25,
                      width: '100%',
                      maxWidth: 100
                    }}
                  />
                </Placeholder>
              </div>
            </>
          ) : (
            <>
              <WorkOrderLineItemTaskItemDescriptionArea
                isCompleted={isCompleted}
                task={task}
                isDropDownPreview={isDropDownPreview}
                itemTargetId={itemTargetId}
                setWorkOrderVoucherData={setWorkOrderVoucherData}
                setShowLoading={setShowLoading}
              />

              <div className="d-flex flex-wrap">
                <div className="me-auto">
                  <div className="d-flex flex-row gap-1 align-items-center">
                    <p className="fw-bold text-dark mb-0">
                      {Translate('Assignee')}
                    </p>

                    {!isDropDownPreview && permissions?.updateTaskAssignee ? (
                      <>
                        <Button
                          variant="transparent"
                          className="p-0 m-0 text-warning outline-none shadow-none"
                          type="button"
                          onClick={handleShowSelectAssignee}
                          title={Translate(
                            `${task?.assignee ? 'Change' : 'Add'} Assignee`
                          )}
                          disabled={task?.running_status || isCompleted}
                        >
                          {task?.assignee ? (
                            <FaUserEdit size={13} />
                          ) : (
                            <FaUserPlus size={13} />
                          )}
                        </Button>
                        {!showSelectAssignee && task?.assignee ? (
                          <Button
                            variant="transparent"
                            className="p-0 text-danger outline-none shadow-none"
                            title={Translate('Remove Assignee')}
                            type="button"
                            onClick={handleRemoveAssignee}
                            disabled={task?.running_status || isCompleted}
                          >
                            <FaTimes size={10} />
                          </Button>
                        ) : null}
                      </>
                    ) : null}
                  </div>

                  {showSelectAssignee ? (
                    <div className="d-flex flex-row align-items-center">
                      <SelectUser
                        placeholder={Translate('Choose Assignee')}
                        handleFieldChange={event =>
                          handleChangeAssignee(event, undefined)
                        }
                        menuPortalTarget={document.body}
                      />
                      <Button
                        className="align-self-stretch"
                        size="sm"
                        variant="danger"
                        onClick={handleShowSelectAssignee}
                      >
                        <FaTimes size={10} />
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="text-muted">
                        <div className="d-flex flex-row gap-1 align-items-center mb-2">
                          <FaUser size=".55rem" />
                          <p
                            className="m-0"
                            style={{
                              fontSize: '.75rem'
                            }}
                          >
                            {task?.assignee
                              ? task?.assignee_full_name
                              : Translate('Not Assigned')}
                          </p>
                        </div>
                        <div className="d-flex flex-row gap-1 align-items-center mb-2">
                          <FaClock size=".55rem" />
                          <p
                            className="m-0"
                            style={{
                              fontSize: '.72rem'
                            }}
                          >
                            Total <b>{task?.total_time_tracked}</b> worked in
                            this task
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="mt-auto d-flex flex-row gap-1">
                  <div style={{ minWidth: 130 }}>
                    <SelectTaskStatus
                      handleFieldChange={handleTaskStatus}
                      value={{
                        label: task?.status_formatted,
                        value: task?.status
                      }}
                      isDisabled={
                        !task?.assignee?.id ||
                        task?.running_status ||
                        (!permissions?.changeTaskStatus && !isMyTask) ||
                        (task?.status !== 'in_progress' && isCompleted)
                          ? true
                          : false
                      }
                      isClearable={false}
                      style={{
                        borderRadius: '0.25rem'
                      }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                  <TaskTimerHandler
                    isCompleted={isCompleted}
                    task={task}
                    itemTargetId={itemTargetId}
                    setWorkOrderVoucherData={setWorkOrderVoucherData}
                    setShowLoading={setShowLoading}
                  />
                </div>
              </div>
            </>
          )}
        </td>
      </tr>
      {withBottomSpace ? (
        <tr className="table-row-seperation-tr">
          <td colSpan={colSpan ?? 10} className="table-row-seperation-td"></td>
        </tr>
      ) : null}
    </>
  ) : null;
}

WorkOrderLineItemTaskItem.propTypes = {
  task: PropTypes.object,
  itemTargetId: PropTypes.string,
  setWorkOrderVoucherData: PropTypes.func,
  withBottomSpace: PropTypes.bool,
  colSpan: PropTypes.number,
  isDropDownPreview: PropTypes.bool,
  isCompleted: PropTypes.bool
};
