import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlinePayments } from 'react-icons/md';
import { FcPlus } from 'react-icons/fc';
import { FaTrash } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import { AuthWizardContext, StoreContext } from 'context/Context';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import SelectBankAccount from 'components/form/SelectBankAccount';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
function PaymentChequeScreen({
  show,
  person,
  invoiceData,
  onHide,
  fetchData,
  dueAmount
}) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);
  let defaultBankAccount =
    store?.globalConfiguration?.undeposited_cheque_account ?? '';
  const [formData, setFormData] = useState([]);
  const [formError, setFormError] = useState({});
  const [saving, setSaving] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  const AddNewPayment = () => {
    let total = 0;
    formData.map(items => {
      total = total + items.allocs.amount;
    });
    let balanceAmount = dueAmount - total;

    setFormData(prev => [
      ...prev,
      {
        trans_date: '',
        memo: '',
        amount: balanceAmount > 0 && balanceAmount,
        customer_id: invoiceData.customer_id,
        allocs: {
          customer_id: invoiceData.customer_id,
          voucher_id: invoiceData.voucher_id,
          amount: balanceAmount > 0 && balanceAmount,
          trans_type: 'SI'
        },
        deposit_to: '',
        payment_mode: 'cheque',
        cheque_number: '',
        cheque_date: '',
        cheque_bank: '',
        trans_ref: '',
        bank_charge: ''
      }
    ]);
  };

  const removeColumn = index => {
    // Use splice directly on formData to remove the item at the specified index
    formData.splice(index, 1);

    // Create a new array reference before updating the state
    const newArray = [...formData];
    // Update the state with the modified array
    setFormData(newArray);
  };
  const handleFieldChange = (e, index) => {
    if (e.target.name == 'amount') {
      setFormData(prev => {
        // Create a copy of the previous state array
        const updatedFormData = [...prev];
        // Update the specific element using the index
        updatedFormData[index].allocs[e.target.name] = e.target.value;
        updatedFormData[index][e.target.name] = e.target.value;
        return updatedFormData;
      });
      setFormData([...formData]);
    } else {
      setFormData(prev => {
        // Create a copy of the previous state array
        const updatedFormData = [...prev];
        // Update the specific element using the index
        updatedFormData[index][e.target.name] = e.target.value;
        return updatedFormData;
      });
      setFormData([...formData]);
    }
  };

  const handleChange = (e, action, singleFormData, index) => {
    if (action?.action == 'select-option' && action.name === 'bank_account') {
      setFormData(prev => {
        // Create a copy of the previous state array
        const updatedFormData = [...prev];
        updatedFormData[index]['deposit_to'] = e.value;
        updatedFormData[index]['deposit_to' + '_ref'] = e;
        return updatedFormData;
      });
      setFormData([...formData]);
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    setSaving(true);
    const sumOfAmount = formData.reduce(
      (accumulator, current) => accumulator + parseFloat(current.amount),
      0
    );

    if (sumOfAmount > parseFloat(dueAmount)) {
      showToast(
        'Amount should be less than or equal to balance amount',
        'error'
      );
      setSaving(false);
    } else {
      axios({
        method: 'post',
        url: 'finance/customer-receipt/bulk-store',
        data: { payments: formData, stringify: false }
      })
        .then(response => {
          if (response.data.success === true) {
            fetchData();
            showToast(response.data.message, 'success');
            setFormError({});
            setSaving(false);
            setFormData([
              {
                amount: '',
                trans_date: '',
                customer_id: invoiceData.customer_id,
                memo: '',
                allocs: {
                  customer_id: invoiceData.customer_id,
                  voucher_id: invoiceData.voucher_id,
                  amount: '',
                  trans_type: 'SI'
                },
                deposit_to: '',
                payment_mode: 'cheque',
                cheque_number: '',
                cheque_date: '',
                cheque_bank: '',
                trans_ref: '',
                bank_charge: ''
              }
            ]);
            onHide();
          } else {
            showToast(
              'Something went wrong, please refresh the page and try again.',
              'error'
            );
          }
          setSaving(false);
        })
        .catch(error => {
          setSaving(false);
          if (error.response.data && error.response.data.message) {
            const validation_error =
              error.response.data &&
              error.response.data.data &&
              error.response.data.data
                ? error.response.data.data
                : '';
            validation_error && setFormError({ ...validation_error?.errors });
          } else {
            showToast(
              'Something went wrong, please refresh the page and try again.',
              'error'
            );
          }
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    setFormData([
      {
        trans_date: '',
        memo: '',
        amount: dueAmount,
        customer_id: invoiceData.customer_id,
        allocs: {
          customer_id: invoiceData.customer_id,
          voucher_id: invoiceData.voucher_id,
          amount: dueAmount,
          trans_type: 'SI'
        },
        deposit_to: defaultBankAccount?.value ?? '',
        deposit_to_ref: defaultBankAccount ?? '',
        payment_mode: 'cheque',
        cheque_number: '',
        cheque_date: '',
        cheque_bank: '',
        trans_ref: '',
        bank_charge: ''
      }
    ]);
  }, [dueAmount]);

  return (
    <>
      <Modal
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 9998 }}
        backdrop="static"
        className="modal-with-overlay"
      >
        <Card>
          <Card.Header className="fs-1 text-dark d-flex align-items-center border-bottom">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                <MdOutlinePayments size={20} className={'me-2'} />
                {Translate('Payment for')}&nbsp;
                <span style={{ cursor: 'pointer' }}>
                  {invoiceData.reference}
                </span>
              </div>
              <AiOutlineClose
                size={22}
                className="text-danger cursor-pointer"
                onClick={onHide}
              />
            </div>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body className="pt-1">
              <Col md={8} sm={12} className={'m-0'}>
                <div className="p-2 ps-0 d-flex align-items-center">
                  {Translate('Total Balance Amount')}
                  &nbsp;: &nbsp;
                  <span className="fw-bold text-warning">
                    {dueAmount
                      ? `${baseCurrency} ` +
                        dueAmount.toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      : `${baseCurrency} 0.00`}
                  </span>
                </div>
              </Col>
              <div>
                {!Object.keys(formError).length == 0 ? (
                  <ErrorAlert
                    setFormError={setFormError}
                    formError={formError}
                  />
                ) : (
                  ''
                )}
              </div>
              <Form.Group as={Row} className="mt-2">
                <Col md={6} sm={12}>
                  <Form.Label>{Translate('Customer Name')}</Form.Label>
                  <Form.Control disabled value={person.name} />
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>{Translate('Reference')}</Form.Label>
                  <Form.Control disabled value={invoiceData.reference} />
                </Col>
              </Form.Group>
              {formData.map((items, index) => {
                const formDataLength = formData.length;
                if (items.payment_mode === 'cheque') {
                  return (
                    <Card
                      className="mt-2 pb-3"
                      style={{ borderLeft: '1px solid green' }}
                      key={index}
                    >
                      <Card.Body className="pb-0">
                        <Form.Group as={Row}>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 require-data">
                              {Translate('Amount Received')}({baseCurrency})
                            </Form.Label>
                            <InputGroup>
                              <InputGroup.Text className="fs--1">
                                {baseCurrency}
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                name="amount"
                                value={items.allocs.amount}
                                onChange={e => {
                                  handleFieldChange(e, index);
                                }}
                              />
                              {formError && formError.amount ? (
                                <span className="ms-2">
                                  <FormErrorPopover
                                    id="amount"
                                    totalErrorCount={
                                      Object.keys(formError).length
                                    }
                                    errorMessage={formError.amount}
                                  />
                                </span>
                              ) : (
                                ''
                              )}
                            </InputGroup>
                          </Col>

                          <Col md={3} sm={12}>
                            <Form.Label className="m-0">
                              {Translate('Payment Mode')}
                            </Form.Label>
                            <Form.Select
                              name="payment_mode"
                              value={items.payment_mode}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                              isInvalid={!!formError.payment_mode}
                            >
                              <option value="cheque">
                                {Translate('Cheque')}
                              </option>
                              <option value="cash">{Translate('Cash')}</option>
                              <option value="card">{Translate('Card')}</option>
                              <option value="bank_transfer">
                                {Translate('Bank Transfer')}
                              </option>
                            </Form.Select>
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 require-data">
                              {Translate('Cheque Number')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="cheque_number"
                              value={items.cheque_number}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 require-data">
                              {Translate('Cheque Date')}
                            </Form.Label>
                            <AppDatePicker
                              name="cheque_date"
                              value={items.cheque_date}
                              yearPlaceholder="yyyy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                          <Col
                            md={3}
                            sm={12}
                            className={formError.deposit_to ? 'ms-0 ps-0' : ''}
                          >
                            <Form.Label className="m-0 mt-3 require-data">
                              {Translate('Payment Date')}
                            </Form.Label>
                            <AppDatePicker
                              name="trans_date"
                              value={items.trans_date}
                              yearPlaceholder="yyyy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                              showDefaultDate
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 mt-3 require-data">
                              {Translate('Deposit To')}
                            </Form.Label>
                            <SelectBankAccount
                              value={items.deposit_to_ref}
                              error={formError.deposit_to}
                              handleFieldChange={(e, action) => {
                                handleChange(e, action, items, index);
                              }}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 mt-3">
                              {Translate('Cheque Bank')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="cheque_bank"
                              value={items.cheque_bank}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 mt-3">
                              {Translate('Transaction Ref')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="trans_ref"
                              value={items.trans_ref}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                          <Col md={12} sm={12}>
                            <Form.Label className="m-0 mt-3">
                              {Translate('Notes')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              as="textarea"
                              name="memo"
                              row={2}
                              value={items.memo}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                        </Form.Group>

                        <div className="w-100 d-flex align-items-center  m-0 p-0  justify-content-end pt-1">
                          {formDataLength - 1 === index && (
                            <div className="mt-1 text-center pt-2 me-2 cursor-pointer">
                              <FcPlus
                                size={18}
                                className="text-primary"
                                onClick={AddNewPayment}
                              />
                            </div>
                          )}
                          {index !== 0 && (
                            <div className="mt-1 text-center pt-2 cursor-pointer">
                              <FaTrash
                                title="Remove column"
                                className="text-danger cursor-pointer"
                                onClick={() => {
                                  removeColumn(index);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  );
                } else if (items.payment_mode === 'bank_transfer') {
                  return (
                    <Card
                      className="mt-2 pb-3"
                      style={{ borderLeft: '1px solid green' }}
                      key={index}
                    >
                      <Card.Body className="pb-0">
                        <Form.Group as={Row}>
                          <Col md={4} sm={12}>
                            <Form.Label className="m-0 require-data">
                              {Translate('Amount Received')}({baseCurrency})
                            </Form.Label>
                            <InputGroup>
                              <InputGroup.Text className="fs--1">
                                {baseCurrency}
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                name="amount"
                                value={items.allocs.amount}
                                onChange={e => {
                                  handleFieldChange(e, index);
                                }}
                              />
                              {formError && formError.amount ? (
                                <span className="ms-2">
                                  <FormErrorPopover
                                    id="amount"
                                    totalErrorCount={
                                      Object.keys(formError).length
                                    }
                                    errorMessage={formError.amount}
                                  />
                                </span>
                              ) : (
                                ''
                              )}
                            </InputGroup>
                          </Col>

                          <Col md={4} sm={12}>
                            <Form.Label className="m-0">
                              {Translate('Payment Mode')}
                            </Form.Label>
                            <Form.Select
                              name="payment_mode"
                              value={items.payment_mode}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                              isInvalid={!!formError.payment_mode}
                            >
                              <option value="cheque">
                                {Translate('Cheque')}
                              </option>
                              <option value="cash">{Translate('Cash')}</option>
                              <option value="card">{Translate('Card')}</option>
                              <option value="bank_transfer">
                                {Translate('Bank Transfer')}
                              </option>
                            </Form.Select>
                          </Col>

                          <Col
                            md={4}
                            sm={12}
                            className={formError.deposit_to ? 'ms-0 ps-0' : ''}
                          >
                            <Form.Label className="m-0 require-data">
                              {Translate('Payment Date')}
                            </Form.Label>
                            <AppDatePicker
                              name="trans_date"
                              value={items.trans_date}
                              yearPlaceholder="yyyy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                              showDefaultDate
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 mt-3 require-data">
                              {Translate('Deposit To')}
                            </Form.Label>
                            <SelectBankAccount
                              value={items.deposit_to_ref}
                              error={formError.deposit_to}
                              handleFieldChange={(e, action) => {
                                handleChange(e, action, items, index);
                              }}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 mt-3">
                              {Translate('Transaction Ref')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="trans_ref"
                              value={items.trans_ref}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 mt-3">
                              {Translate('Bank charges(If any)')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="bank_charge"
                              value={items.bank_charge}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Form.Label className="m-0 mt-3">
                              {Translate('Notes')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="memo"
                              value={items.memo}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                        </Form.Group>
                        <div className="w-100 d-flex align-items-center  m-0 p-0  justify-content-end pt-1">
                          {formDataLength - 1 === index && (
                            <div className="mt-1 text-center pt-2 me-2 cursor-pointer">
                              <FcPlus
                                size={18}
                                className="text-primary"
                                onClick={AddNewPayment}
                              />
                            </div>
                          )}
                          {index !== 0 && (
                            <div className="mt-1 text-center pt-2 cursor-pointer">
                              <FaTrash
                                title="Remove column"
                                className="text-danger cursor-pointer"
                                onClick={() => {
                                  removeColumn(index);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  );
                } else {
                  return (
                    <Card
                      className="mt-2 pb-3"
                      style={{ borderLeft: '1px solid green' }}
                      key={index}
                    >
                      <Card.Body className="pb-0">
                        <Form.Group as={Row}>
                          <Col md={4} sm={12}>
                            <Form.Label className="m-0 require-data">
                              {Translate('Amount Received')}({baseCurrency})
                            </Form.Label>
                            <InputGroup>
                              <InputGroup.Text className="fs--1">
                                {baseCurrency}
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                name="amount"
                                value={items.allocs.amount}
                                onChange={e => {
                                  handleFieldChange(e, index);
                                }}
                              />
                              {formError && formError.amount ? (
                                <span className="ms-2">
                                  <FormErrorPopover
                                    id="amount"
                                    totalErrorCount={
                                      Object.keys(formError).length
                                    }
                                    errorMessage={formError.amount}
                                  />
                                </span>
                              ) : (
                                ''
                              )}
                            </InputGroup>
                          </Col>

                          <Col md={4} sm={12}>
                            <Form.Label className="m-0">
                              {Translate('Payment Mode')}
                            </Form.Label>
                            <Form.Select
                              name="payment_mode"
                              value={items.payment_mode}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                              isInvalid={!!formError.payment_mode}
                            >
                              <option value="cheque">
                                {Translate('Cheque')}
                              </option>
                              <option value="cash">{Translate('Cash')}</option>
                              <option value="card">{Translate('Card')}</option>
                              <option value="bank_transfer">
                                {Translate('Bank Transfer')}
                              </option>
                            </Form.Select>
                          </Col>

                          <Col
                            md={4}
                            sm={12}
                            className={formError.deposit_to ? 'ms-0 ps-0' : ''}
                          >
                            <Form.Label className="m-0 require-data">
                              {Translate('Payment Date')}
                            </Form.Label>
                            <AppDatePicker
                              name="trans_date"
                              value={items.trans_date}
                              yearPlaceholder="yyyy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                              showDefaultDate
                            />
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Label className="m-0 require-data mt-3">
                              {Translate('Deposit To')}
                            </Form.Label>
                            <SelectBankAccount
                              value={items.deposit_to_ref}
                              error={formError.deposit_to}
                              handleFieldChange={(e, action) => {
                                handleChange(e, action, items, index);
                              }}
                            />
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Label className="m-0 mt-3">
                              {Translate('Transaction Ref')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="trans_ref"
                              value={items.trans_ref}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Label className="m-0 mt-3">
                              {Translate('Notes')}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="memo"
                              value={items.memo}
                              onChange={e => {
                                handleFieldChange(e, index);
                              }}
                            />
                          </Col>
                        </Form.Group>
                        <div className="w-100 d-flex align-items-center  m-0 p-0  justify-content-end pt-1">
                          {formDataLength - 1 === index && (
                            <div className="mt-1 text-center pt-2 me-2 cursor-pointer">
                              <FcPlus
                                size={18}
                                className="text-primary"
                                onClick={AddNewPayment}
                              />
                            </div>
                          )}
                          {index !== 0 && (
                            <div className="mt-1 text-center pt-2 cursor-pointer">
                              <FaTrash
                                title="Remove column"
                                className="text-danger cursor-pointer"
                                onClick={() => {
                                  removeColumn(index);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  );
                }
              })}
            </Card.Body>

            <Card.Footer className="d-flex gap-2">
              <Button
                variant="success"
                size="sm"
                type={'submit'}
                disabled={saving}
              >
                {!saving
                  ? Translate('Record Payment')
                  : Translate('Saving') + '...'}
              </Button>
              <Button
                variant="danger"
                size="sm"
                disabled={saving}
                onClick={onHide}
              >
                {Translate('Cancel')}
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Modal>
    </>
  );
}

PaymentChequeScreen.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  invoiceData: PropTypes.any,
  fetchData: PropTypes.func,
  person: PropTypes.object,
  dueAmount: PropTypes.any
};

export default PaymentChequeScreen;
