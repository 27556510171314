import { React, useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

function SearchAndSelect({ data, name, value, handleFieldChange, select }) {
  const [optionsArray, setoptionsArray] = useState([]);

  useEffect(() => {
    setoptionsArray(
      data.map(item => ({
        label: item.name,
        options: item.data.map(value => ({
          label: value.code + ' - ' + value.name,
          code: value.code,
          value: value.code
        }))
      }))
    );
  }, []);

  const Option = ({ innerProps, children, isSelected }) => {
    return (
      <div
        {...innerProps}
        className={isSelected ? '' : 'drop-down-menu'}
        style={{
          backgroundColor: isSelected ? '#2C7BE5' : 'white',
          color: isSelected ? 'white' : '',
          fontWeight: '12px',
          marginTop: '1px',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '20px',
          cursor: 'pointer'
        }}
      >
        {children}
      </div>
    );
  };

  Option.propTypes = {
    innerProps: PropTypes.any,
    children: PropTypes.any,
    isSelected: PropTypes.any
  };

  const GroupHeading = ({ children }) => {
    return (
      <div
        style={{
          fontSize: '12px',
          fontWeight: 'bold',
          padding: '5px',
          backgroundColor: '#fef6f0'
        }}
      >
        {children}
      </div>
    );
  };

  GroupHeading.propTypes = {
    children: PropTypes.any
  };

  const customStyle = {
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided,
      zIndex: 9999,
      fontFamily:
        'Inter, Source Sans Pro, Helvetica, Arial, sans-serif !important',
      fontSize: '12px',
      borderRadius: '0px',
      color: 'black',
      textTransform: 'capitalize',
      overflow: 'hidden'
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: '0px',
      border: '1px solid #b5b5b5',
      height: '10px'
    })
  };

  return (
    <>
      <Select
        cacheOptions
        defaultOptions
        className="basic-single"
        classNamePrefix="select"
        onChange={handleFieldChange}
        value={
          select
            ? optionsArray.flatMap(item =>
                item.options.find(option => option.label === select)
              )
            : value
        }
        isSearchable={true}
        isOptionSelected={item => item.value === value}
        name={name}
        options={optionsArray}
        styles={customStyle}
        menuPortalTarget={document.body}
        components={{ Option, GroupHeading }}
      />
    </>
  );
}

SearchAndSelect.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  select: PropTypes.string,
  value: PropTypes.any,
  data: PropTypes.any
};

export default SearchAndSelect;
